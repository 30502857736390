.disclaimer{
	&.required-form-field {
		&.failed{
			color: $color-brand-red;
		}
	}
	}

.input-form {
	$spacing: .5rem;

	margin-bottom: $spacing-unit;

	.form-text-input {
		$border-style: 1px solid $color-gray-gainsboro;

		padding: 1rem;
		border: $border-style;

		&.required-form-field {
			//border: solid 1px black;
			select{
				color: $color-text-gray-alt;
			}
			&.failed{
				.contact-types{
					color: $color-brand-red;
				}
				.text-input{
					
					&::placeholder{
					color: $color-brand-red;
					}
				}
			}

		}
		


		.text-input {
			outline-style:none;
			width: 100%;
			border: none;
			padding: 0;
			font-family: $font-family-sans-serif;
			font-size: px-to-rem(14px);
		}
	}

	.contact-types{
			outline-style:none;
			border:none;
			width:100%;
			font-family: $font-family-sans-serif;
			font-size: px-to-rem(14px);

			margin-left:-5px;

			option{
				&.default-option{
					color: $color-text-gray-alt;
				}
				padding:0;
				margin:0;
				
				&:not(:checked){color:rgb(0,0,0);}
				&:checked{
					color :rgb(0,0,0);
					}
			}
	}

	.two-column-layout {
		display: flex;
		justify-content: space-between;

		.form-text-input {
			width: calc(50% - #{$spacing/2});
		}
	}

	.form-field {
		.form-text-input {
			margin-top: 0;
		}
	}
 
	.form-text-input {
		margin-top: $spacing;
	}

	.inout-form-buttons {
		margin-top: $spacing;
		margin-bottom: 4rem;
	}

	@include bp-max(415px) {
		.two-column-layout {
			display: block;

			.form-text-input {
				width: 100%;

				&:last-of-type {
					margin-top: $spacing;
				}
			}
		}
	}
}

.server-response-message {
	font-weight: bold;

	&.error {
		color: $color-brand-red;
	}

	&.success {
		color: $color-brand-green;
	}
}
