.insights-marquee{
    background-size:cover !important;
    background-color: $color-gray-mortar;
    background-position: top center;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;

    .left-text{
        width: 40%;
        margin-right:10%;
        padding: 2rem;
        color: white;
        line-height: 1.2;
        letter-spacing: .1rem;
        font-family: Avenir LT W01_65 Medium1475532;
        
        .left-text-tag{
            font-size: .66667rem;
            font-weight: 400;
            text-transform: uppercase;
            font-style: normal;
            margin-bottom: .5rem;
        }

        .left-text-main{
            font-size: 2.25rem;
            display: inline;
        }
    }

    .right-content{
        display: inline-flex;
        float: right;
        flex-direction: column;
        color: white;
        flex: 3 1;
        min-height: 100%;
        justify-content: center; 
        
        a.right-content-item {
            padding: 1rem 0;
            line-height: 1.25;
            display: flex;
            flex-direction: column;
            justify-content: center; 
            text-decoration: none;
            color: white;

            &:hover{
                opacity: .75;
                cursor: pointer;
            }
        
            .right-content-item-title{
                font-size: 1.5rem;
                display: flex;
                width: 75%;
            }
            
            .right-content-item-description{
                font-size: 1rem;
                width: 75%;
                display: flex;
            }
        }

        .right-content-item:not(:last-of-type){
            border-bottom: white solid .125rem;
        }
    }

    @include bp-max($bp-tablet) {
        flex-wrap: wrap;
        .left-text{
            width: 100%;
            margin-right: 0;

            .left-text-main{
                font-size: 1.75rem;
            }
        }
        .right-content a.right-content-item{
            padding: 1.75rem;

            .right-content-item-title{
                font-size: 1.5rem;
            }
            .right-content-item-description{
                width: 100%;
                font-size: 1rem;
            }
        }
        .right-content-item:first-of-type{
            border-top: white solid .125rem;
        }
    }
}