$insights-filter-margin-left: 0.75rem;

.insights-search-filters {
    @extend %site-search-padding;

    $padding-vertical: 0.9rem;

    border-bottom: 1px solid $color-gray-gainsboro;
    margin-top: 2rem;
    padding-top: $padding-vertical;
    padding-bottom: $padding-vertical;
    margin-bottom: 0;
    width: 100%;

    @media (min-width: $bp-mobile-portrait) {
        display: inline-block;
    }
}

.insights-search-filters__filter-list {
    margin-bottom: 0;

    @media (min-width: $bp-mobile-portrait) {
        display: inline-block;
    }
}

.insights-search-filters__filter-list-item {
    align-items: center;
    display: inline-flex;
    margin-top: 0.75rem;

    @media (min-width: $bp-mobile-portrait) {
        margin-right: $insights-filter-margin-left;
        margin-top: 0;
    }
}

.insights-search-filters__clear-filters-btn {
    color: $color-green-blue-lagoon;
    font-size: 0.75rem;
    font-style: normal;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    &:hover {
        color: $color-brand-dark-black;
    }
}

.insights-search-filters__filter-list-icon {
    color: $color-gray-med;
    display: inline-block;
    font-family: "Black Tie";
    margin-left: 0.5rem;

    &:hover {
        color: $color-brand-dark-black;
    }
}

.insights-search-filters__filter-list-item-text {
    font-size: 1rem;
    font-style: italic;
}

.insights-search-filters__filters-label {
    display: inline-block;
    font-family: $font-family-sans-serif;
    font-size: 0.875rem;

    @media (min-width: $bp-mobile-portrait) {
        margin-right: $insights-filter-margin-left;
    }
}