.careers-section-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  background-color: $color-brand-gray-light;
  border: 1px solid $color-gray-gainsboro;
  padding: $spacing-unit $spacing-unit;

.nav-option__label {
  bottom: 100%;
  font-size: 0.85rem;
  font-family: $font-family-sans-serif-bold;
  position: absolute;
  top: -2.65rem;
  margin-top: 1rem;
}

  .nav-option {
    position: relative;
    width: 40%;

    &.submit{
      width:16%;

      button{
        width:100%;
        height: 100%;
      }
    }
    & + .nav-option {
      margin-left: 2%;
    }
  }

  .select-drop-down-aud {
    height: 100%;
  }

  .display-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .display-item-label {
    font-family: $font-family-sans-serif-bold;
  }

    @include bp-max($bp-mobile-landscape) {
      flex-wrap: wrap;

      .nav-option {
        width: 100%;

        & + .nav-option {
          margin-top: 1rem;
          margin-left: 0%;
        }

        &.submit {
          width: 100%;
          text-align: right;

          button {
            width: auto;
          }
        }
      }
    }
}