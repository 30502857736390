.language-bar {
  text-align: center;

  .popup-menu  {
    margin-left: -1.05em;
  }
}
.language-bar-label,
.language-bar-links {
  @extend %body-6;

  display: inline-block;
}
.language-bar-links {
  margin: 0;
  padding-left: 0;
}
.language-bar-label {
  color: #4f5658;
}

.language-bar-item {

  display: inline;

  &:after {
    color: $color-text-light;
    content: '|';
    display: inline-block;
    margin-left: px-to-rem(2px);
  }

  &:last-child {
    &:after {
      content: '';
    }
  }

  &:last-child {

    .popup-menu {
      right: 0;
      left: auto;
      transform: none;

      &:after {
        left: 85%;
      }
    }
  }
}

.language-bar-menu-trigger {
  color: $color-text-base;

  &.show-cursor{
      cursor: pointer;
  }
  &.is-active{
      color: $color-brand-red;
  }
  &.is-active:not(.show-cursor) {
    cursor: default;
  }
}

.language-bar-link {
  color: $color-text-gray;
  display: inline-block;
  padding: px-to-rem(6px) px-to-rem(6px);
  display: inline-block;
  position: relative;

  &:hover {
    color: $color-text-dark;
  }

  &.is-active {
    font-family: $font-family-sans-serif-bold;
    color: $color-brand-red;
  }
  
} 