.areas-of-focus {
	margin-top: 2.2rem;

	.section-header {
		margin-bottom: 2rem;
	}

	.areas-of-focus-areas {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.areas-of-focus-area {
		@extend %body-4;

		display: block;
		margin: 0;
		padding-bottom: px-to-rem($font-size-base-px);

		-webkit-column-break-inside: avoid;
    	page-break-inside: avoid;
    	break-inside: avoid;
    	position: relative;
	}
}

.areas-of-focus-areas {
	columns: 2 auto;
	@include bp-max($bp-tablet) {
		columns: 1 auto;
	}
}
