.project-listing-selection {
	line-height: 1.6;

	.helper-text {
		@extend %body-2 ;
	}
}

.project-listing-selection-header {
	text-align: center;
	margin-bottom: $spacing-unit;
	margin-top: $spacing-unit* 2;

	&:first-child {
		margin-top: 0;
	}
}

.project-listing-selection-title {
	@extend %body-5;
}

.project-listing-selection-group {
	margin: 0 auto;
}
