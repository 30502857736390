// Link Variants
@mixin links__blue() {
  color: $color-brand-blue;
  transition: color 0.25s;

  &:hover {
    color: $color__black;
  }
}

@mixin links__black() {
  color: $color-brand-black;
  transition: color 0.25s;

  &:hover {
    color: $color-brand-blue;
  }
}
