.careers-detail-header {
	margin-top: $spacing-unit-block;


	.title  {
		text-align: center;
		margin-bottom: $spacing-unit-small;
	}

	.rte-intro   {
		margin-top: $spacing-unit;
	}


	.cta-link {
		@extend %body-3;

		margin-top: $spacing-unit;
		background-color: $color-brand-blue;
		display: block;
		color: $color-brand-white;
		background-color: $color-button-primary;
		padding: 1rem;
		text-align: center;

		&:after {
			content: '↑';
			transform:  rotate(45deg);
			margin-left: px-to-rem(5px);
			display: inline-block;
		}

		&:hover {
			background-color: $color-button-primary-hover;
			color: $color-brand-white;
		}
	}
}