.site-nav-bar {
  background-color: $bg-nav-bar;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: $zindex-header-bar;

  @include bp-max($bp-mobile) {
    position: static;
    z-index: 1;
    height: auto;
    top:auto;
    left: auto;
    background: none;
  }

  .site-nav-bar-menu {
    @extend %uc;
    position: relative;
    padding-right: $site-search-width;

    @include bp-max($bp-mobile) {
      padding-right: 0;
      float: none;
      margin-top: .5rem;
    }

  }
}

.site-nav-bar-link {
  display: inline-block;
  font-family: $font-family-sans-serif;
  font-size: .6rem;
  line-height: 2.222rem;
  padding:0 1rem;
  color: $color-gray-gainsboro;
  // border-left: 1px solid #656365;
  letter-spacing: .1rem;
  margin-right: -0.17rem;
  &.js-drop-down{
      position: relative;
      cursor: pointer;
      min-width: px-to-rem(170px);
      text-align: center;

      .js-drop-down__icon{
        @include bt-icon-after($bt-var-caret-down) {
            font-size: .5rem;
            margin-left: .7rem;
            font-weight: 900;
            position: relative;
            top: -1px;
            @include bp-max($bp-mobile) {
                display: inline-block;
                  position: absolute;
                  top: 13px;
                  right: 25px;
            }
        }

        &--active{
          &:after{
            transform: rotate(180deg);
          }
        }
      }

      @include bp-max($bp-tablet) {
          min-width: 0;
      }

  }
  .nav-homepage:hover & {
    &:hover {
  		color: $color-brand-white;
    }
  }
  .nav-drop-down-container{
      position: absolute;
      min-width: px-to-rem(140px);
      width: 100%;
      top: 100%;
      left: 0;
      background-color: $color-brand-dark-gray;
      display: none;
      overflow: hidden;
      z-index: 2;
      @include bp-max($bp-mobile){
          //display: block;
          position: relative;
          background-color: transparent;
      };
      .site-nav-bar-link{
          border-left: 0;
          border-bottom: 1px solid #656365;
          width: 100%;
          line-height: 2;
          padding: 14px px-to-rem(28px);
          text-align: center;
          @include bp-max($bp-mobile){
              line-height: 2;
              padding: 6px 1rem;
              border-left: 0;
              border-bottom: 0;
              font-size: .6rem;
              text-align: center;
          };
        }
  }
  @include bp-max-both($bp-main-nav){
    padding: 0 .8rem;
    font-size:.45rem;
  }

  @include bp-max($bp-tablet) {
    line-height: 2.25rem;
    padding:0 .55rem;
  }

  @include bp-max($bp-site-location-adjust) {
    font-size: 8px;
  }

  @include bp-max($bp-mobile) {
    font-size: .8rem;
    margin-right: 0;
    border-left: 0 none;
    display: block;
    text-transform: uppercase;
    width: 100%;
  }

  &:hover{
    opacity: 1;
    color: $color-brand-white;

    @include bp-max($bp-mobile) {
      color: $color-brand-yellow;
    }
  }

  &.active {
    opacity: 1;
    color: $color-text-dark;
    background-color:$color-brand-white;
    position: relative;

    @include bp-max($bp-mobile) {
      background-color: $color-brand-yellow;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      border-bottom: 1px solid $color-brand-white;

      @include bp-max($bp-mobile) {
        border-color: $color-brand-yellow;
      }

    }
  }
}
