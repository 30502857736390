.page-insights-landing {
	$spacing: 50px;

	.media-contacts-container {
		margin-top: rem(25px);
		margin-bottom: 0;
	}

	.media-contacts {
		h2{
			text-align: center;
		}
	}
	.subscribe-publications {
		margin: 3rem 0;
	}


	.insights-landing-search {
		.search-results-list {
			&:not(:last-of-type) {
				padding-bottom: 3rem;
			}
		}
	}

	.view-our-social-link {
	    $spacing: 50px;

		margin-top: px-to-rem($spacing);
		margin-bottom: px-to-rem($spacing);
	}

	@include bp-min($bp-mobile-portrait) {
		.insights-landing-search {
			margin-bottom: px-to-rem(50px);
		}

		.subscribe-publications {
			margin: 2rem 0 1rem;
		}
	}
}
