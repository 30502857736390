.tab-menu {
    margin-bottom: $spacing-unit;
    margin-top: $spacing-unit;
    position: relative;
    z-index: 10;

    &.our-story-explore-tab-menu {
        border-bottom-width: 0;

        @include bp-max($bp-mobile) {
            .menu-items {
                display: none;
            }
        }
    }

    &.tab-menu-as-header {
        .menu-items {
            visibility: visible;
        }

        .menu-item {
            cursor: auto;
        }

        .tab-menu-btn {
            cursor: auto;

            &:after {
                content: '';
            }
        }
    }

    @include bp-min($bp-mobile-landscape) {
        border-bottom: 1px solid $color-gray-white-dust;
        margin-top: 0;
        text-align: left;
    }

    @include bp-max($bp-mobile-landscape) {
    }

    .menu-items {
        display: block;
        position: absolute;
        z-index: 6;
        width: 100%;
        margin: 0;
        background-color: $color-brand-white;
        border: 1px solid $border-color-input;
        border-width: 0 1px;
        opacity: 0;
        top: 20%;
        visibility: hidden;
        transition: top $base-animation-timing ease-out, opacity $base-animation-timing ease-out, visibility 100ms $easing $base-animation-timing;

        &.filter-small {
            .menu-item {
                @include bp-min($bp-mobile-landscape) {
                    padding: 0.75rem .9rem 1.2rem;
                }        
                @include bp-min($bp-tablet) {
                    padding-left: .9rem;
                    padding-right: .9rem;
                }
                @include bp-min($bp-desktop-large) {
                    padding-left: 1.2rem;
                    padding-right: 1.2rem;
                }
            }
        }

        @include bp-min($bp-mobile-landscape) {
            position: relative;
            top: auto;
            visibility: visible;
            opacity: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 0.75rem 0 0.25rem;
            border: 0 none;

            .is-expanded + & {
                top: auto;
            }

            &.people-menu {
                flex-wrap: nowrap;
                text-align: center;
            }
        }

        @include bp-min($bp-tablet) {
            &.people-menu {
                flex-wrap: nowrap;
            }
        }
    }

    &.is-expanded {
        &:not(.tab-menu-as-header) {
            .menu-items {
                top: 100%;
                visibility: visible;
                opacity: 1;
                transition: top 1s $easing, opacity 1s $easing;

                @include bp-min($bp-mobile-landscape) {
                    top: auto;
                }
            }
        }
    }


    &.our-story-explore-tab-menu {
        .menu-items {
            padding-top: 0;
        }

        .menu-item {
            margin-bottom: 0;
            padding-top: 0;
        }
    }


    li {
        margin-bottom: 0;
    }

    .menu-item {
        @extend %body-5;
        display: block;
        padding: 1rem;
        color: $color-green-blue-lagoon;
        cursor: pointer;
        border-bottom: 1px solid $border-color-input;
        width: 100%;
        position: relative;

        &.active {
            color: $color-brand-red;
            font-family: $font-family-sans-serif-bold;
            font-weight: bold;
            // display: none;
            a {
                color: $color-brand-red;
                font-weight: bold;
            }
        }
        &:hover {
            color: black;
        }

        &.one-tab {
            padding-right: 0;
            padding-left: 0;
            cursor: default !important;

            @include bp-max($bp-tablet) {
                display: none;
            }
        }

        &.gen-item {
            a {
                color: currentColor;
                outline: none;
                border: none;
                &:focus {
                    outline: thin dotted;
                }
            }
        }

        @include bp-min($bp-mobile-landscape) {
            width:auto;
            display: inline-block;
            padding: 0.75rem 1rem 1.2rem;
            border-bottom: 0 none;
            /*&:first-of-type{
				padding-left:0;
			}*/
            &.active {
                //	display: inline;
            }
        }

        @include bp-min($bp-desktop-large) {
            &.people-menu {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                width: auto;
            }
        }

        @include bp-min($bp-tablet) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 1.35rem;

            &.people-menu {
                text-align: center;
            }
        }

        li:last-of-type {
            &.menu-item, .menu-item {
                @include bp-min($bp-mobile-landscape) {
                    padding-right: 0;
                }
            }
        }

        &.menu-item--external {
            @include bt-icon-after($bt-var-external-link) {
                margin-left: 0.5em;
                font-size: 0.9em;
            }
        }

        a {
            display: block;
            padding: 0;
            word-break: keep-all;

            &:before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
        // @include bp-max(415px) {
        // 	// margin-right: 2rem;
        // 	letter-spacing: 0;
        // }
    }
}

    .tab-menu-btn {
        @extend %body-3;
        width: 100%;
        border: 1px solid $border-color-input;
        padding: 1rem;
        text-align: left;
        display: none;
        z-index: 8;
        position: relative;
        color: $color-brand-red;
        background-color: $color-brand-gray-light;

        @include bp-max($bp-mobile-landscape) {
            display: block;
        }

        @include bt-icon-after($bt-var-angle-down) {
            position: absolute;
            right: 1rem;
            transform: translate3d(0, -50%, 0);
            transition: transform $base-animation-timing $easing;
            top: 1.5rem;
        }


        &.one-tab {
            text-align: center;
            background-color: white;
            border: none;

            &:after {
                content: '';
            }
        }

        &:hover {
        }

        .tab-menu.is-expanded & {
            &:after {
                transform: translate3d(0, -50%, 0) rotateX(180deg);
            }
        }
    }

    .tab-content-general--wrapper {
        position: relative;

        .tab-content-general {
            visibility: hidden;
            opacity: 0;
            height: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            .article-share-tools {
                margin-left: -6rem;

                .hold {
                    margin-left: 0px;
                }

                @include bp-max($bp-mobile) {
                    display: none;
                }
            }

            .rich-text:first-of-type {
                display: none !important;

                .section-header {
                    text-align: left;
                    margin-bottom: 0;

                    &::before {
                        height: 0;
                        border-bottom: none;
                    }

                    h2 {
                        font-size: 1rem;
                        font-family: $font-family-sans-serif-bold;
                        max-width: 100%;
                        padding: 0;
                        color: #000;
                    }
                }
            }

            &.is-active-tab {
                visibility: visible;
                overflow: visible;
                opacity: 1;
                height: auto;
                z-index: 5;
                position: relative;
                transition: opacity $base-animation-timing ease-out,visibility 100ms $easing $base-animation-timing;

                .rich-text:first-of-type:not(.accordions-drawer-list-container) {
                    display: block !important;
                }
            }
        }
    }

    .tab-menu {
        &.tab-menu--insights-landing-search-results {
        }
    }
