.location-selection {
	margin-top: $spacing-unit-section;

	.section-header {
		margin-bottom: $spacing-unit;
	}

	.location-helper-text {
		@extend %body-3;

		background-color: $color-brand-gray-light;
		padding: 1rem;
		color: $color-text-light;
		text-align: center;

	}
	.location-groups {
		overflow: hidden;
		margin: 0;
		padding: 0;
	}


	.location-list {
		padding-left: 0;
		display: none;
		padding-top: $spacing-unit;
		padding-bottom: $spacing-unit-block;
		
	}

	.location-list-item {
		flex: 1 1 50%;

	}

	.location-list-link {
		@extend %body-3;

		background-color: $color-brand-blue;
		display: block;
		color: $color-brand-white;
		background-color: $color-button-primary;
		padding: 1rem;
		text-align: center;

		&:hover {
			background-color: $color-button-primary-hover;
			color: $color-brand-white;
	  	}
	}
}
