$site-filter-margin-left: 0.75rem;

.site-search-filters {
    $border: 1px solid $color-gray-gainsboro;

    border-bottom: $border;
    border-top: $border;
    margin-bottom: 2.7rem;
    margin-top: 1.7rem;
}

.site-search-filters__filter-list {
    @extend %site-search-padding;

    $padding-vertical: 0.9rem;

    padding-top: $padding-vertical;
    padding-bottom: $padding-vertical;
    margin-bottom: 0;

    @media (min-width: $bp-mobile-portrait) {
        display: inline-block;
    }
}

.site-search-filters__filter-list-item {
    align-items: center;
    display: inline-flex;
    margin-top: 0.75rem;

    @media (min-width: $bp-mobile-portrait) {
        margin-bottom: 0;
        margin-top: 0;
    }

    &:not(:last-of-type) {
        &::after {
            color: $color-text-icons;
            content: "  |  ";
            white-space: pre;
        }
    }

    .site-search-filters__filter-btn {
        font-size: 0.75rem;
    }

    &.site-search-filters__filter-list-item--is-active {
        .site-search-filters__filter-btn {
            color: $color-brand-black;
            font-family: $font-family-sans-serif-bold;
        }
    }
}

.site-search-filters__clear-filters-btn {
    color: $color-green-blue-lagoon;
    font-size: 0.75rem;
    font-style: normal;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    @media (min-width: $bp-mobile-portrait) {
        margin-left: $site-filter-margin-left;
    }

    &:hover {
        color: $color-brand-dark-black;
    }
}

.site-search-filters__filter-btn {
    color: $color-text-icons;
    font-family: $font-family-sans-serif;
    font-size: 1rem;
}

.site-search-filters__filters-label {
    font-family: $font-family-sans-serif;
    font-size: 0.875rem;
}