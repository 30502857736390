.resource {
	&-list {
		margin-top: $spacing-unit-section-large;
	}

	&-content {
		.result-title {
			position: relative;
			padding-bottom: 20px;

			.resource-icon {
				@extend %body-5;
				line-height: 20px;
				color: $color-brand-blue;
				display: block;
				padding-right: 24px;
				height: 20px;
				position: absolute;
				right: 0;
				bottom: 0;
			}

			&.link {
				.resource-icon {
					background: url(../images/external-link.png) no-repeat 100% center;
				}
			}

			&.download {
				.resource-icon {
					background: url(../images/download.png) no-repeat 100% center;
				}
			}

			@media (min-width: $bp-mobile) {
				padding-right: 100px;
				padding-bottom: 0;

				.resource-icon {
					opacity: 0;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translate(50%, -50%);
					transition: transform 0.4s $easeInOutQuint,
								opacity 0.4s $easeInOutQuint;
				}

				&:hover {
					.resource-icon {
						transform: translate(0, -50%);
						opacity: 1;
					}
				}
			}
		}
	}
}