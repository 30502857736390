.offices-modal {
	background-color: rgb(255,255,255);
	position: absolute;
	overflow:hidden;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	box-shadow: 0 0 12px 6px rgba(0,0,0,0.3);
	opacity: 0;
	visibility: hidden;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    transition:  transform .5s ease-out,
    			 opacity .3s ease-out,
    			 visibility .5s ease-out;

	.office-item {
		@extend %body-3;
		@include vertical-align;

		line-height: 2;
		display: none;
		text-align: center;
		opacity: 0;
		transition: opacity .5s ease-out;

		&.is-modal-active  {
			display: block;
		}
	}

	.address {
		margin-bottom: 0;
	}

	.office-name {
		@extend .h1-size;
		text-transform: capitalize;
	}

	&.is-show-modal {
		transform: scale3d(1, 1, 1);
		opacity: 1;
		visibility: visible;
		transition:  transform .5s ease-in;

		.office-item {
			opacity: 1;
			transition: opacity .5s ease-out .25s;
		}
	}
}

.offices-modal-close {
	position: absolute;
	right: 4%;
	top: 6%;
	color: $color-text-gray;

	&:hover {
		color: $color-brand-black;
	}

	@include bt-icon-before( $bt-var-times) {
		font-size: px-to-rem(25px);
  }
}
