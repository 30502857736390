.entity-grid {
	@extend %list-reset;


	

	@include bp-min($bp-mobile-landscape) {
		display: flex;
  		flex-flow: row wrap;
  		align-items: stretch;
  		margin-right: -1px;
	}

}


.entity-grid-item {

	@include bp-min($bp-mobile-landscape) {
		flex: 1 1 50%;
	}

	@include bp-min($bp-mobile) {
		flex: 1 1 100%;
	}

	@include bp-min($bp-tablet) {
		flex: 1 1 50%;
	}
}


.entity-grid-link {
	display: block;
	position: relative;

	overflow: hidden;
	height: px-to-rem(165px);
	text-align: center;
	padding: 0 $spacing-unit-small;
	transition: none;
	border: 1px solid $color-brand-white;
	border-width: 0 1px 1px 0;

	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 3;
		background-color: rgba(0,0,0,0.3);
		transition: background-color 1s ;
	}

	&:hover {

		&:after {
			background-color: rgba(0,0,0,.2);
		}

		.entity-grid-img {
			filter: grayscale(0%);
			transform:translate(-50%, -50%) scale(1.05);
		}
	}
}

.entity-grid-title {
	@extend %body-3;

	@include vertical-align;
	@include smooth-font;

	position: absolute;
	left: 0;
	display: block;
	width: 100%;
	padding: 0 1rem;
	line-height: 1.6;
	color: $color-brand-white;
	z-index: 10;

	
}

.entity-grid-img {
	@include absolute-center-align;

	z-index: 2;
	min-width: 100%;
	min-height: 100%;
	max-width: none;
	transition: all 1s ;
	transform-origin: 50% 50%;
	filter: grayscale(100%);
}