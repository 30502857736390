.filter-toggles-menu {
	position: relative;
	z-index: 100;

	.filter-toggles {
		&__mobile-btn {
			width: 100%;
			background-color: $color-gray-gainsboro;
			color: $color-brand-blue;
			font-size: px-to-rem(16px);
			line-height: 1.2;
			font-family: $font-family-sans-serif;
			text-align: left;
			padding: 13px;
			font-weight: 900;
			z-index: 8;
			position: relative;

			@include bt-icon-after($bt-var-angle-down) {
				position: absolute;
				font-size: px-to-rem(26px);
				right: 1rem;
				transform: translate3d(0, -50%, 0);
				transition: transform $base-animation-timing $easing;
				top: 1.5rem;
			}

			@media (min-width: $bp-mobile) {
				display: none;
			}
		}

		&__list {
			display: flex;
			flex-wrap: wrap;
			justify-content: left;
			background-color: $bg-articles;
			padding: 15px 15px 25px;
			position: absolute;
			width: 100%;
			top: 20%;
			visibility: hidden;
			opacity: 0;
			z-index: 6;
			transition: top $base-animation-timing ease-out,
	                opacity $base-animation-timing ease-out,
	                visibility 100ms $easing $base-animation-timing;
			
			&.open {
				top: 100%;
				visibility: visible;
				opacity: 1;
			}

			@media (min-width: $bp-mobile) {
				position: relative;
				padding: 0;
				margin: 0;
				background-color: transparent;
				visibility: visible;
				opacity: 1;
			}
		}

		&__intro-label {
			font-size: px-to-rem(14px);
			line-height: 1.2;
			font-family: $font-family-sans-serif;
			color: $color-text-headings;
			padding-right: 15px;
			padding-top: .5rem;
			padding-bottom: .5rem;
			display: none;

			@media (min-width: $bp-mobile) {
				display: block;
			}
		}

		&__list-item {
			$spacing: $spacing-unit/2;
			 
			margin-right: $spacing;
			margin-bottom: $spacing;
		}

		&__toggle {
			font-size: px-to-rem(14px);
			line-height: 1.2;
			font-weight: 900;
			font-family: $font-family-sans-serif;
			color: $color-brand-blue;
			padding: 6px 15px;
			background-color: $color-brand-white;
			border-radius: 15px;

			&.checked {
				background-color: $color-brand-blue;
				color: $color-brand-white;
			}

			@media (min-width: $bp-mobile) {
				background-color: $color-brand-gray-light;
			}
		}

		&__clear-wrapper {
			position: absolute;
			bottom: 0;
			right: 15px;

			@media (min-width: $bp-mobile) {
				position: relative;
				margin-left: auto;
				right: initial;
				bottom: initial;
			}	
		}

		&__clear {
			font-size: px-to-rem(14px);
			line-height: 1.2;
			font-family: $font-family-sans-serif;
			color: $color-text-headings;
			text-decoration: underline;
		}
	}

	&.is-expanded {
		.filter-toggles {
			&__mobile-btn {
				&:after {
					transform: translate3d(0, -50%, 0) rotateX(180deg);
				}
			}

			&__list {
				top: 100%;
				visibility: visible;
				opacity: 1;
				transition: top 1s $easing,
							opacity 1s $easing;
			}
		}
	}
}