.search-form-buttons {
	display: flex;
	justify-content: space-between;

	.btn-advance-search {
		@extend .btn--text-link;

		
		margin: 0;
		color: $color-button-primary;
		
		transition: color .3s;

		@include bt-icon-before($bt-var-plus) {
			padding-right: .5em;
			
			font-size: px-to-rem(12px);
		}

		&.show-advanced {
			&:before {
				content: $bt-var-minus;
			}
		}

		&:hover {
			color: $color-button-primary-hover;
		}
	}
}