/* Variables */
$icomoon-font-path: "./../fonts/icons/" !default;

$ico-icon-name: "icomoon";

$icomoon-hash: qla6b3;


$bt-var-times: "\f00c";
$bt-var-times-circle: "\f00d";
$bt-var-check: "\f012";
$bt-var-check-circle: "\f013";
$bt-var-check-square: "\f014";
$bt-var-plus: "\f01b";
$bt-var-minus: "\f021";
$bt-font-size-base:   14px !default;

@mixin icon-face-family($font-family, $file-path, $font-weight:normal, $font-style: normal) {
  @font-face {
  	font-family: $font-family;
  	src:  url('#{$file-path}#{$font-family}.ttf?qla6b3') format('truetype'),
					url('#{$file-path}#{$font-family}.woff?qla6b3') format('woff'),
					url('#{$file-path}#{$font-family}.svg?qla6b3##{$file-path}') format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
  }
}

//sass mappings
$icons: (
  alumni: "\e90e",
  camera: "\f032",
  video-play: "\f07b",
  caret-down: "\f0d7",
  caret-up: "\f0d8",
  close: "\f00c",
  plus: "\f01b",
  minus: "\f021",
  chevron-down: "\e90a",
  chevron-left: "\e90b",
  chevron-right: "\e90c",
  chevron-up: "\e90d",
  globe: "\f0c8",
  long-arrow-right: "\e900",
  long-arrow-left: "\e901",
  long-arrow-down: "\e902",
  long-arrow-up: "\e903",
  search: "\e904",
  user: "\e905",
  linkedin: "\e906",
  youtube-play: "\e907",
  facebook: "\e908",
  twitter: "\e913",
  twitter-x: "\e913",
  close-thin: "\f00d",
  plus-thin: "\f01c",
  minus-thin: "\f022",
  external: "\f05b",
  external-alt: "\f05c",
  chevron-down-heavy: "\f091",
  chevron-right-heavy: "\f093",
  region-north-america: "\e90f",
  region-europe: "\e910",
  region-asia: "\e911"
);


//Mixin to load icon font
@include icon-face-family($ico-icon-name, $icomoon-font-path);

//attribute selector of class name for icons
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $ico-icon-name !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


//Generate Class for each icon based on sass mapping object
@each $name, $icon in $icons {
  .icon-#{$name}:before {
    content: $icon;
  }
}
