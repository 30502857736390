.media-tiles {
    display: flex;
    justify-content: flex-start;
    padding-top: 20px;
    flex-wrap: wrap;
    padding-bottom: 0px;
    .media-tile {
        background-color: $bg-articles;
        color: $color-text-dark;
        // border-right:solid 20px white;
        position: relative;
        z-index: 5;
        margin-bottom: 8px;
        width: calc(33.333% - 8px);
        margin-left: 8px;
        &__wrapper {
            position: relative;
            overflow: hidden;
            height: 100%;
            &:hover {
                &:before {
                    transform: translate3d(0,0,0);
                }
                .media-tile__title, .media-tile__tag-item, .media-tile__text,.media-tile__meta, .article-tag, i.external-link {
                    color: white;
                }
            }
            &:before {
                background-color: #535353;
                content: "";
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                right: 0;
                top: -1px;
                transition: transform .3s cubic-bezier(.165,.84,.44,1);
                width: 100%;
                z-index: 1;
                transform: translate3d(0,-100%,0);
            }            
        }   
        &__content {
            padding: px-to-rem(40px);
            position: relative;
            z-index: 2;
            color: $bg-footer-home;
            @include bp-max($bp-mobile) {
                padding: 40px;
            }

            @media (max-width: $bp-mobile-portrait) {
                padding: 35px;
            }
        }
        i.external-link {
            position: relative;
            float:right;
            top: -30px;
            color: $bg-footer-home;
        }
    
        &__title {
            font-size: px-to-rem(24px);
            margin-bottom: px-to-rem(30px);
            color: $bg-footer-home;
        }
        &__text {
            margin-bottom: px-to-rem(30px);
        }
        &__meta {
            font-size: px-to-rem(18px);
            font-style: italic;
            color: $bg-footer-home;
            margin-bottom: px-to-rem(30px);
        }
        &__tag-item {
            font-size: px-to-rem(14px);
            font-style: italic;
            color: #606060;
            margin-bottom: px-to-rem(30px);
            +.media-tile__tag-item {
                &:before {
                    content: '|';
                    display:inline-block;
                    padding: 0 6px;
                }
            }
        }
    }
    @include bp-max(1200px) {
        .media-tile {
            width: calc(50% - 8px);
        }
    }
    @include bp-max($bp-mobile) {
        padding-bottom: 20px;
        padding-top: 0;
        .media-tile {
            width: 100%;
        }
    }

}