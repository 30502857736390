.people-landing-search {
	display: block;
	$spacing: .5rem;

	.name-search {
		display: flex;
		justify-content: space-between;

		.search-text-input {
			width: calc(50% - #{$spacing/2});
			margin-top: 0;
		}
	}

	.search-parameters {
		margin-bottom: 2rem;
	}

	.search-field {
		.search-text-input {
			margin-top: 0;
		}
	}

	.search-text-input {
		margin-top: $spacing;
	}

	.search-by-letter {
		margin-top: $spacing;
	}

	.search-form-buttons {
		margin-top: $spacing;
		margin-bottom: 4rem;
	}

	@include bp-max(415px) {
		.name-search {
			display: block;

			.search-text-input {
				width: 100%;

				&:last-of-type {
					margin-top: $spacing;
				}
			}
		}
	}
}

.page-people-landing {
	$animation-duration: .8s;

	.articles-grid-list {
		transition: opacity $animation-duration $easing,
					max-height $animation-duration $easing,
					visibility $animation-duration $easing;
		opacity: 1;		
		max-height: 10000px;
		visibility: visible;
	}

    &.has-search-run {
    	.articles-grid-list {
			opacity: 0;		
            max-height: 0px;
            margin: 0;
            overflow: hidden;			
            visibility: hidden;
		}
    }
}