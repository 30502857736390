.primary-text-input-container {
	display: flex;
	flex-direction: column;

	.search-text-input.search-text-input {
		flex-grow: 1;
		@include bp-min($bp-mobile) {
			margin-right: 0.8rem;
		}
}

	@include bp-min($bp-mobile) {
		flex-direction: row;
	}

	.btn-submit {
		margin-top: 1.2rem;
		@include bp-min($bp-mobile) {
			margin-top: 0;
		}
	}
}

.services-landing-search-form {
	.primary-text-input-container {
		.search-text-input{
			margin-right:0;
		}
	}
}

.search-text-input {
	$border-style: 1px solid $color-gray-gainsboro;

	padding: 1rem;
	border: $border-style;

	.text-input {
		width: 100%;
		border: none;
		padding: 0;
		font-family: $font-family-sans-serif;
		font-size: px-to-rem(14px);

		@include placeholder {
			color: $color-text-base;
		}
	}
}
