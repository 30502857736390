.article-page-header {
	$spacing: 1.5rem;

	text-align: center;
	padding-bottom: 3rem;
	margin-bottom: 3rem;
	border-bottom: 1px solid $color-gray-gainsboro;

	img {
		margin-top: 1rem;
		width: 100%;
	}

	.language-bar {
		margin-bottom: 0;
	}

	.tag {
		@extend %body-5;

		color: $color-brand-red;
	}

	.title {
		margin-top: px-to-rem(50px);
		font-weight: normal;

		@media (min-width: $bp-mobile-portrait) {
			font-size: px-to-rem(48px);
		}
	}

	.article-date {
		@extend %body-2;

		margin-top: $spacing;
	}

	.menu-items {
		position: relative;

		.page-tools-item-pdf {
			position: absolute;
			top: calc(100% + 10px);
			right: 0;

			a {
				max-height: 15px;
			}
		}
	}
}

// Our Story Detail
.article-page-header.article-page-header--our-story-detail {
	border-bottom: none;
	margin-left: auto;
	margin-right: auto;
	max-width: 65rem;
	padding-bottom: 0;
}

// Services Sub Page
.article-page-header.article-page-header--services-sub-page {
	padding-bottom: 0;
}
