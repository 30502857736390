.masonry-grid {

  &__item {
    width: 100%;
    margin-bottom: 1rem;
  }

  &__image {
    background-size: cover;
    background-position: center;
    height: px-to-rem(368px);
  }

  &__article-container {
    width: 100%;
    background-color: $bg-articles;
    color: $color-brand-black;
    overflow: hidden;
    position: relative;
    color: inherit;
  }

  &__article {
    padding: px-to-rem(45px);
  }

  @include bp-min($bp-desktop) {

    &__item {
      width: calc(50% - 6px);
      margin-right: px-to-rem(12px);
      margin-bottom: px-to-rem(12px);

      &:nth-child(even) {
        margin-right: 0;
      }
    }

    &__article-container {
      margin-bottom: 0;
    }
  }
}