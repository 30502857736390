.article-feature {

    &-list {
        display: flex;
        flex-flow: row wrap; 
    }

    background-color: $bg-articles;
    color: $color-brand-blue;
    overflow: hidden;
    position: relative;
    width: 33.33334%;
    margin-bottom: 30px;

    @media (min-width: $bp-mobile) {
        &:not(.article-feature--full) {
            min-height: 194px;
        }
    }

    &__article {
        padding: 8%;
    }

    &__image {

    }

    &--full {
        width: 100%;
        // background: url(../images/radial_decoration.png) no-repeat 100% 100% $color-brand-gray-light;
        border-top: 2px solid $color-brand-red;

        &:before {
            content: "";
            height: 400px;
            width: 400px;
            background: url(../images/complex-vector-gray.svg) no-repeat;
            background-size: 400px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 90%;
            z-index: 0;
        }

        .article-feature {
            &__tag {
                @extend %body-5;
    
                color: $color-brand-dark-gray;
                text-align: center;
                padding: 4% 4% 0;
            }

            &__image {
                padding: 4% 4% 0;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                img {
                    object-fit: cover;
                    min-height: 100%;
                    min-width: 100%;
                }
            }

            &__article {
                text-align: center;
                padding: 4% 4% 12%;
                position: relative;
            }
        }

        &.article-feature {
            &--yellow {
                background-color: $color-brand-yellow;
                border-top: 0;
                color: $color-brand-dark-gray;
                
                &:before {
                    right: 70%;
                    bottom: 70%;
                    left: unset;
                    top: unset;
                    transform: translateX(0);
                }
                
                .article-feature {
                    &__article {
                        padding-bottom: 4%;
                    }
                }

                .article-tag {
                    color: $color-brand-dark-gray;
                }

                .article-meta {
                    color: $color-gray-mortar;
                }

                @media (min-width: $bp-mobile) {
                    flex-direction: row-reverse;

                    &:before {
                        right: 75%;
                        bottom: 45%;
                    }

                    .article-feature {
                        &__image {
                            padding: 0;
                        }
                    }
                }
            }

            &--green {
                background-color: $color-green-gray;
                border-top: 0;
                color: $color-brand-dark-gray;
                
                &:before {
                    right: 70%;
                    bottom: 70%;
                    left: unset;
                    top: unset;
                    transform: translateX(0);
                }
                
                .article-feature {
                    &__article {
                        padding-bottom: 4%;
                    }
                }

                .article-tag {
                    color: $color-brand-dark-gray;
                }

                .article-meta {
                    color: $color-gray-mortar;
                }

                @media (min-width: $bp-mobile) {
                    flex-direction: row-reverse;

                    &:before {
                        right: 75%;
                        bottom: 45%;
                    }
                }
            }

            &--text-only {
                border-color: $color-green-blue-lagoon;
                color: $color-brand-black;

                .article-tag,
                .article-meta {
                    display: none;
                }

                .article-title {
                    font-size: 1.335rem;
                    line-height: 1.335;
                }

                .article-feature {
                    &__tag,
                    &__image {
                        display: none;
                    }

                    &__article {
                        width: 100%;

                        @media (min-width: $bp-mobile) {
                            padding: 3% 6% 10% 3%;
                        }
                    }
                }
            }
        }

        @media (min-width: $bp-mobile) {
            display: flex;
            flex-flow: row wrap; 
            border-width: 4px;

            &:before {
                height: 500px;
                width: 500px;
                background-size: 500px;
                left: 70%;
                top: 50%;
                transform: translateX(0);
            }

            .article-feature {
                &__image {
                    width: 40%;
                    padding: 2%;
                }

                &__article {
                    width: 60%;
                    padding: 2%;
                    text-align: left;
                }
            }
        }

        @media (min-width: $bp-desktop) {
            &:before {
                height: 600px;
                width: 600px;
                background-size: 600px;
            }
        }
    }

    &__wide-list {
        display: flex;
        margin-right: -2rem;
        margin-left: -2rem;
        padding-left: 1.60525rem;
        padding-right: 1.60525rem;
        overflow-x: auto;

        &:after {
            content: "";
            min-width: 1.60525rem;
            height: 1rem;
            display: block;
            flex: 0 1 auto;
        }
        
        &::-webkit-scrollbar {
            display: none;
        }

        .article-feature {
            margin-right: 6px;
            margin-left: 6px;
            min-width: 285px;
            padding-bottom: 40px;
        
            .article-title {
                line-height: 1.2;
            }

            .article-meta {
                position: absolute;
                bottom: 18px;
            }
        }

        @media (min-width: $bp-mobile) {
            flex-flow: row wrap; 

            .article-feature {
                min-width: 0;
                width: calc(33.33334% - 12px);
            }
        }

        @media (min-width: $bp-desktop) {
            margin-right: -15px;
            margin-left: -15px;
            padding-left: 0;
            padding-right: 0;

            .article-feature {
                margin-right: 15px;
                margin-left: 15px;
                width: calc(33.33334% - 30px);
            }
        }
    }
}


@include bp-max($bp-mobile) {
    .inner-wrapper--resources{

        .article-feature__wide-list{
            flex-wrap: wrap;

            @include bp-max($bp-mobile-landscape) {
                .article-feature{
                    width: 100%;
                }
            }
        }
    }
}

.article-feature-resource {
    &__row {
        display: flex;
        flex-flow: row wrap;
    }

    &__item {
        margin-right: 5px;
        width: calc(33.4% - 4px);
        min-height: px-to-rem(300px);
        background-color: $bg-articles;
        color: $color-text-dark;
        overflow: hidden;
        position: relative;
        transform: translateZ(0);
        display: block;
        // this prevents janky text on hover
        transition: $base-animation;

        &:nth-child(3n) {
            margin-right: 0;
        }

        @include bp-max($bp-desktop) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
        }
    }

    &__padding {
        padding: px-to-rem(30px) !important;
    }

    &__no-margin {
        margin-bottom: 5px;
    }
}

