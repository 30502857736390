$padding: 6rem;

.jump-sections {
	$spacing: 50px;

	position: relative;
	margin-top: px-to-rem($spacing);

	&:first-child {
		margin-top: 0;
	}


	.contacts-module {
		margin-top: px-to-rem($spacing);
	}

	.related-services,
	.related-experience,
	.related-insights,
	.credentials,
	& + .articles-grid-list {
		margin-top: px-to-rem($spacing);
	}

	@include bp-max($bp-mobile) {
		$padding: 0;
		padding-left: $padding;
		padding-right: $padding;
	}

	@include bp-max($bp-mobile-landscape) {
		// padding-left: 0;
		// padding-right: 0;
	}
}

.jump-sections--services-sectors-detail,
// .jump-sections--general,
.jump-sections--services-sectors-deep {
	padding-left: 0;
	padding-right: 0;
}

.jump-section-spacing{
	& + & {
		margin-top:$spacing-unit-section-large;
	}
}

.jump-section {

	& + & {
		margin-top: $spacing-unit-section-large;
	}

	.rte-expand {
		padding-left: $padding;
		padding-right: $padding;
	}

	.jump-sections--general &  {
		.rte-expand {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.jump-section__content-container {
	padding-left: $padding;
	padding-right: $padding;
}

.jump-sections {
	&.jump-sections--general {
        .section-header {
            margin-bottom: 1.4rem;
            margin-top: -1.4rem;
        }
    }
}