.article-related-blogs {
	.header {
		text-transform: uppercase;
		font-family: $font-family-sans-serif-bold;
		font-size: px-to-rem(14px);
	}

	.related-blogs {
		margin: 0;
		margin-top: 1rem;
		padding: 0;
		display: flex;
	}

	.related-blog {
		display: inline-block;
	}

	.related-blog-link {
		@extend .btn--blue;

		margin-right: $spacing-unit/2;
	}
}