.home-hero {
    height: 100%;
    border-top: $site-header-bar-height solid transparent;
    overflow: hidden;

    //is sticky classes
    position: fixed;
    z-index: 2;
    left: $site-header-nav-width;
    right: 0;
    bottom: 0;
    height: 100%;


    @include bp-max($bp-desktop-large) {
      left: $site-header-nav-width-desktop;
    }

    @include bp-max($bp-tablet) {
      left: $site-header-nav-width-tablet;
    }

    @include bp-max($bp-mobile) {
      border-top-width: $site-header-height + $site-location-bar-height;
      left: auto;
      right: auto;
      bottom: auto;
      position: relative;
      z-index: 5;
    }
}

.home-hero-canvas {
  background: $color-brand-black;
  height: 100%;
  position: relative;

}

.home-hero-img {
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: url('./../images/home-hero-img') no-repeat top center;
  background-size: cover;
  opacity: 1;
  transition: opacity $animation-dim-timing $easeInOutQuint;


  .is-animating-phase-1 & {
    opacity: .55;
  }
}


.home-hero-titles {
  @extend %giga-size;
  @include absolute-center-align;

  width: 100%;
  font-family: $font-family-titles;
  z-index: 15;
  text-align: center;
  display: block;
  padding:0 3%;
  color: $color-brand-white;

  &:lang(ja){
    font-family: $font-family-titles-ja;
  }

  .home-hero--eggplant &{
    color: $color-brand-eggplant;
  }

  .home-hero--red &{
    color: $color-brand-red;
  }

  .home-hero--yellow &{
    color: $color-brand-yellow;
  }
}


//PHASE 2 animations Begin

$animation-complex-phase-2: .5s;

$animation-complex-phase-2-fadeout: .4s;

.home-hero-title {
  display: block;
  opacity: 0;
  transform: translateY(30%);
  transition: opacity $animation-complex-phase-2 ease-in ,
              transform $animation-complex-phase-2 ease-in;
}

.home-hero-multi-title {

  transition: opacity $animation-complex-phase-2 ease-in ,
              transform $animation-complex-phase-2 ease-in;

  .is-animating-phase-2  & {
    transform: translateY(0);
    opacity: 1;
  }

  .is-animating-phase-3  & {
    transition: opacity $animation-complex-phase-2-fadeout ease-out 0s !important;
    opacity: 0;
  }
}

$animation-complex-secondTitleTiming: $animation-complex-phase-2;

.home-hero-multi-titles {

  .home-hero-multi-title[data-animate-title="second"] {
    transition-delay: $animation-complex-secondTitleTiming;
  }
}

//PHASE 3 animation
$animation-complex-callout-timing: .7s;
$animation-complex-callout-timing-delay: $animation-complex-phase-2-fadeout + $animation-complex-phase-2;


.home-hero-titles[data-animate-phase="phase-2"] {

  .home-hero-title {
    .is-animating-phase-3 & {
      transform: translateY(0);
      opacity: 1;
      transition-delay: $animation-complex-phase-2-fadeout;
    }
  }
}

@keyframes arrowLoopVertical {
    0% {
      opacity: 0;
      transform: translate3d(-50%, -100%, 0);
    }
    50%, 60%, 65% {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(-50%, 0%, 0);
    }
}

.home-hero-callout {
  @extend %uc;
  $spacing: 30px;

  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate3d(-50%, 100%, 0);
  background-color: $color-brand-yellow;
  color: $color-brand-dark-gray;
  border-color: transparent;
  font-size: .7em;
  padding: $spacing 5rem;
  z-index: 15;
  letter-spacing: 0.1rem;
  white-space: nowrap;
  transition: transform $animation-complex-callout-timing $easeInOutQuint $animation-complex-callout-timing-delay,
    background-color .3s;

  &:after,
  &:before {

    @include font-awesome-icon
    content: $fa-var-long-arrow-down ;
    font-size: 1rem;
    transform: translate3d(-50%,-50%,0);
    position: absolute;
    top: 50%;
    color: $color-brand-white;
  }

  &:after {
    left: calc(100% - #{$spacing});
  }

  &:before {
    left: $spacing;
    margin-left: 0;
    margin-right: 3rem;
  }

  &:hover {
    background-color: $color-orange;

    &:after,
    &:before {
      animation: arrowLoopVertical 1.5s infinite;
    }
  }

  @include bp-max($bp-mobile) {
    $spacing: 20px;

    padding: $spacing 3rem;

    &:before {
      left: $spacing;
    }

    &:after {
      left: calc(100% - #{$spacing});
    }
  }

  .is-animating-phase-3 & {
    transform: translate3d(-50%, 0, 0);
  }
}
