.rich-text-redesign{
	.section-header {
			text-align: left;
			margin-bottom: 0;
			&::before{
				height:0;
				border-bottom:none;
			}
			h2{
				font-size:1rem;
				font-family: $font-family-sans-serif-bold;
				max-width:100%;
				padding: 0;
				color: #000;
			}
		}
	}