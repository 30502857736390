.location-box {
    font-size: px-to-rem(15px);
    height: px-to-rem(50px);
    position: relative;
    display: table;
    text-align: center;
    width: 100%;

    a {
        display: table-cell;
        vertical-align: middle;
        background-color: $color-button-primary; 
        color: $color-brand-gray-light;
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
        width: 100%;

        &:hover {
            background-color: $color-button-primary-hover;
        }
    }
}
