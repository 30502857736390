.articles-grid {
  margin-top: $spacing-unit;
  position: relative;
  z-index: 5;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.25);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;

  @include bp-max($bp-tablet) {
    margin-top: 0;
    padding-top: 60px;
  }

  @include bp-max($bp-mobile) {
    box-shadow:  none;
  }
}

.articles-grid-header {
 @extend %large-size;

 color: $color-accessibility-green-text;
 margin-bottom: 2rem;
 text-align: center;
}