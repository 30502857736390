.popup-menu {
  line-height: 1.4;
  background-color: $color-brand-white;
  border: 1px solid $color-gray-gainsboro;
  padding: 1em;
  text-align: left;
  max-width: 250px;
  $triangle-size: 15px;
  
  @include center-align('horizontal');
  top: calc(100% + #{$triangle-size});
  z-index: 5;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s linear
    visibility 1ms linear 0.5s;

  &:after {
    @include center-align('horizontal');

    content: '';
    width: $triangle-size;
    height: $triangle-size;
    border: 1px solid $color-gray-gainsboro;
    border-width: thin 0 0 thin;
    bottom: 100%;
    margin-bottom: $triangle-size/-2 + 1px;
    transform: rotate(45deg);
    z-index: 2;
    background-color: inherit;
  }

  .is-active & {
    visibility: visible;
    opacity: 1;
    @include transition(opacity);
  }


  &-context {
    position: relative;
  }

  &-item {
    & + & {
      margin-top:0.5em;
      // padding-top: 0.5em;
      // border-top: thin solid $border-color-divider;
    }
  }

  &-link {
    @extend %body-5;
    text-transform: none;
    white-space: nowrap;
    font-size:12px;
    letter-spacing: 0;
    margin: 0;
    font-family: $font-family-sans-serif;
    color: $color-green-blue-lagoon;

    &:hover{
      color: $color-green-dark-green;
    }

    &.is-inactive {
      cursor: default;
      color: $color-text-gray;
    }
  }
}