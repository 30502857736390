.rte-expand--intro {

  .rte-expand-short {
    @extend .h4-size;
  }
}

.news-detail {
    .rte-expand-short{
        font-size:inherit;
        font-family:inherit;
        line-height:inherit;
      }
}

.rte-expand-full {
  overflow: hidden;
  display: none;
}
.rte-expand-button-full{
    width: 100%;
}

.rte-expand-button {
  text-align: center;
  padding-top: $spacing-unit;

  .btn-read-more  {
  	margin: 0 auto;
    display: block;
    transition: color .3s;

    &:hover {
        color: $color-brand-dark-black;
    }
  }
}

.contacts-resource{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.rte-expand--sectors-landing, .rte-expand--services-landing {
	margin-top: 2.5rem;
}
.module{
    iframe{
        max-width:100%;
    }
    padding: 20px 0;
    &.story-module{
            padding: 0;
            border-bottom:1px solid black;
            &:first-of-type{
                border-top:1px solid black;
            }
            .rte-expand-short{
                width:90%;
                float:left;
                margin:0;
                height:50px;
                line-height:50px;
                color: #066;
            }
            .rte-expand-button {
                position:relative;
                width:100%;
                padding:0;
                height:50px;
                line-height:50px;
                button{
                    color: #066;
                    width:100%;
                    position:absolute;
                    right:17px;
                    top:0;
                    text-align:right;
                    height:100%;
                }

            }
            .rte-expand-full-story{
                padding-bottom:0 !important;
                border-bottom:none !important;
                display:none;
            }
            .has-visibility{
                display:block;
            }
        }
    .story-quote-block{
        padding:0 40px 20px;
        color:#b49746;
        font-style:italic;
        line-height:1.3;
        font-size:18px;
        &:before{
            margin-left:-20px;
            padding-right:20px;
            content:"\0022";
        }
        &:after{
            content:"\0022";
        }
    }
}