.updates {
  position: relative;
  margin: 5rem 0;
  &__flex {
    display: flex;
  }
  &__image {
    background-image: url('/assets/release/images/homepage/informed-city.jpg');
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 140%;
    background-size: cover;
    opacity: 0;
    @include transition(opacity 0.75s);

    &--active {
      opacity: 1;
    }

  }
  &__background {
    overflow: hidden;
    position: relative;
    flex-basis: 100%;
    height: 500px;
    margin-right: 5rem;
  }
  &__items {
    flex-basis: 100%;
  }
  &__item {
    padding: 2rem;
    &:not(:last-of-type) {
      border-bottom: 1px solid #D9D9D9;
    }
    &:first-of-type {
      padding-top: 0;
    }
  }
  &__title {
    margin-bottom: 0.5rem;
    font-size: 24px;
    a {
      color: $color-text-dark;
      &:hover {
        color: $color-text-link;
      }
    }
  }
  &__date {
    color: $color-text-icons;
    font-style: italic;
  }
  &__cta {
    text-align: right;
  }

  @include bp-max($bp-desktop-large) {
    &__background {
      margin-right: 2rem;
    }
    &__item {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @include bp-max($bp-desktop) {
    &__flex {
      display: block;
    }
    &__background {
      margin: 0 0 3rem 0;
      height: 300px;
    }
    &__item {
      padding: 2em 0;
    }
  }

  @include bp-max($bp-mobile-portrait) {
    &__background {
      height: auto;
      padding-bottom: 90%;
    }
  }
}