.tabs-module {

}

.tabs-module-mobile-next {
  margin: $spacing-unit-block auto 0;
  display: block;
  width: 80%;
  color: $color-brand-white;
  background-color: $color-brand-eggplant;
  text-transform: capitalize;

  @include bp-min($bp-mobile-landscape) {
    display: none
  }
}