.contacts-links {
	$spacing: 50px;

	margin-top: px-to-rem($spacing);

	&.contacts-links__slim-margin{
		margin-top: 0;
	}
}

.contacts-links-list {
	margin-top: $spacing-unit-block;
	padding-left: 0;

	&.contacts-links-list__slim-margin{
		margin-top: 1rem;

		@include bp-max($bp-mobile) {
			margin-top: $spacing-unit-block;
		}
	}
}

.contacts-links-item {
	text-align: center;
}

.contacts-links-link,
.contacts-links-nonlink {
	@extend %body-3;

	background-color: $color-brand-blue;
	display: block;
	color: $color-brand-white;
	background-color: $color-button-primary;
	padding: 1rem;
}

.contacts-links-link {
	&:hover {
		background-color: $color-button-primary-hover;
		color: $color-brand-white;
  	}
}

.contacts-links-label {
	color: rgba(255,255,255, 0.85);
} 