.select-drop-down,
.select-drop-down-aud {
	$border-style: 1px solid $color-gray-gainsboro;
	$spacing: 1rem;
	$font-size: 14px;

	position: relative;
	font-family: $font-family-sans-serif;



	.display-item {
		padding: $spacing;
		padding-right: $spacing * 2;
		border: $border-style;
		position: relative;
		cursor: pointer;
		font-size: px-to-rem($font-size);
		background-color: $color-brand-white;

		&:after {
			content: $bt-var-angle-down;
			font-family: $font-family-icons;
			font-size: .75rem;
			vertical-align: middle;
			color: $color-green-blue-lagoon;
			position: absolute;
			top: 50%;
			right: $spacing;
			transform: translateY(-50%);
		}
		&.location{
			padding-right:$spacing;
			&:after {
				right: 0;
			}
		}
	}

	.drop-down-options {
		padding: 0;
		margin: 0;
		list-style-type: none;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		display: none;
		background-color: $color-brand-white;
		max-height: 270px;
    overflow: auto;
		border: $border-style;
		border-top: none;
	}

	.drop-down-option {
		margin: 0;
		padding: $spacing;
		border-top: $border-style;
		transition: background-color .3s;
		cursor: pointer;
		font-size: px-to-rem($font-size);

		&:first-child {
			border-top: 0;
		}
		&:focus {
			outline: thin dotted;
			background-color: $color-brand-gray-light;
		}
		&:hover, .hover { 
			background-color: $color-brand-gray-light;
		}

		&.selected-option {
			display: none;
		}
	}

	&.show-options {
		z-index: 101;

		.display-item {
			&:after {
				content: $bt-var-angle-up;
			}
		}

		.drop-down-options {
			display: block;
		}
		.drop-down-options.locations{
		display:none;
		&.active{
		display:block;
		}
	}
	}
}
