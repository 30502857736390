@keyframes nojsfadein {
  0% {opacity: 0}
  100% {opacity: 1}
}

.homepage {
	height: 100vh;
  padding-left: 0;

  .header-logo{
    visibility: hidden;
    img {
      visibility: hidden;
    }
  }

  .site-header {
    background: none;
    display: none;
  }
  .site-nav-main-wrap {
    background: none;
    .site-nav-main {
      height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
    }
  }
  &__padding {
    padding-left: $site-header-nav-width;
  }

  .sidley-footer {
    margin-left: $site-header-nav-width;
  }

  &__hero {
    padding-left: calc(#{$site-header-nav-width} + 4rem);
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(90deg, rgba(33,33,33,.6), rgba(33,33,33,.3) 34%, transparent);
    }
    .feature-card,
    .hero__header {
      opacity: 0;
      z-index: 1;
    }
  }

  

  &__padding {
    padding-top: 3rem;
  }

  .hero--without-feature-card {
    & ~ .homepage__padding {
      padding-top: 0;
    }
  }

  &__content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__perpetual {
    position: fixed;
    z-index: 0;
    left: 50%;
    top: 10%;
    width: 100%;
    max-width: 680px;
    opacity: 1;
    transition: opacity 1s ease-in;
    &--hide {
      opacity: 0;
    }
  }
  .container {
    max-width: $width-max-home;
    margin: 0 auto;
    padding: 0 4rem;
  }
	.site-main {
		min-height: 100%;
  }

	.home-panel-item {
		position: relative;
		height: 100%;

		&:last-of-type {
      height: $homepage-last-panel-height;
			.site-main-content {
				display: flex;
				flex-direction: column;

				&::before {
					content: "";
					height: 100vh;
					background-color: $color-brand-white;
					width: 100%;
					bottom: 100%;
				    position: absolute;
				}
			}

			.footer-links {
				height: auto;
			}
		}
	}
	.home-header {
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    margin: 4rem 0;
    &::before,
    &::after {
      background-color: #BABABA;
      content: '';
      height: 1px;
      width: 100%;
    }
    &__content {
      padding: 0 1.5rem;
      font-size: 24px;
      line-height: 1.3em;
      color: $color-gray-med;
      font-style: italic;
      font-family: $font-family-titles;
      flex-shrink: 0;

      &:lang(ja){
        font-family: $font-family-titles-ja;
      }
    }
    &__text {
      font-size: 1em;
      &--primary {
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
        font-style: normal;
        color: $color-brand-black;
        font-size: 0.8em;
        letter-spacing: 0.6em;
      }
    }
  }

  @include bp-max($bp-desktop-large) {
    &__hero {
      padding-left: calc(#{$site-header-nav-width-desktop} + 2rem);
    }
    &__padding {
      padding-left: $site-header-nav-width-desktop;
    }
    
    .sidley-footer {
      margin-left: $site-header-nav-width-desktop;
    }
  }

  @include bp-max($bp-desktop) {
    &__padding {
      padding-top: 0;
    }
  }

  @include bp-max($bp-tablet) {
    .site-header {
      display: block;
    }
    &__hero {
      padding-left: calc(#{$site-header-nav-width-tablet} + 2rem);

      &:after{
        background: linear-gradient(90deg, rgba(66,66,66,.4), rgba(66,66,66,.2) 34%, transparent);        
        z-index: -1;
      }
    }
    &__padding {
      padding-left: $site-header-nav-width-tablet;
    }
    
    .sidley-footer {
      margin-left:  $site-header-nav-width-tablet;
    }
  }

  @include bp-max($bp-mobile-landscape){
    .home-panel-item {
      &:last-of-type {
				height: $homepage-last-panel-height-mobile;
      }
    }
  }


	@include bp-max($bp-mobile) {
  .header-logo img {
      visibility: visible;
    }
    &__content {
      position: relative;
    }
    &__perpetual {
      position: absolute;
      top: $site-header-height;
    }
    .container {
      padding: 0 2rem;
    }
		.articles-grid {
			box-shadow: 0 3px 3px 1px rgba($color-brand-dark-black, 0.25);
    }
    .site-header {
      background: $color-brand-gray;
    }
    .site-nav-main-wrap {
      .site-nav-main {
        height: auto;
      }
    }
    &__hero {
      padding-left: 2rem;
    }
    &__padding {
      padding-left: 0;
      padding-top: 3rem;
    }
    
    .sidley-footer {
      margin-left: 0;
    }
  }
  
  @include bp-max($bp-mobile-portrait) {
    &__hero {
      margin-bottom: 0;
    }
  }
}

$zindex-perpetual-graphic: 5;
$zindex-site-nav-bg: 3;
$zindex-hero-background: 4;
$zindex-main-content: 6;


// Midnight Styles for Menu and header
.homepage {
    &__perpetual {
        z-index: $zindex-perpetual-graphic;
    }

    .hero__background {
        z-index: $zindex-hero-background;

        &--override {
            z-index: 0;

            @include bp-max($bp-desktop) {
                z-index: -1;
            }
        }
    }

    &__padding,
    .hero {
        z-index: $zindex-main-content;
    }

    &__padding {
        position: relative;
    }

    .site-nav-main-wrap {
        .midnightInner {
            overflow: hidden !important;
        }

        .midnightHeader {
            &.transparent {
                background: none;

                .site-nav-link {
                    color: #fff;
                }
            }
        }
    }

    &__logo {
        z-index: $zindex-header - 1;
        position: fixed;
        left: 0;
        top: 0;
        width: $site-header-nav-width;
        z-index: $zindex-header;
        text-align: center;

        .midnightHeader {
            .midnightInner {
                overflow: hidden !important;
            }
        }

        @include bp-max($bp-desktop-large) {
            width: $site-header-nav-width-desktop;
        }

        @include bp-max($bp-tablet) {
            width: $site-header-nav-width-tablet;
        }

        @include bp-max($bp-mobile) {
            display: none;
        }
    }

        &__logo-holder {
            position: relative;
            width: 70%;
            padding-top: 50px + $site-header-bar-height;
            max-width: 170px;
            margin: 0 auto;
        }

        &__logo-midnight {
            width: 100%;
            display: block;
            width: 100%;
            padding-bottom: 22.7%;
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-image: url('/assets/release/images/logostacked.svg');

            .midnightHeader.default & {
                background-position: 0 0;
            }

            .midnightHeader.transparent & {
                background-position: 0 99%;
            }
        }

        .site-main {
            // Gray Background Navigation
            &:before {
                content: '';
                background-color: $bg-site-nav;
                display: block;
                width: $site-header-nav-width;
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                z-index: $zindex-site-nav-bg;

                @include bp-max($bp-desktop-large) {
                    min-width: $site-header-nav-width-tablet;
                    width: $site-header-nav-width-desktop;
                }

                @include bp-max($bp-tablet) {
                    width: $site-header-nav-width-tablet;
                }

                @include bp-max($bp-mobile) {
                    display: none;
                }
            }
        }
    }