.cta-links {
	$spacing: 50px;

	margin-top: px-to-rem($spacing);
	padding-left: 0;
}

.cta-links-item {
	text-align: center;
}

.cta-links-link,
.cta-links-nonlink {
	@extend %body-3;

	@include smooth-font;

	background-color: $color-brand-blue;
	display: block;
	color: $color-brand-white;
	background-color: $color-button-primary;
	padding: 1rem;
}

.cta-links-row {
	text-align: center;
}

.cta-links-link {
	text-align: center;

	&.is-emphasized {
		&:after {
			content: '↑';
			transform:  rotate(45deg);
			margin-left: px-to-rem(5px);
			display: inline-block;
		}
	}

	&:hover {
		background-color: $color-button-primary-hover;
		color: $color-brand-white;
  }

	&.single-cta {
		margin: $spacing-unit-section 0 $spacing-unit;
		padding-left: 2rem;
		padding-right: 2rem;
		.module-pattern & {
			&:first-child:last-child{
				margin-top: 0;
			}
		}
	}


	&.cta-narrow {
		width: auto;
		display: inline-block;
	}

	&.cta-links-link-image {
		padding: 0;
		display: block;
		position: relative;
		letter-spacing: 0;

		.link-image {
			display: block;
			min-width: 100%;
			margin: 0 auto;
		}
		.link-content {
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			width: 50%;
			text-align: left;
			text-transform: none;
			padding: px-to-rem(55px) px-to-rem(45px);
			padding-right: 0;

		}
		.link-label {
			color: $color-brand-white;
			margin-bottom: .5em;
			display: block;

		}
		.link-description {
			margin: .5em 0;
			display: block;
			font-style: italic;
			color: $color-brand-white;
			font-family: $font-family-primary;
			&:lang(ja){
				font-family: $font-family-primary-ja;
			}
		}

		@include bp-max($bp-mobile-landscape){
			.link-content {
				padding: px-to-rem(40px) px-to-rem(35px);
				padding-right: 0;
			}
		}

		@include bp-max($bp-mobile-portrait){
			.link-content {
				padding: px-to-rem(30px) px-to-rem(30px);
				padding-right: 0;
			}

			.link-label {
				font-size: 1.5rem;
			}
		}
	}
}

.our-story-landing-wrapper {
	.cta-links-link {
		text-align: center;
	}
}


.cta-links-arrow {
	font-family: $font-family-sans-serif;
	font-size: 16px;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 2px;
	&::after {
		content: '';
		display: inline-block;
		background-image: url(/assets/release/images/arrow-right.svg);
		width: 16px;
		height: 12px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		margin-left: 0.5rem;
		vertical-align: baseline;
	}
}