.article-share-tools:not(.dedicated-social-assets) {
	display: inline-block;
	color: $color-text-dark;
	transition: color .3s;
	z-index: 10;
	transform: translate3d(0,0,0);
	$spacing: .5rem;

	.header {
		font-size: px-to-rem(14px);
		text-transform: uppercase;
		font-family: $font-family-sans-serif;
		padding-bottom: $spacing;
		margin-bottom: $spacing;
		border-bottom: 1px solid $color-gray-gainsboro;
	}

	.share-tools {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.share-tool {
		text-align: center;
	}

	.share-link {
		color: $color-text-dark;
		transition: color .3s;

		&:hover {
			color: $color-text-link;
		}
		&:before {
			font-family: $font-family-font-awesome;
		}
	}

	&.fixed-scroll {
		color: rgba($color-text-dark, .5);

		.share-link {
			color: rgba($color-text-dark, .5);
		}

		&:hover {
			color: $color-text-dark;

			.share-link {
				color: $color-text-dark;

				&:hover {
					color: $color-text-link;
				}
			}
		}
	}

	.link-twitter {
		&:before {
			font-family: $ico-icon-name !important;
			content: "\e913";
			font-size: 16px;
		}
	}

	.link-linkedin {
		&:before {
			content: $fa-var-linkedin;
		}
	}

	.link-facebook {
		&:before {
			content: $fa-var-facebook;
		}
	}

	.link-email {
		&:before {
			content: $fa-var-envelope;
		}
	}
	&.desktop{
		@include bp-max($bp-mobile){
			visibility: hidden;
			height: 0;
			opacity: 0;
		}
	}
	&.mobile {
		display: none;
		position: relative;
		margin-top: 0;

		.header{
			margin:0 $spacing 0 0;
		}

		@include bp-max($bp-mobile){
			display: inline-flex;

			+ .article-content {
				margin-top: 0;
			}
		}
	}
	&.hold{
		display: inline-flex;
		position: relative;
		margin: $spacing-unit 0;
		.header{
			margin: 0 .5rem 0 0;
			padding-bottom:0;
			border-bottom:none;
		}
		.share-tools{
			position:absolute;
			top:50%;
			left: 100%;
			transform: translate3d(0, -50%, 0);
			display:flex;
			flex-direction: row;
			align-items: center;

			li{
				flex-grow: 1;
				margin: 0;

				&:not(:last-child){
					margin-right: 1rem;
				}
			}
		}

	&.float-left{
		// float:left;
		margin-bottom: 0;

		@media(min-width: 950px) {
			position: absolute;
			margin-top: 0.5em;
			// margin-bottom: 0;
		}
	}

	&.gallery{
		padding-left:6rem;
	}
	}

}

//Changes for resources page
.main-wrapper--resources .article-share-tools:not(.dedicated-social-assets) {

	&.hold{
		margin: 0;

		.header {
			display: none;
		}

		.share-tools{
			position: unset;
			top:0;
			left: 0;
			transform: unset;

			.share-link{
				transition: color .3s;
				display: inline-block;

				&:hover{
					color: #005e85;
				}
			}
		}
	
		@include bp-max($bp-mobile-landscape) {
			
			.share-tools{
				.share-link{
					font-size: unset;
				}
			}

		}

		@include bp-max(400px) {
			margin-top: .5rem;
		}

	}

} 

.scrollmagic-pin-spacer {
	margin-top: 0 !important;
}


@include bp-max($bp-mobile) {
	$spacing: .5rem;

	// Overriding scroll magic styles in mobile

	.scrollmagic-pin-spacer {
		padding: 0 !important;
		position: static !important;
		height: auto !important;
		margin-bottom: 1rem !important;
		margin-top: 0 !important;
	}

	.article-share-tools:not(.dedicated-social-assets) {
		top: 0 !important;
		left: 0 !important;
		position: static !important;
		display: inline-block;
		margin: $spacing-unit 0;


		.header{
			display: inline-block;
			border-bottom:none;
			padding: 0 $spacing 0 0;
			margin:$spacing $spacing $spacing 0;
		}
		.share-tools{
			position: absolute;
			top: 50%;
			left: 100%;
			transform: translate3d(0, -50%, 0);
			display: flex;
			flex-direction: row;
			align-items: center;

			li{
				flex-grow: 1;
				margin: 0;

				&:not(:last-child){
					margin-right: 1rem;
				}
			}
		}
	}
}

