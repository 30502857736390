.site-nav-main {

  @include vertical-align;

  position: absolute;
  font-family: $font-family-sans-serif;
  width: 100%;

  @include bp-max($bp-mobile) {
    position: static;
    top: auto;

    padding-bottom: 1rem;
    border-bottom: 1px solid $color-gray-med;
    transform: translate(0,0);
  }


  @media (min-width: $bp-mobile) and (max-height: 550px){
    top: 60%;
  }
}

$site-divider-spacing: 15%;

.site-nav-link {
  @extend %uc;
  $link-active: $color-gray-night-rider;
  $link-active-mobile: $color-brand-yellow;

  display: block;
  position: relative;
  color: #606060;
  font-size: .75rem;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.1rem;
  transition: all .5s $easing;

  &.is-active {
    color: $link-active;
    font-family: $font-family-sans-serif-bold;
  }

  &:hover {
    color: $link-active;
    font-family: $font-family-sans-serif-bold;
  }

  @include bp-max($bp-tablet) {
    font-size: .84rem;
  }

  @include bp-max($bp-mobile) {
    font-size: 1.1rem;

    color: $color-brand-white;
    padding-top: 1rem;

    border-top: 1px solid $color-gray-med;
    -webkit-font-smoothing: antialiased;

    &.is-active,
    &:hover {
      color: $link-active-mobile;
    }
  }

  & + & {
    margin-top: $site-divider-spacing;
    padding: $site-divider-spacing 10% 0;

    @include bp-max($bp-mobile) {
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 1px solid $color-gray-med;

    }

    &:before {
      @include horizontal-align;

      content: '';
      border-top: 1px solid $border-color-divider;
      display: inline-block;
      width: 35px;
      position: absolute;
      margin-top: -$site-divider-spacing;

      @include bp-max($bp-mobile) {
        display: none;
      }
    }
  }

  @media (min-width: $bp-mobile) and (max-height: 650px){
    & + & {
      $spacing: 10%;

      margin-top: $spacing;
      padding-top: $spacing;

      &:before {
        margin-top: $spacing * -1;
      }
    }
  }

  @media (min-width: $bp-mobile) and (max-height: 550px){
    & + & {
      $spacing: 1rem;

      margin-top: $spacing;
      padding-top: $spacing;

      &:before {
        margin-top: $spacing * -1;
      }
    }
  }

  @media (min-width: $bp-mobile) and (max-height: 400px){
    & + & {
      $spacing: .5rem;

      margin-top: $spacing;
      padding-top: $spacing;

      &:before {
        margin-top: $spacing * -1;
      }
    }
  }
}
