.staying-informed {
  margin: 5rem 0;
  &__tiles {
    display: flex;
    justify-content: space-between;
  }
  .article-tag {
    font-size: 14px;
    margin-bottom: 3rem;
  }
  &__tile {
    position: relative;
    margin-bottom: 4rem;
    min-width: 50%;
    max-width: 50%;

    &--announcements {
      .article-tag {
        color: $color-brand-dark-black;
      }
      .staying-informed__content {
        background: $color-gold;
        box-shadow: none;
      }
    }
    &--publications {
      order: 1;

      .article-tag,
      .staying-informed__title,
      .staying-informed__supporting-content {
        color: $color-brand-white;
      }
      .staying-informed__supporting-content {
        color: $color-brand-white;
      }
      .staying-informed__content {
        background: $color-navy;
        box-shadow: none;
      }

      &-black {
        .staying-informed__content {
          background: $color-brand-dark-black;
          .article-tag,
          .staying-informed__title {
            color: $color-brand-white;
          }
          .staying-informed__supporting-content {
            color: $color-brand-white;
          }
        }
      }

      &-white {
        .staying-informed__content {
          background: $color-brand-white;
          box-shadow: -1px 5px 10px rgba(0, 0, 0, 0.15);

          .article-tag {
            color: $color-brand-red;
          }
          .staying-informed__title {
            color: $color-brand-dark-black;
          }
          .staying-informed__supporting-content {
            color: $color-brand-dark-black;
          }
        }
      }

      &-gold {
        .staying-informed__content {
          background: $color-gold;
          color: $color-brand-dark-black;

          .article-tag,
          .staying-informed__title {
            color: $color-brand-dark-black;
          }
          .staying-informed__supporting-content {
            color: $color-brand-dark-black;
          }
        }
      }
    }
    &--resources,
    &--story {
      flex: 1 1 auto;
      .staying-informed__content {
        margin-right: 4rem;
      }
    }
    &--resources {
      margin-right: 7rem;
    }
    &--story {
      margin-left: 0rem;
      order: 2;
    }
  }
  &__title {
    font-size: 24px;
    margin-bottom: 0;
  }
  &__background {
    position: absolute;
    top: 0;
    left: 2.5rem;
    right: 0;
    bottom: 2rem;
    background-size: cover;
    background-position: center;
  }
  &__content {
    position: relative;
    padding: 2.5rem;
    margin-top: 2rem;
    background: $color-brand-white;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    min-height: 400px;
    max-width: 400px;
    box-shadow: -1px 5px 10px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.35s ease-out,
      transform 0.25s ease-out;

    &:hover {
      box-shadow: -5px 10px 20px 2px rgba(0, 0, 0, 0.15);
      transform: translate(1px, -3px);
    }
  }

  &__supporting-content {
    color: $color-brand-dark-gray;
  }

  .scEmptyPlaceholder {
    width: 100%;
    z-index: 1;
  }

  @include bp-max($bp-desktop-large) {
    &__tile--resources {
      margin-right: 2rem;
    }
    &__tile--story {
      margin-left: 2rem;
    }
  }
  @include bp-max($bp-desktop) {
    &__tiles {
      display: block;
    }
    &__tile {
      margin: 0 0 2rem 0;
      min-width: auto;
      max-width: none;

      &--story,
      &--publications {
        order: auto;
      }
      &--story,
      &--resources {
        padding-top: 5rem;
        .staying-informed__content {
          margin: 0 0 0 2rem;
        }
      }
    }
    &__content {
      max-width: none;
      min-height: 300px;
    }
    &__background {
      left: 0;
      bottom: 5rem;
    }
  }
}