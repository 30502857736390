.people-hero-header {
    $section-spacing: px-to-rem(15px);

    padding-bottom: $section-spacing;
    border-bottom: 1px solid $border-color-divider;
    margin-bottom: $section-spacing;
    text-align: center;

    .page-title {
        margin-bottom: $spacing-unit-small;

        @include bp-max($bp-people-desktop) {
            font-size: px-to-rem(30px);
        }

        @include bp-max($bp-people-small) {
            font-size: px-to-rem(30px);
        }
    }

    .level {
        @extend %body-3;
        margin-bottom: $spacing-unit-small;
        color: $color-text-gray;
    }

    @include bp-max($bp-people-desktop) {
        padding-bottom: 0;
        border-bottom: 0 none;
    }

    &__has-image {

        @include bp-max($bp-people-small) {
            margin-left: 40%;
            text-align: left;
        }

        & .people-hero-header__page-tools--mobile {
            & .page-tools {
                text-align: left;
            }
        }
    }

    .people-hero-header__page-tools--mobile {

        @include bp-min($bp-people-small) {
            display: none;
        }

        display: block;
    }

    .people-hero-practices {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        text-align: center;
        color: $color-text-gray;

        @include bp-max($bp-people-small) {
            text-align: left;
            display: none;
        }


        .practice-item {
            @extend %body-2;
            margin: px-to-rem(6px) 0 0;
        }
    }
}
