//Solving the Whitespace issuewith inlineblock positioning
//http://www.vanseodesign.com/css/inline-blocks/
.grid-row-inline-block  {
	overflow: hidden;
	font-size: 0;
	line-height: 0;

	 > [class*="grid-col"]  {
		font-size: 1rem;
		line-height: 1.5;
		display: inline-block;
		vertical-align: top;
	}
}

.grid-row {
	@extend %clearfix;
}

.grid-row-reversed {

	> [class*="grid-col"] {
		float: right;
	}
}

[class*="grid-col"] {
	float: left;



	// @include respond-max($mobile-max) {
	// 	float: none;
	// }
}

.grid-col-33{
	width: 33.33333%;


	.grid-row-spaced &{
		width: 32.33333%;
		margin-right: 1%;
	}

	// @include respond-max($bp3-max) {
	// 	width: 50%;
	// }
  //
	// @include respond-max($mobile-max) {
	// 	width: 100%;
	// }
}

.grid-col-50{
	width: 50%;

	.grid-row-spaced > &{
		width: 48%;

		&:nth-child(2n+1) {
			margin-right: 4%;
		}

		// @include respond-max($mobile-max) {
		// 	width: 100%;
    //
		// 	&:nth-child(2n+1) {
		// 		margin-right: 0;
		// 	}
		// }
	}
}



//specify breakpoint inside components, breakpoints will differ based on layout
.grid-col-20 {
	width: 20%;
}

.grid-col-25 {
	width: 25%;
}

//specify breakpoint inside components, breakpoints will differ based on layout
.grid-col-40 {
	width: 40%;
}

//specify breakpoint inside components, breakpoints will differ based on layout
.grid-col-35 {
	width: 35%;
}

//specify breakpoint inside components, breakpoints will differ based on layout
.grid-col-60 {
	width: 60%;
}


.grid-col-sidebar {
	width: 28%;
	line-height: 1.4;

	@include bp-max($bp-tablet) {
		width: 30%;
	}
}

.grid-col-sidebar-right {
	float: right;
	width: 18%;
}

.grid-col-content {
	width: 66%;
	float: right;

	.grid-row-three-col &{
		width: 48%;
		margin-right: 3%;

		@include bp-max($bp-tablet) {
			width: 46%;
		}
	}
}
