.article-summary {
	background-color: $color-gray-white-smoke;
	padding: 2.5rem;
	border-bottom: 1px solid $color-gray-gainsboro;

	.summary-label {
		@extend .h5-size;

		color: $color-brand-dark-black;
		font-family: $font-family-sans-serif-bold;
	}

	.rich-text {
		@extend .h4-size;

		margin: 1.5rem 0 0;
	}

	.event-details{
		> * {
			&:first-child {
				margin-top: 0;
			}
		}

		.city{
			display:flex;
		}
	}
}