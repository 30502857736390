//****************************************************************************
//layout includes base-level elements and master page layout styles, base styles
//****************************************************************************
* {
	box-sizing: border-box;

	&:before,
	&:after{
		box-sizing: border-box;
	}
}

body {
  	color: $color-text-base;
	overflow-x: hidden;   
}

a {
	text-decoration: none;
	transition: $base-animation;
	color: $color-text-link;
	word-break: break-word;

	&:hover {
		color: $color-green-dark-green;
	}
}

input[type=text]:focus {
	outline: thin dotted !important; 
}

ul {
	margin:0 0 $spacing-unit;
	padding-left: 0;
	list-style: none;
}

p {
	margin: 0 0 $spacing-unit;
}

li {
	margin-bottom: $spacing-unit/2;
}

img {
	max-width: 100%;
}

address {
	font-style: normal;
	margin-bottom: $spacing-unit;
}

hr {
    border: 0;
    border-bottom: 1px solid $color-gray-white-dust;
}
