.page-people-landing {
	$spacing: 3rem;

	margin-bottom: $spacing;

	.people-landing-search {
		margin: 1rem auto 0;
		margin-top: 3rem;
	}

	.articles-grid-list {
		margin-top: .5rem;
	}
}
