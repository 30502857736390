
.tile-grid {
  $grid-spacing: px-to-rem(8px);
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
  margin: 0 $grid-spacing * -1;

  &__item {
    margin: 8px $grid-spacing/2 0;
    width: calc(33.33334% - #{$grid-spacing});
    display: flex;
    flex-direction: column;
    min-height: px-to-rem(324px);
  }

  @include bp-max($bp-desktop-large) {
    &__item {
      width: calc(50% - #{$grid-spacing});
    }
  }
  @include bp-max($bp-tablet) {
    margin-left: 0;
    margin-right: 0;
    &__item {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
