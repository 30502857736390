.hero {
  min-height: 90vh;
  padding: 4rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  position: relative;

  &__scroll {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    &-text {
      font-family: $font-family-sans-serif;
      color: $color-brand-white;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 3px;
      transform: rotate(-90deg);
      margin-bottom: 2rem;
    }
    &-line {
      height: 150px;
      width: 1px;
      background: $color-brand-white;
    }
    @include bp-max($bp-desktop-large) {
      display: none;
    }
  }
  &__image {
    background-image: url('/assets/release/images/homepage/bridge.jpg');
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // bottom: 0;
    top: 0;
    height: 150%;
    background-size: cover;
  }
  &__background {
    overflow: hidden;
    position: absolute;
    // z-index: -1;
    // bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    min-height: 90vh;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: $site-header-nav-width;
      background: rgba(0,0,0,0.2);
      box-shadow: 0 0 100vw 15vw rgba(0,0,0,.2);
      @include bp-max($bp-desktop-large) {
        width: $site-header-nav-width-desktop;
      }
      @include bp-max($bp-tablet) {
        width: $site-header-nav-width-tablet;
      }
  
      @include bp-max($bp-mobile) {
        display: none;
      }
    }

    @include bp-max-landscape($bp-tablet) {
      min-height: 125vh;
    }
  }
  
  &__header {
    // https://css-tricks.com/snippets/css/fluid-typography/
    // font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
    
    font-size: calc(100px + (125 - 100) * ((100vw - 1260px) / (2000 - 1260)));
    color: $color-brand-white;
    font-family: $font-family-titles;
    line-height: 1em;
    margin: 0;
    max-width: 600px;
    &:lang(ja){
      font-family: $font-family-titles-ja;
    }

    strong {
      font-weight: normal;
      display: block;
      white-space: nowrap;
    }

    span {
      position: relative;
      display:inline-block;
    }
  }

  $containerHeight: 55vh; //Approximate Centere Alignment
  
  &__content {
    z-index: 1;
    height: $containerHeight;
  }
  .feature-card {
    padding: 2rem;
    color: $color-brand-white;
    background: $color-brand-black;
    max-width: 400px;
    width: 400px;
    min-height: $containerHeight;
    box-shadow: 0px 10px 30px rgba(0,0,0,.25);
    display: block;
    position: relative;
    transform: translateY(25%);
    transition: box-shadow 0.35s ease-out,
      transform 0.25s ease-out;

    &:hover {
      box-shadow: 0px 10px 20px 5px rgba(0,0,0,.25);
      transform: translateY(20%);
    }

    &__subheader {
      font-family: $font-family-sans-serif;
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.29em;
      margin-bottom: 1.5rem;
      display: inline-block;
    }
    &__date {
      font-style: italic;
    }
    &__subheader,
    &__date {
      color: $color-gray-med-alt;
    }
    &__header {
      color: inherit;
      font-size: 24px;
      line-height: 1.3em;
      margin-bottom: 1.5rem;
    }
  }

  .scEmptyPlaceholder {
    align-self: flex-end;
    width: 100%;
  }

  &--without-feature-card {
    min-height: 82vh;
  }

  &--without-feature-card {
    & + .hero__background {
      min-height: 82vh;
    }
  }

  

  @include bp-max($bp-desktop-large) {
    padding: 2rem;
    &__header {
      font-size: 75px;
      max-width: 400px;
    }
  }

  @include bp-max($bp-desktop) {
    display: block;
    padding-top: 200px;
    // margin-bottom: calc(200px + 2rem);

    .feature-card {
      margin: 0;
      min-height: 460px;
      transform: none;

      &:hover {
        transform: translateY(-5px)
      }
    }

    &__content {
      height: auto;
      margin-bottom: 2rem;
    }

    &__header {
      margin-bottom: 50px;
    }
  }


  @include bp-max($bp-mobile-portrait) {
    margin-bottom: calc(100px + 2rem);
    padding-top: 150px;

    &__header {
      font-size: 17.5vw;
      max-width: 300px;
    }
    $cardHeight: 460px;
    .feature-card {
      margin-bottom: -100px;
      min-height: 150px;
      width: auto;

      &__header {
        font-size: 26px;
      }
      &__subheader {
        font-size: 14px;
        margin-bottom: 1rem;
      }
    }

    &--without-feature-card {
      margin-bottom: 0;
      min-height: 75vh;
    }

    &--without-feature-card {
      & + .hero__background {
        min-height: 75vh;
      }
    }
  }

}
