.article-component {

  &__list {
    padding: px-to-rem(10px);
  }

  &__list-name {
    font-size: px-to-rem(18px);
    margin-bottom: px-to-rem(30px);

    .article-component & {
      @extend %body-5;
      color: $color-brand-red;
    }
  }

  &__article {
    border-bottom: 1px solid $color-gray-white-dust;
    padding: px-to-rem(30px) 0;

    &:first-of-type {
      border-top: 1px solid $color-gray-white-dust;
    }
  }

  .main-wrapper--resources & {
    &__item,
    &__article {
      @include links__black();
    }
  }

  @include bp-min($bp-desktop) {
    display: flex;

    &__featured {
      width: 50%;
    }

    &__list {
      padding: px-to-rem(40px);
      width: 50%;
    }
  }
}