.locations-list-nearest-wrapper {
    @include bp-min(1135px) {
        justify-content: flex-start;
    }
    cursor: pointer;
    display: flex;
    justify-content: center;
    *:hover {
        ~ .nearest-img {
            transform: scale(1.05);
            &::after {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.location-box-container {
    margin-top: px-to-rem(44px);
}

.locations-list-item {
    margin-bottom: 0;
}

.locations-list-nearest {
    
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    li {
        @include bp-min(375px) {
            height: px-to-rem(165px);
            width: 50%;
        }
        align-items: center;
        display: flex;
        width: 100%;
        flex-shrink: 0;
        height: px-to-rem(140px);
        margin-bottom: 1px;
        border-right: 1px solid $color-brand-white;
        overflow: hidden;
        position: relative;
    }
}

.locations-list-offices,
.location-box-container {
    display: block;

    @include bp-min($bp-mobile-landscape) {
        columns: auto 2;
        column-gap: $spacing-unit/2;

        > li {
            margin-bottom: 0;
            margin-right: 3%;
            padding-bottom: $spacing-unit/2;
            width: 100%;

            break-inside: avoid;
        }
    }

}

.locations-list-reach {
    & > * {
        margin-left: auto;
        margin-right: auto;
        ol {
            display: block;
        }
    }
    h3 {
        font-family: $font-family-sans-serif-bold;
        font-size: px-to-rem(24px);
        text-align: center;
    }
}

.locations-section {

    margin-bottom: $spacing-unit-section;

    ol {
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
    }
}

.locations-section-nearest {
    .section-header {
        margin-bottom: 1.9rem;
    }
}

.locations-section-offices,
.locations-section-reach {
    .section-header {
        margin-bottom: 2rem;
    }
}

.locations-sections-offices .locations-section-label-text {
    margin-bottom: px-to-rem(54px);
}

.locations-sections-reach .locations-section-label-text {
    margin-bottom: px-to-rem(44px);
}

.nearest-details {
    color: $color-brand-white;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 50%;
    z-index: 20;
    a {
        color: $color-brand-white;
        font-family: $font-family-sans-serif;
    }
}

.nearest-details-map {
    a {
        align-items: center;
        background-color: rgba($color-brand-dark-black, 0.33);
        border: 1px solid $color-brand-white;
        display: flex;
        height: px-to-rem(38px);
        justify-content: center;
        width: px-to-rem(103px);
        &:hover {
            background-color: rgba($color-brand-dark-black, 0.75);
        }
        &::after {
            content: "↑";
            display: inline-block;
            margin-left: 0.777777784rem;
            transform: rotate(45deg);
        }
    }
}

.nearest-details-label {
    font-size: px-to-rem(15px);
    margin-bottom: 0;
    margin-top: 0;
}

.nearest-details-map {
    margin-bottom: 0;
    * {
        font-size: px-to-rem(15px);
    }
}

.nearest-details-tel {
    font-size: px-to-rem(14px);
    margin-bottom: 0;
    margin-top: px-to-rem(14px);
}

.nearest-img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 1s ;
    width: 100%;
    &::after {
        background-color: rgba(0, 0, 0, 0.4);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: background-color 1s ;
        width: 100%;
    }
    img {
        min-height: 100%;
        min-width: 100%;
        max-width: none;
    }
}

.nearest-img-overlay {
    height: 100%;
    left: 0;
    position: absolute;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
}
