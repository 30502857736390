.page-location-detail {
	.rich-text-section {
        margin-top: px-to-rem(50px);

		&.rich-text-section__slim-margin {
			margin-top: 0;
		}
	}
	
	.contacts-module {
		.contact-cards { 
			display: flex;
			flex-wrap: wrap;
		}

		.contact-card {
			width: 50%;

			@include bp-max(1000px){
				width: 100%;
			}
		}

	}
 
	.tab-menu {
		margin-bottom: 0;
		border-bottom: none;
		&.office-detail{
			@include bp_max($bp-desktop){
				margin-bottom: 2.1rem;
			}
		}
	}
	@include bp-min($bp-mobile-landscape) {
		.related-insights {
			.news { 
				border-bottom: 1px solid $color-gray-white-dust;
			}
		}
	}
	.articles-grid-list {
		margin-top: 0;
	}

	.rich-text {
		.section-header {
			text-align: left;

			&:before {
				content: none;
			}
		}

		.section-header-title {
			margin: 1rem 0;
			font-size: 1rem;
			font-family: $font-family-sans-serif-bold;
			color: $color-brand-dark-black;
			padding: 0;
			max-width: none;
		}
	}

	.btn-view-gallery {
		@extend %body-3;

		background-color: $color-brand-blue;
		display: block;
		color: $color-brand-white;
		background-color: $color-button-primary;
		padding: 1rem;
		width: 100%;

		&:hover {
			background-color: $color-button-primary-hover;
			color: $color-brand-white;
	  	}
	}
}
