.insight-type-filters {
	list-style-type: none;
	padding: px-to-rem(30px) 10%;
	text-align: center;
	border-top: 1px solid $color-gray-gainsboro;
	border-bottom: 1px solid $color-gray-gainsboro;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 2.5rem;
	display: flex;
	justify-content: space-around;
	align-items: center;

	.insight-type {
		@extend %body-5;

		display: inline-block;
		color: $color-green-blue-lagoon;
		margin-right: 10%;
		cursor: pointer;
		margin-bottom: 0;

		&:last-of-type {
			margin-right: 0;
		}

		&:hover {
			color: $color-text-base;
		}
	}

	.active-type {
		color: $color-brand-red;
		font-family: $font-family-sans-serif-bold;
	}

	@include bp-max($bp-desktop){
		padding-left: 0;
		padding-right: 0;

		.insight-type {
			letter-spacing: 2px;
		}
	}

	@include bp-max(500px) {
		flex-wrap: wrap;
		justify-content: center;

		.insight-type {
			letter-spacing: 0;
			margin: .75rem 1rem;
		}
	}

}
