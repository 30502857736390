.insights-list {
  margin: $spacing-unit 0 0;
  padding-left: 0;
}

.insights-list-item {
  & + & {
    margin-top: $spacing-unit;
    padding-top: $spacing-unit;
    border-top: 1px dotted $border-color-divider;
  }
}

.insights-list-load {
  margin-top: $spacing-unit;
  text-align: center;
}