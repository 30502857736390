.site-location-bar {
    display: none;
    -webkit-transform: translateZ(0);

    .language-updated-notification {
        pointer-events: none;
        opacity: 0;
        background-color: transparent;
        position: absolute;
        color: $color-text-headings;
        top: 0;
        left: 0;
        z-index: 10;
        font-size: .6rem;
        width: auto;
        padding: 0 15px;
        line-height: 2.222rem;
        font-weight: bold;
        font-family: $font-family-sans-serif;
        letter-spacing: 1px;
        transition: opacity, background-color .3s ease-out;
        white-space: nowrap;

        .bts {
            margin-right: 10px;
            vertical-align: middle;

            &:before {
                font-size: 14px;
            }
        }

        &__text {
            vertical-align: middle;
        }
    }
    .site-location-divider {
        color:  $color-text-gray-alt;
    }
    &__btn-wrapper {
        display: inline-block;
        position: relative;

        &--language-change {
            .language-updated-notification {
                opacity: 1;
                background-color: $color-brand-white;
            }
        }
    }

    .site-nav-bar & {
        display: block;
    }

    @include bp-max($bp-mobile) {
        background-color: $bg-nav-bar;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: $zindex-location-bar;
        transition: transform $animation-mobile-timing $easeInOutQuint;
        display: block;

        .language-updated-notification{
            line-height:2.5rem;
        }
    }

    .site-nav & {
        @include bp-max($bp-mobile) {
            display: none;
        }
    }

    &.headroom--unpinned {
        transform: translate3d(0, $site-header-and-location-height * -1, 0);
    }

    @include bp-max($bp-mobile) {
        display: table;
    }
}

.site-location-btn {
    color: $color-brand-white;
    display: inline-block;
    font-size: .6rem;
    line-height: 2.1rem;
    padding: 0;
    font-family: $font-family-sans-serif;
    text-transform: none;

    &--upper {
        text-transform: uppercase;
    }
    .bt-global {
        color: $color-text-gray-alt;
    }
    &--dropdown {
        // font-size: 0.65rem;
        strong {
            @extend %uc;
            // color: $color-brand-white;
        }
        // .is-active-globalization-menu & {
        //   strong {
        //     color: $color-text-dark;
        //   }
        // }
    }

    &.active {
        position: relative;
        opacity: 1;
        color: $color-text-dark;
        background-color: $color-brand-white;
        position: relative;
        border-color: $color-brand-white;
        border-bottom: 1px solid $color-brand-white;

        @include bp-max($bp-main-nav) {
            padding: 0 .8rem;
            font-size: .45rem;
        }

        @include bp-min($bp-tablet) {
            border-bottom-width: 2px;
        }
    }

    @include bp-max($bp-tablet) {
        line-height: 2.43rem;
        font-size: .75rem;
    }

    @include bp-max($bp-site-location-adjust) {
        font-size: .6rem;
    }

    @include bp-max($bp-mobile) {
        line-height: 1.1rem;
        display: table-cell;
        height: $site-location-bar-height;
        padding: 0 .2rem;
        vertical-align: middle;
        font-size: .75rem;
    }

    &:hover {
        color: $color-brand-white;
    }

    .bts {
        margin-right: .3rem;
    }
}

.site-lang-toggle {
    color: $color-text-light;
    display: inline-block;
    font-size: .6rem;
    line-height: 2.1rem;
    padding: 0 .7em;
    font-family: $font-family-sans-serif;
    text-transform: none;

    &.lang-cn {
        font-weight: 800;
    }

    &.active {
        position: relative;
        opacity: 1;
        position: relative;
        border-color: $color-brand-white;
        background-color: rgba(0,0,0,0);
        color: $color-brand-white;
        border-bottom: none;


        @include bp-max($bp-main-nav) {
            //     padding: 0 .8rem;
            //     font-size:.45rem;
        }

        @include bp-min($bp-tablet) {
            border-bottom-width: 2px;
        }
    }

    @include bp-max($bp-tablet) {
        padding: 0;
        width: 40px;
        line-height: 2.43rem;

        &.lang-cn {
            width: 45px;
        }
        // font-size: .75rem;
    }

    @include bp-max($bp-mobile) {
        line-height: 1.1rem;
        display: table-cell;
        height: $site-location-bar-height;
        //  padding: 0 .2rem;
        vertical-align: middle;
    }

    &:hover {
        color: $color-brand-white;
    }

    .bts {
        margin-right: .3rem;
    }
}

.site-location-disclaimer {
    color: $color-brand-white;
    font-size: .6rem;
    display: inline-block;
    font-family: $font-family-sans-serif;
    padding-left: .7em;

    &:before {
        content: '|';
        padding-right: 1.5em;
        display: inline-block;
        padding-left: 0;

        @include bp-max($bp-mobile) {
            display: none;
            padding: 0;
        }
    }

    @include bp-max($bp-mobile) {
        display: none;
    }

    ;
}

// .site-location-disclaimer-footer{
//     color:  $color-brand-white;
//     font-size: .6rem;
//     display: inline-block;
//     font-family: $font-family-sans-serif;
//     padding-left: .7em;
//     display: none;
//
//     @include bp-max($bp-mobile){
//       display: block;
//     };
// }

.js-site-country-trigger {
    padding: 0 .7em;
    margin-left: -0.1em;


    .is-active-globalization-menu & {
        background-color: $color-brand-white;
        color: $bg-nav-bar;

        &:after {
            transform: rotate(180deg);
        }
    }

    @include bt-icon-after($bt-var-caret-down) {
        font-size: .5rem;
        margin-left: .3rem;
        font-weight: 900;
        color:  $color-text-gray-alt;
    }
}


.js-site-location-trigger {
    background-color: $color-gray-darker-gray;
    text-transform: uppercase;
    padding: 0 1rem;
    letter-spacing: .1rem;

    @include bp-max($bp-site-location-adjust) {
        padding: 0 .75rem;
    }

    @include bp-max($bp-mobile) {
        display: table-cell;
        width: 135px;
        max-width: 50%;
        padding: 0 1rem;
    }
}
