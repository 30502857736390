$bp-jump-nav-mobile: 500px;


.jump-nav {
  position: relative;

  z-index: 10;
}

 .jump-nav--general {
  margin-top: $spacing-unit-section;


 }


.jump-nav-btn {
  @extend %body-3;

  width: 100%;
  border-bottom: 1px solid $border-color-input;
  padding: 1rem;
  text-align: left;
  display: none;
  z-index: 8;
  position: relative;

  @include bp-max($bp-jump-nav-mobile) {
    display: block;
  }

  @include bt-icon-after($bt-var-angle-down) {
    position: absolute;
    right: 1rem;
    transition: transform $base-animation-timing $easing;
  }

  &:hover {
    background-color: $color-brand-gray-light;
  }

  &.is-expanded {


    &:after {
      transform: rotateX(180deg);
    }
  }
}


.jump-nav-list {

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-bottom:$spacing-unit-small;
  border-bottom:  1px solid $border-color-input;
  margin: $spacing-unit 0 ;
  padding-left: 0;


  .jump-nav--general & {
    padding-top:$spacing-unit-small * 2;
    border-top:  1px solid $border-color-input;

   @include bp-max($bp-jump-nav-mobile) {
    padding-top: 0;
    border-top: 0 none;
   }

  }

  @include bp-max($bp-jump-nav-mobile) {
    display: block;
    padding: 0;
    position: absolute;
    z-index: 5;
    width: 100%;
    margin: 0;
    background-color: $color-brand-white;
    opacity: 0;
    top: 20%;
    visibility: hidden;
    transition: top $base-animation-timing ease-out,
                opacity $base-animation-timing ease-out,
                visibility 100ms $easing $base-animation-timing;

    .is-expanded + & {
      top: 100%;
      visibility: visible;
      opacity: 1;
      transition: top 1s $easing,
                  opacity 1s $easing;
    }
  }
}

.jump-nav-item {
  flex-grow: 1;
  text-align: center;
  border-right: 1px solid $border-color-input;

  @include bp-max($bp-jump-nav-mobile) {
    margin-bottom: 0;
    text-align: left;
    border: 0 none;
  }

  &:last-child {
    border-right: 0;
  }
}

.jump-nav-link {
  @extend %body-5;

  color: $color-text-dark;
  display: inline-block;
  padding: px-to-rem(6px) px-to-rem(15px);
  display: inline-block;
  position: relative;

.jump-nav-link-text--phantom {
	visibility: hidden;
    font-family: $font-family-sans-serif-bold;
}

.jump-nav-link-container {
	position: relative;
}

.jump-nav-link-text {
	top: 0%;
	left: 0%;
	position: absolute;
}

  &:hover {
    font-family: $font-family-sans-serif-bold;

    &:before {
      opacity: 1;
      top: 95%;

      @include bp-max($bp-jump-nav-mobile) {
        top: 50%;
      }
    }
  }

  @include bp-max($bp-jump-nav-mobile) {
    padding: 1rem;
    border:  1px solid $border-color-input;
    border-width: 0 1px 1px;
    display: block;

    &:hover {
      background-color: $color-brand-gray-light;
    }
  }

  @include bt-icon-before( $bt-var-caret-down) {
    @include horizontal-align;

    font-weight: 900;
    display: block;
    position: absolute;
    top: 85%;
    opacity: 0;
    transition: top .4s ease-out,
                opacity .25s ease-out;

    @include bp-max($bp-jump-nav-mobile) {
      right: 1rem;
      top: 50%;
      left: auto;
      transform: translate(0, -50%);
    }
  }
}

.jump-nav--services-sectors-detail {
	.jump-nav-list {
		border-top: 1px solid $border-color-input;
		padding-bottom: 1.35rem;
		padding-top: 1.35rem;

		@include bp-max($bp-jump-nav-mobile) {
			border-top-width: 0;
			padding-bottom: 0;
			padding-top: 0;
		}
	}
	.jump-nav-item {
		margin-bottom: 0;
	}
}
