.cookie-popup {
	position: fixed;
	bottom: 0;
	right: 0;
	width: calc(100% - #{$site-header-nav-width});
	z-index: $zindex-cookie-popup;
	background-color: rgba($color-brand-blue, .95);
	padding: px-to-rem(36px) px-to-rem(52px);
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: transform .2s ease-in-out, opacity 0s .2s, visibility .01s linear .2s;
	transform: translate3d(0,100%,0);
	opacity: 0;
	visibility: hidden;

	.cookie-message-header {
		color: $color-brand-white;
		font-family: $font-family-sans-serif;
		font-size: 1rem;
		letter-spacing: 2px;
    	text-transform: uppercase;
		padding-bottom: .5rem;
		font-weight: bold;
	}

	.cookie-message {
		color: $color-gray-soft;
		font-size: px-to-rem(18px);
		line-height: 1.5;
		max-width: 60%;
		padding-bottom: 1rem;

		&--long {
			border-bottom: none;
		}

		a {
			color: $color-brand-white;
			position: relative;
			display: inline-block;
			text-decoration: underline;
		}

		p {
			margin-bottom: 0;

			+ p {
				margin-top: .5rem;
			}
		}
	}

	.popup-buttons {
		margin-left: 1rem;
		display: flex;
	}

	.btn-cookie-popup {
		background-color: $color-brand-white;
		text-transform: uppercase;
		font-family: $font-family-sans-serif;
		padding: .75em;
		letter-spacing: 2px;
		color: $color-brand-blue;
		flex-basis: 0px;
		min-width: 10em;
		border: 1px solid $color-brand-white;
		margin: 0;
		.no-touch &:hover {
			background-color: $color-gray-med-alt;
		}

		&.alt-color {
			background-color: transparent;
			color: $color-brand-white;

			.no-touch &:hover {
				color: $color-brand-blue;
			}
		}
	}

	&.show-popup {
		transform: translate3d(0,0,0);
		visibility: visible;
		transition: transform .2s ease-in-out, opacity 0s;
		opacity: 1;
	}

	&.popup-globalization {
		transition-delay: 0s, .2s;

		&.show-popup {
			transition-delay: .7s, 0s;
		}
	}

	@include bp-max($bp-desktop-large) {
		width: calc(100% - #{$site-header-nav-width-desktop});

		.btn-cookie-popup {
			font-size: .8em;
		}
	}

	@include bp-max($bp-tablet) {
		width: calc(100% - #{$site-header-nav-width-tablet});
		padding: px-to-rem(20px) px-to-rem(30px);
	}

	@include bp-max($bp-mobile) {
		width: 100%;
	}

	@include bp-max(600px) {
		flex-direction: column;

		.cookie-message {
			max-width: none;
			width: 100%;
			font-size: .75rem;
		}

		.popup-buttons {
			margin-top: .75rem;
		}
	}
}
