.breadcrumb-nav {
	padding-right: 1em;

	.nav-links {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.nav-link {
		@extend %body-6;

		display: inline;
		margin-bottom: 0;

		a {
			color: $color-text-gray;

			&:hover {
				color: $color-brand-dark-black;
			}
		}

		&:after {
			content: '>';
			padding: 0 .5em;
		}

		&:last-of-type {
			font-family: $font-family-sans-serif-bold;
			color: $color-text-gray;

			&:after {
				content: none;
			}
		}
	}

	@include bp-max($bp-mobile) {
		padding-right: 0;
	}

	@include bp-min($bp-mobile-landscape) {

		.nav-links {
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}