 .article-title {
   font-size: 1.35rem;
   line-height: 1.4;
 }

.article-title--popular-recent  {
   @extend %body-4;
   font-size: px-to-rem(15px);
   margin-top: 1.7rem;
   display: block;
   color: $color-green-blue-lagoon;

	&:hover {
		color: #003a52;
	}
}

.article-meta,
.article-tag  {
  transition: $base-animation;
}

.article-tag {
  @extend %body-5;

  color: $color-brand-red;
  margin-bottom: .5rem;
}

.article-tag--pub {
  color: $color-brand-green
}

.article-tag--blog {
  color:$color-brand-yellow
}

.article-meta {
  @extend .small-text-size;
  color: $color-text-gray;
  font-style: italic;
  margin-top: px-to-rem(5px);
}

 .article-meta-item {
   &:after {
     content: '|';
     display: inline-block;
     padding:0 .3rem 0 .5rem;
   }

   &:last-child {
     &:after {
       content: '';
       padding:0;
     }
   }
 }
