.footer-links {

	.footer-social-icons-list {
		position: relative;

		&.is-focused,
		&:hover {
			.social-list-container {
				visibility: visible;
				opacity: 1;
				transition: opacity .4s, visibility .01s 0s;
			}
		}
	}

	.footer-links-chevron-down {
		font-size: rem(14px);
	}

	.social-list-container {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-44%); // don't ask about the magic number, 
		backface-visibility: hidden;
		z-index: 1;
		padding-bottom: 1em;
		transition: opacity .4s, visibility .01s .4s;
	}

	.social-list {
		padding: 0 1em 1em;
		background: $color-brand-white;
		border: 1px solid #E2E2E2;
		cursor: pointer;
		font-size: 0.77778rem;
		margin-bottom: 0;

		.social-item {
			margin: 1em 0 0;

			a { 
				word-break: unset; 
			}
		}
	}

	.icon-list {
		display:flex;
		justify-content: center;
		align-items: center;

		.icon-item {
			position: relative;
			display: flex;
			align-items: center;
			padding: 12px;
			cursor: pointer;

			.list-div {
				position: static;
				cursor:pointer;
				margin-left: 3px;
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid $color-text-dark;

				.social-list {
					visibility: hidden;
					opacity: 0;
					position: absolute;
					left: 50%;
					bottom: 100%;
					z-index: 1;

					margin: 0;
					margin-bottom: 0;
					margin-left: -58px;
					padding: 0 1em 1em;

					background: white;
					border: 1px solid #E2E2E2;
					transition: opacity .4s, visibility .01s .4s;
					cursor: pointer;
					font-size: 0.77778rem;

					.social-item {
						margin: 1em 0 0;

						a { cursor: pointer;
						    word-break: unset; }
					}

				}
			}


			&:hover {
				.list-div {
					border-top: none;
					border-bottom: 5px solid $color-brand-dark-black;

					.social-list {
						visibility: visible;
						opacity: 1;
						transition: opacity .4s, visibility .01s 0s;
					}
				}

				.footer-link {
					color: $color-brand-dark-black;
				}
			}
		}
	}
}