.search-by-letter {
	$border-style: 1px solid $color-gray-gainsboro;

	padding: 1rem;
	border: $border-style;

	.current-value {
		display: none;
		font-family: $font-family-sans-serif;
		font-size: px-to-rem(14px);
		position: relative;
		padding-right: 2rem;
		cursor: pointer;

		&:after {
			content: $fa-var-angle-down;
			font-family: $font-family-font-awesome;
			position: absolute;
			top: 50%;
			right: 1rem;
			font-size: 1.5em;
			transform: translateY(-50%);
		}

	}

	.letters {
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: flex;
		justify-content: space-between;
	}

	.letter {
		font-family: $font-family-sans-serif;
		font-size: px-to-rem(14px);
		color: $color-green-blue-lagoon;
		cursor: pointer;
		margin: 0;
		transition: color .3s;

		&.is-disabled {
			color: $color-text-disabled;
		}

		&:hover {
			color: $color-brand-dark-black;
		}
	}

	@include bp-max(415px) {
		position: relative;
		padding: 0;

		.current-value {
			padding: 1rem;
			display: block;
		}

		.letters {
			position: absolute;
			top: 100%;
			left: -1px;
			width: calc(100% + 2px);
			display: none;
			z-index: 6;
			border: $border-style;
		}

		.letter {
			padding: .75rem 1rem;
			background-color: $color-brand-white;
			border-bottom: $border-style;
			transition: background-color .3s;

			&:last-of-type {
				border-bottom: none;
			}

			&:hover {
				background-color: $color-gray-gainsboro;
			}

			&.selected-letter {
				display: none;
			}
		}

		&.show-letters {
			.current-value {
				&:after {
					content: $fa-var-angle-up;
				}
			}

			.letters {
				display: block;
			}
		}
	}
}