.sitemap {
  height: 100%;
  display: block;
  margin: auto 5rem;

  &__header {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 1rem;
    margin: 0 auto 2.5rem;
  }

  &__column {
    font-family: $font-family-sans-serif;    
    flex-wrap: wrap;
    flex: 1 1 0px;
    margin: 0 2rem;
  }

  &__section {
    margin-bottom: .75rem;
  }

  &__section-title {    
    text-transform: uppercase;
    font-weight: bold;
    margin: .375rem 0;
    display: inline-block;
  }
  
  &__links {
    margin-left: .75rem;
  }
  
  &__link {
    margin: .375rem 0;
    display: inline-block;
  }


  @media (min-width: $bp-desktop) {
    margin: auto;
  }

  @media (min-width: $bp-desktop-large) {
    display: flex;
    flex: 1 1 0px;
    margin: auto 5rem;
  }
}