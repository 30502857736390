.view-our-social-link {
    $spacing: 50px;

    @extend .btn--blue;

    display: block;
    margin-top: px-to-rem($spacing);
    text-align: center;

    &:after {
        content: "→";
        display: inline-block;
        font-family: $font-family-font-awesome;
        font-size: 1.25em;
        padding-left: 1rem;
        vertical-align: middle;
    }
}