.searchbox {
  position: relative;

  &.searchbox--small {
    width: 50%;
    font-size: .9rem;
  }
  
  &.pull-right {
    margin-bottom: $spacing-unit;
  }
}

.searchbox-input {
  padding: .8333rem .9rem;
  width: 100%;
}

.searchbox-btn  {
  background-color: $color-gray-med-alt;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  color: $color-brand-white;
  border-color: transparent;
}
