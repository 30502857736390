.location-dropdown {
    align-items: center;
    color: #707070;
    display: flex;
    font-family: $font-family-sans-serif;
    font-size: px-to-rem(13.81px);
    height: px-to-rem(75px);
    justify-content: center;
    .display-item {
        border-width: 0;
        padding-left: 0.2rem;
    }
    .select-drop-down {
        display: inline-block;
    }
    .drop-down-options {
        border-top: 1px solid #e2e2e2;
        width: 10rem;
    }
    .display-item-label {
        font-family: $font-family-sans-serif-bold;
        padding-bottom: 0.1rem;
        position: relative;
        display: inline-block;
        overflow: hidden;
        top: 3px;

        _::-webkit-full-page-media, _:future, :root & {

            top: 1px;
        }

        &:after {
            content: '';
            display: inline-block;
            width: 120%;
            bottom: 0;
            left: 50%;
            border-bottom: 2px dotted;
            position: absolute;
            transform: translate3d(-50%, 0, 0);
        }
    }
}
