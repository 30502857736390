.page-tab-panels {
    transition: opacity 0.5s ease-in-out;
    margin-top: -($spacing-unit-section / 2);

    @include bp-max($bp-mobile-landscape) {
        margin-top: 0;

        &.page-tab-panels__slim-margin {
            margin-top: -($spacing-unit-section / 2);
        }
    }

    &--no-margin{
        margin-top: 0;
    }

    &.is-transitioning {
        position: relative;
        opacity: 0.5;
    }

    &.page-tab-panels__slim-margin {
        margin-bottom: 0;
    }

    &__header {
        margin-bottom: 2.1rem;

        .menu-items {
            display: flex;
            justify-content: center;

            &.menu-items__hide{
                display: none;
            }

            @include bp-max($bp-mobile-landscape) {
                flex-wrap: wrap;

                .page-tools-item-pdf {
                    display: none;
                }
            }
        }

        .page-tools-item-pdf {
            position: absolute;
            top: calc(100% + 5px);
            right: 0;
        }
    }


    &__section {
        display: none;

        div.rte-expand +.rte-expand{
            margin-top:0;
        }

        &.active-panel {
            display: block;
        }

        &--deep {
            .inner-wrapper:not(.section-header-standard){
                 .section-header {
                    text-align: left;
                    margin-bottom: 0;

                    &::before {
                        height: 0;
                        border-bottom: none;
                    }

                    h2 {
                        font-size: 1rem;
                        font-family: $font-family-sans-serif-bold;
                        max-width: 100%;
                        padding: 0;
                        color: #000;
                    }
                }
            }
           

            .page-wrapper {
                margin-top: 0;
            }

            .content-wrapper {
                @media (max-width: $bp-desktop-large) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .rte-expand {
                margin-bottom: 1.4rem;
            }

            .rte-expand-button {
                padding-top: 0;
            }
        }
    }
}
