//$profile-width: px-to-rem(310px);
$content-min-width: 460px;

.office-hero {
  background-color: $color-brand-gray-light;
  display: flex;
  margin-top: $spacing-unit;

}

.office-hero-image{
  position: relative;
  width: 60%;
  max-width: calc(100% - #{px-to-rem($content-min-width)});
  background-size: cover;
  background-position: 50% 50%;
}

.office-hero-content {
  width: 40%;
  min-width: px-to-rem($content-min-width);
  position: relative;
  padding: px-to-rem(30px) 5.5% px-to-rem(15px);
  border: 1px solid $border-color-input;
}

@include bp-max($bp-desktop){
  .office-hero{
    flex-direction: column;
  }

  .office-hero-image {
    min-height: px-to-rem(200px);
    width: 100%;
    max-width: none;
  }

  .office-hero-content {
    width: 100%;
    min-width: 0;
  }
}

.office-hero-carousel {
  $position: px-to-rem(20px);
  position: absolute;
  bottom: $position;
  right: $position;
  border: 1px solid $color-brand-white;
  color: $color-brand-white;
  padding: px-to-rem(5px) px-to-rem(8px);


  @include bt-icon-before($bt-var-camera) {
    position: relative;
    z-index: 5;
  }

  &:hover {
    background-color: $color-brand-dark-black;
  }

  @include bp-max($bp-people-small) {
    bottom: -1px;
    right: -1px;
  }
}
@include bp-max($bp-people-small) {
  .office-hero-wrapper {
    position: relative;
  }
}

