.credentials {
  .section-header {
    margin-bottom: 2rem;
  }
}


.credentials-title {
  @extend .h6-size;
  @extend %uc;

  margin-bottom: 1rem;
}

.credentials-list {
  margin-top: $spacing-unit * 1.25;
}

.credentials-item {

  .rich-text {
    @extend %body-4;

    p {
			strong {
				@extend .h6-size;
				@extend %uc;
				margin-bottom: 1rem;
			}
    }
    
    ul {
      margin-left: 0;
      list-style: none;

        li {
            padding-bottom: $spacing-unit-small;
            margin-top: 0;
            font-size: 0.8rem;
            padding-left: 0;

            &::before {
              display: none;
            }
            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }
  }

  & + &  {
    margin-top: $spacing-unit * 1.25;
  }

  &-disclaimer {
    @extend %body-2;
    font-size: px-to-rem(13px);
    padding-top: px-to-rem(5px);
    bottom: $contact-module-padding;
    line-height: px-to-rem(16px);
  }

}

