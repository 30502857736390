input[type="text"],
button {
    outline: none;
}

input {
  border: 1px solid $border-color-input;
  padding: .5rem .9rem;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: $font-weight-regular;
  font-family: $font-family-titles;
  &:lang(ja){
    font-family: $font-family-titles-ja;
  }

  @include placeholder {
    color:$color-text-gray-alt;
  }
}

fieldset {
  padding: 0;
  border: 0 none;
  margin: 0;
}

label {
  display: inline-block;
  line-height: 1.2;
  padding: .5rem 0;
}


legend {
  border-bottom:1px solid $border-color-base;
  padding-bottom: $spacing-unit/2;
  margin-bottom: $spacing-unit;
  display: block;
  width: 100%;
}