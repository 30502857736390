.insight-tabs {
    margin-top: 20px !important;
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-top: solid 1px #C4C4C4;
    border-bottom: solid 1px #C4C4C4;
    .tab-item {
        text-align: center;
        width: 50%;
        font-family: $font-family-sans-serif;
        letter-spacing: 3px;
        padding: 4px 0;
        font-size: 12px;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
            font-weight: bold;
        }
        &:first-child {
            border-right: solid 1px #C4C4C4;
        }
    }
}
.media-tab {
    .banner {
        margin-top: 30px;
    }
    .btn-load-more {
        margin-bottom: 50px;
    }
}