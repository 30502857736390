//Defining base animation for PHASE 1
$animation-dim-timing: 2.5s;

$animation-complex-timing-delay: .1s;
$animation-complex-timing: $animation-dim-timing - $animation-complex-timing-delay;

$animation-delay-phase-two: $animation-dim-timing + $animation-complex-timing;

.complex-animation {
  @include absolute-center-align;

  height: 0;
  width: 70%;
  padding-bottom: 70%;
  z-index: 12;

  @include bp-min(1400px) {
    width: 50%;
    padding-bottom: 50%;
  }
}
.complex-animation-item {
  @include absolute-center-align;

  width: 100%;
  height:100%;
  background: url('../images/animation/Pasted-xxxxl.svg') no-repeat top left;
  background-size: 100%  100%;

  //animating elements
  transform-origin: 0 0;
  opacity: 0;
  transform:  scale(0, 0)
              rotate(360deg)
              translate(-50%, -50%);
  transition: opacity $animation-complex-timing $easeInOutQuint $animation-complex-timing-delay,
              transform $animation-complex-timing $easeInOutQuint $animation-complex-timing-delay;

  .is-animating-phase-1 .complex-animation & {
    transform:  scale(1, 1)
                rotate(0)
                translate(-50%, -50%);
  }
}

.complex-animation-item-sm {
  height: 59.708%;
  width: 59.708%;
  background: url('./../images/animation/Pasted.svg') no-repeat top left;

  .is-animating-phase-1  & {
    opacity: .8;
  }

}

.complex-animation-item-md {
  height: 68.203%;
  width: 68.203%;
  background-image: url('./../images/animation/Pasted-md.svg');

  transform:  scale(0, 0)
              rotate(-360deg)
              translate(-50%, -50%);
  .is-animating-phase-1  & {
    opacity: .75;
  }
}

.complex-animation-item-lg {
  height: 74.271%;
  width: 74.271%;
  background-image: url('./../images/animation/Pasted-lg.svg');

  transform:  scale(0, 0)
              rotate(-360deg)
              translate(-50%, -50%);
  .is-animating-phase-1  & {
    opacity: .7;
  }
}

.complex-animation-item-xlg {
  height: 77.184%;
  width: 77.184%;
  background-image: url('./../images/animation/Pasted-xl.svg');

  transform:  scale(0, 0)
              rotate(-360deg)
              translate(-50%, -50%);

  .is-animating-phase-1  & {
    opacity: .55;
  }
}

.complex-animation-item-xxlg {
  height: 88.249%;
  width: 88.249%;
  background-image: url('./../images/animation/Pasted-xxl.svg');

  .is-animating-phase-1  & {
    opacity: .4;
  }
}

.complex-animation-item-xxxlg {
  height: 96.601%;
  width: 96.601%;
  background-image: url('./../images/animation/Pasted-xxxl.svg');

  transform:  scale(0, 0)
              rotate(-360deg)
              translate(-50%, -50%);

  .is-animating-phase-1  & {
    opacity: .25;
  }
}

.complex-animation-item-xxxlg {
  .is-animating-phase-1  & {
    opacity: .1;
  }
}
