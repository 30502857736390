.stats {
  position: relative;
  margin: 5rem 0;
  &__items {
    display: flex;
    justify-content: space-evenly;
  }
  &__item {
    text-align: center;
    flex: 1 1 auto;
    max-width: 350px;
    &:nth-of-type(2) {
      margin-top: 5rem;
    }
  }
  &__number {
    font-family: $font-family-titles;
    font-size: 150px;
    margin-bottom: 0;

    &:lang(ja){
      font-family: $font-family-titles-ja;
    }
  }
  &__description {
    font-size: 28px;
  }

  @include bp-max($bp-desktop) {
    &__items {
      display: block;
    }
    &__item {
      margin: 2rem auto;
    }
  }
}