.page-header {
	margin-top: $spacing-unit-section;

	&--resources {
		margin-top: px-to-rem(80px);
		margin-bottom: px-to-rem(80px);
	}
}

.page-header-title {
	text-align: center;
	margin-bottom:$spacing-unit;

	&--resources {
		font-size: px-to-rem(80px);
		font-family: $font-family-titles;
		&:lang(ja){
			font-family: $font-family-titles-ja;
		}
	}

	&--light {
		color: $color-brand-white;
	}

	sup {
	    font-size: px-to-rem(16px);
		vertical-align: super;
		padding-left: 0;
		top: -1.1em;
	}	
}

.page-header-subtitle {
	@extend %body-5;

	color: $color-brand-red;
	margin-bottom: $spacing-unit-small;
	text-align: center;

	&--light {
		color: $color-brand-yellow;
	}
}

.page-header-text {
	max-width: 680px;
	margin: 0 auto;
	text-align: center;

	&--resources {
		font-family: $font-family-sans-serif;
		font-size: px-to-rem(18px);
		line-height: px-to-rem(27px);
	}

	&--light {
		color: $color-brand-white;
	}
}

.page-header--background {
	padding: px-to-rem(35px);
	background-color: $color-brand-yellow;
	background-image: url('./../images/content/complex-vector-white.svg');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: 50% 10%;
	margin-top: px-to-rem(18px);

	&-image {
		padding: px-to-rem(50px);
	}

	.page-header-title {
		@extend %hero-size;

		color: $color-brand-dark-gray;
		text-align: center;
		margin: 0;
	}

	@include bp-min($bp-mobile) {
		padding: px-to-rem(50px);
	}
}

.no-tabs {
	border-bottom: 1px solid $color-gray-gainsboro;
}