$contact-module-padding: .75rem;
$contacts-module-padding-left: 1.25rem;

.contacts-module {
    margin-top: $spacing-unit-section-large;

    &.contacts-module--site-search-results {
        margin-top: 0;
    }
}

.prof-cta {
    margin-top: 30px;
}

.contact-cards {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    @include container;
    min-width: 100%;
    // resources page specific styles
    @include bp-between($bp-mobile-landscape, $bp-mobile) {
        .inner-wrapper--resources & {
            display: inline-block;
            flex-wrap: wrap;
        }
    }

    @include bp-min($bp-tablet) {
        .inner-wrapper--resources & {
            display: inline-block;
            flex-wrap: wrap;
        }
    }
}

.contact-card {
    position: relative;
    transition: background-color .3s;

    a.not-linked {
        cursor: default;

        .contact-name span {
            color: $color-text-gray;
        }
    }

    @include bp-between($bp-mobile-landscape, $bp-mobile) {
        padding-right: 10px;
    }

    @include bp-min($bp-mobile-landscape) {
        padding-right: 10px;
    }

    &-description {
        margin: 0 0 .75rem;
        padding-right: 20px;
        display: inline-flex;

        @include bp-min($bp-tablet) {
            margin: .75rem 0;
        }
    }

    &-subheader {
        margin: .75rem 0 0;
        padding-right: 20px;
        font-style: italic;

        @include bp-min($bp-tablet) {
            margin: .75rem 0;
        }
    }

    &-groupheader {
        font-family: $font-family-sans-serif;
        color: $color-brand-red;
        font-size: px-to-rem(14px);
        text-transform: uppercase;
    }
}

.contact-link {
    background-color: $color-brand-white;
    color: $color-text-gray;
    transition: color .3s;

    @media (min-width: px-to-rem(550px)) {
        min-height: px-to-rem(161px);
    }
}


.contact-name {
    transition: color .3s;
    font-size: px-to-rem(18px);

    span {
        color: $color-green-blue-lagoon;
    }
}

.contact-name, .contact-level {
    line-height: px-to-rem(24px);
}

.contact-image {
    float: left;
    width: 4.5rem;
    height: auto;
    
    @media (min-width: px-to-rem(550px)) {
        padding-left: 0;
        padding-top: 0;
        aspect-ratio: auto 375 / 525;
        height: px-to-rem(161px);
        width: auto;
    }
}


.contact-details {
    @extend %body-4;
    padding: $contact-module-padding;
    padding-left: $contacts-module-padding-left;
    padding-top: 0;
    overflow: hidden;
    height: 100%;
    padding-bottom: 0;

    &.no-photo {
        margin-left: 4.5rem;

        @media (min-width: px-to-rem(550px)) {
            margin-left: 97px;
        }

        @media (min-width: $bp-mobile) {
            margin-left: 102px;
        }

        @media (min-width: $bp-tablet) {
            margin-left: 115px;
        }
    }

    @media (min-width: $bp-tablet) {
        padding-bottom: $contact-module-padding;
    }
}

.contact-location {
    @extend %body-2;
    bottom: $contact-module-padding;
}

.contact-disclaimer {
    @extend %body-2;
    font-size: px-to-rem(13px);
    padding-top: px-to-rem(5px);
    bottom: $contact-module-padding;
    line-height: px-to-rem(16px);
}

.contact-region, .contact-location {
    vertical-align: baseline;
    display: inline-block;
}

.contact-region__spacer {
    &::after {
        content: ', ';
        display: inline-block;
        vertical-align: text-top;
        white-space: pre;
        margin-left: -4px;
    }
}

.contact-region {
    display: inline-block;
    font-size: 0.7rem;
    text-transform: uppercase;
    vertical-align: baseline;
}

.contact-email {
    text-transform: uppercase;
}

.contact-email, .contact-tel {
    word-wrap: break-word;
    display: block;
    @extend %body-5;
    letter-spacing: .05rem;
    line-height: px-to-rem(18px);
    color: $color-green-blue-lagoon;
    cursor: pointer;

    @include bp-max($bp_desktop) {
        line-height: px-to-rem(27px);
    }
}

.contact-level {
    color: $color-brand-black;
    font-size: px-to-rem(16px);
}

.contacts-module-meet-team {
    margin-top: $spacing-unit-block;
    text-align: center;
}

.contacts-module-cta-container {
    margin-top: 2.6rem;
    text-align: center;

    .cta-links-link {
        display: inline-block;
    }
}

.contact-cards__two-column {
    .contact-card {
        @include bp-min($bp-desktop-large) {
            width: calc(50% - 1rem);
            margin-bottom: 0;
            display: inline-block;
            vertical-align: top;
        }
    }
}

.tab-content .inner-wrapper .rte-expand .module-pattern:first-of-type .module-pattern__content--small {
    padding-top: 0;
}


.tab-content .inner-wrapper .rte-expand .module-pattern .module-pattern__content--small .contacts-module {
    margin-top: 0;
}
