.page-tools {
	margin: $spacing-unit 0 0;
	text-align: center;
	padding-left: 0;
}


.page-tools-item {
	margin: 0;
	display: inline;
	margin-right: $spacing-unit-small;
	position: relative;

	&:last-child {
		margin-right: 0;
	}

	&.page-tools-item-pdf{
		@include bp-max($bp-desktop) {
			&.pdf{
				display:none;
			}
		}

		margin-right:0;
		.list-div{
			display:inline-block;
			position: static;
			cursor:pointer;
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid black;
			.pdf-list{
				display: none;
				position: absolute;
				z-index: 20;
				top: 100%;
				left: 0;
				transform: translate3d(-50%, 0, 0);
				padding: 1em;

				background: white;
				border: 1px solid #E2E2E2;
				-webkit-transition: background-color .3s;
				transition: background-color .3s;
				cursor: pointer;
				font-size: 0.77778rem;

				.pdf-item{
					white-space: nowrap;
					font-size:12px;
					margin: 0;
					text-transform:normal;
					font-family: $font-family-sans-serif;
					+ .pdf-item {
						margin-top: 1em;
					}
					a { cursor: pointer; }
					&.letter,&.a4{
						color: $color-green-blue-lagoon;
						&:hover{
							color: $color-green-dark-green;
						}
					}
				}
			}
		}
		&:hover {
			.page-tools-link.pdf{
				background-color:#656365;
			}
			.list-div {
				border-top: none;
				border-bottom: 5px solid black;

				.pdf-list {
					display: block;
				}
			}
		}
	}
}

.page-tools-link {
	$icon-height: 17px;
	$icon-width: 30px;

	display: inline-block;
	color: $color-text-icons;
	text-align: center;
	vertical-align: middle;

	@include bp-max($bp-mobile) {

		&:before {
			padding-top: px-to-rem(2px);
			display: inline-block;
		}
	}

	&:hover {
		color: $color-gray-med;
	}

	&.linkedin {
		margin-top: -1px;
		@include font-awesome-icon-before($fa-var-linkedin) {
			font-size: px-to-rem(22px);
			vertical-align: middle;
		}
	}

	&.twitter {
		&:before {
			font-family: $ico-icon-name !important;
			content: "\e913";
			font-size: 17px;
			vertical-align: middle;
		}
	}

	&.pdf {
		background-color: $color-text-icons;
		position: relative;
		height: $icon-height;
		width: $icon-width;
		vertical-align: middle;

		.tab-menu & {
			max-height: 15px;
		}

		&:hover {
			background-color: $color-gray-med;
		}

		&:before {
			content: '';
			background-image: url("./../images/pdf.svg");
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: 65%;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}
	}

	&.vcard {
		background-color: $color-text-icons;
		vertical-align: middle;
		position: relative;
		height: $icon-height;
		width: $icon-width;

		&:hover {
			background-color: $color-gray-med;
		}

		&:before {
			content: '';
			background-image: url("./../images/vcard.svg");
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: 60%;
			background-position: 50% 45%;
			background-repeat: no-repeat;
		}
	}

	&.blog {
		background-color: $color-text-icons;
		padding: 0 px-to-rem(6px);
		vertical-align: middle;

		&:before {
			content: url("./../images/icon-blog.png");
		}

		&:hover {
			background-color: $color-gray-med;
		}
	}

	&.fa-linkedin {
		font-size: 1.12em;
		position: relative;
		top: 3px;
	}
}

