//$profile-width: px-to-rem(310px);

.people-hero {
  background-color: $color-brand-gray-light;
  display: flex;
  flex-flow: row nowrap;
  align-content: stretch;
  align-items: stretch;
  margin-top: $spacing-unit;

  @include bp-max($bp-people-desktop) {
    display: block;
  }
}


.people-hero-image{
  position: relative;
  // width: $profile-width;


   @include bp-max($bp-people-desktop) {
    float: left;
    margin: px-to-rem(30px) 5.5% $spacing-unit;

    .people-hero-pic {
      height: px-to-rem(260px);
      max-height:434px;
    }
  }
  @include bp-max($bp-people-small) {
    width: 30%;

    .people-hero-pic {
      height: auto;
      width: 100%;
    }
  }
}

.people-hero-pic {
  display: block;
  max-width: none;
  max-height: 434px;
  width: auto;
  aspect-ratio: auto 375 / 525;
}

.people-hero-carousel {
  $position: px-to-rem(20px);
  position: absolute;
  bottom: $position;
  right: $position;
  border: 1px solid $color-brand-white;
  color: $color-brand-white;
  padding: px-to-rem(5px) px-to-rem(8px);


  @include bt-icon-before($bt-var-camera) {
    position: relative;
    z-index: 5;
  }

  &:hover {
    background-color: $color-brand-dark-black;
  }

  @include bp-max($bp-people-small) {
    bottom: -1px;
    right: -1px;
  }

}


.program-carousel {
  $position: px-to-rem(20px);
  padding: px-to-rem(5px) px-to-rem(8px);
  font-size:1.3rem;

  @include bt-icon-before($bt-var-camera) {
    position: relative;
    z-index: 5;
  }

  &:hover {
    background-color: $color-brand-white;
  }

  @include bp-max($bp-people-small) {
    bottom: -1px;
    right: -1px;
  }

}


.people-hero-content {
  flex-grow: 1;
  align-items: center;
  position: relative;
  padding: px-to-rem(30px) 5.5% px-to-rem(15px);
  border: 1px solid $border-color-input;

  @include bp-max($bp-people-desktop) {
     padding-bottom: px-to-rem(30px)
  }
}

.people-overview{
  .rte-expand,rte-expand-full{
    @media (max-width: $bp-mobile) and (min-width: $bp-mobile-portrait) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  }

  &-disclaimer {
      padding-top: px-to-rem(18px)
  }
}

