.site-search-form {
    @extend %site-search-padding;

	$top-spacing: 1rem;

	margin: 2.2rem auto 0;

	.search-form-buttons {
		margin: $top-spacing 0 2rem;
	}

	.search-parameters {
		margin-top: $top-spacing;
	}

	.insight-type-filters {
		margin-top: $top-spacing * 3;
	}

	@include bp-max(1100px) {
		.search-text-input,
		.advance-search,
		.search-form-buttons,
		.search-parameters {
			$spacing: 1rem;

			margin-left: $spacing;
			margin-right: $spacing;
		}
	}

	@include bp-max(900px) {
		.search-text-input,
		.advance-search,
		.search-form-buttons,
		.search-parameters {
			$spacing: 0;

			margin-left: $spacing;
			margin-right: $spacing;
		}
	}

}
