.sector-selection {

	.sector-list {
  		margin: 0;
  		padding-left: 0;
	}

	.sector-list-container {
		max-width: 50%;
				margin-left: auto;
		margin-right: auto;
	}

	.sector-list-item {
		border: 1px solid $color-brand-white;
		background-color: $color-text-disabled;
		border-right: 0 1px 1px 0;
		margin: 0;
		width: 100%;

		@include bp-max($bp-mobile-landscape) {
			display: block;
			border-right: 0;
		}


	}
	.sector-list-link {
		display: block;
		position: relative;
		overflow: hidden;
		height: px-to-rem(165px);
		text-align: center;
		padding: 0 $spacing-unit-small;
		transition: none;

		&:not(.sector-list-item--no-img):after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 3;
			background-color: rgba($color-brand-dark-black, .5);
			transition: background-color 1s ;
		}

		&:not(.sector-list-item--no-img):hover {


			&:after {
				background-color: rgba($color-brand-dark-black, .3);
			}

			.sector-img {
				filter: grayscale(0%);
				transform:translate(-50%, -50%) scale(1.05);
			}

		}
	}

	.sector-external-link {

		.sector-title {

			&:after {
				content: '↑';
				transform:  rotate(45deg);
				margin-left: px-to-rem(5px);
				display: inline-block;
			}
		}

	}

	.sector-title {
		@extend %body-3;

		@include vertical-align;
		color: $color-brand-white;
		position: relative;
		z-index: 10;
		display: inline-block;
		font-size: px-to-rem(18px);
		font-weight: bold;
		text-shadow: 0 0 4px rgba(0,0,0,.75);
	}

	.sector-img {
		@include absolute-center-align;

		z-index: 2;
		min-width: 100%;
		min-height: 100%;
		max-width: none;
		transition: all 1s ;
		transform-origin: 50% 50%;
    	filter: grayscale(100%);
	}

}
