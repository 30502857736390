.site-utility-bar {
  .language-bar {
    margin-top: $spacing-unit;
  }

  @include bp-min($bp-tablet) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .breadcrumb-nav {
      margin-top: 0;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      min-width: 0;
    }

    .language-bar {
      flex-shrink: 0;
      margin-top: 0;
    }
  }
}
