.related-insights {
	margin-top: $spacing-unit-section-large;

	.section-header {
		margin-bottom: 0;
	}

	.articles-grid-list--2 {
		margin-right: 0;
		display: block;

		a {
			&:nth-of-type(2n){
				.infographic-grid-item {
					transform: translate3d(2px, 0, 0);
				}
			}
		}

		.infographic-grid-item {
			width: calc(50% - 2px);
			min-height: 0;
			padding-bottom: 49%;
		}
	}
}

.related-insights-content{
	margin-top: $spacing-unit * 1.5 - 1.5rem;

	.rich-text-intro {
		margin-top: $spacing-unit-block;
	}
}