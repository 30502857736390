//****************************************************************************
//utility mixins
//****************************************************************************

@mixin vertical-align {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

@mixin horizontal-align {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

@mixin absolute-center-align {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

//Mixin for placeholder text
@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}


@mixin font-awesome-icon {
	display: inline-block;
	font: normal normal 14px/1 'Font Awesome 5 Brands', 'Font Awesome 5 Free';
	font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); // ensures no half-pixel rendering in firefox
}


@mixin closeButton {
	background-color: transparent;

	display: none;

	.fa {
		font-size: 35px;
	}

	@include bp-max($bp-tablet) {
		display: inline-block;
	}

	&:hover {
		color: $color-gray-mid;
	}
}

@mixin smooth-font {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}



@mixin bt-icon-before($icon-type) {

  &:before {
    content: $icon-type;
	display: inline-block;
	font: normal normal normal #{$bt-font-size-base}/1 "Black Tie"; // shortening font declaration
	font-size: inherit; // can't have font-size inherit on line above, so need to override
	vertical-align: (-100%*2/14);
	text-rendering: auto; // optimizelegibility throws things off #1094
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0); // ensures no half-pixel rendering in firefox

    @content;
  }
}

@mixin bt-icon-after($icon-type) {

  &:after {
    content: $icon-type;
	display: inline-block;
	font: normal normal normal #{$bt-font-size-base}/1 "Black Tie"; // shortening font declaration
	font-size: inherit; // can't have font-size inherit on line above, so need to override
	vertical-align: (-100%*2/14);
	text-rendering: auto; // optimizelegibility throws things off #1094
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0); // ensures no half-pixel rendering in firefox

    @content;
  }
}

@mixin font-awesome-icon-before($icon-type) {
  &:before {
  	content: $icon-type;
	display: inline-block;
	font: normal normal 14px/1 'Font Awesome 5 Brands', 'Font Awesome 5 Free';
	font-size: inherit; // can't have font-size inherit on line above, so need to override
	text-rendering: auto; // optimizelegibility throws things off #1094
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0); // ensures no half-pixel rendering in firefox

  	@content;
  }
}