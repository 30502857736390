.email-disclaimer,
.modal-general  {
	$animation-duration: .8s;
	$max-width: 800px;
	$border-style: 100px solid rgba($color-brand-dark-black, 0);
	position: fixed;
	width: 100%;
	padding: px-to-rem(100px) $spacing-unit-section;
	left: 0;
	top: 0;
	height: 100%;
	background-color: $color-brand-white;
	z-index: $zindex-modal-bg;
	opacity: 0;
	visibility: hidden;
	transition: opacity $animation-duration $easing,
	          visibility 0.1s $easing $animation-duration;
	background-clip: padding-box;
	border-top: $border-style;
	border-bottom: $border-style;
	overflow: auto;

	&.is-active-modal {
    	opacity: 1;
    	visibility: visible;
    	z-index: $zindex-modal-body;
    	transition: opacity $animation-duration .01s $easing, visibility 0s;
	}

	.title {
		@extend .h1-size;
		text-transform: capitalize;
		max-width: px-to-rem($max-width);
		margin-left: auto;
		margin-right: auto;
	}

	.copy {
		margin: $spacing-unit 0 $spacing-unit;
		max-width: px-to-rem($max-width);
		margin-left: auto;
		margin-right: auto;
	}

	.actions {
		max-width: px-to-rem($max-width);
		margin-left: auto;
		margin-right: auto;
	}
	.script-embed {
		min-height: px-to-rem(260px);
		margin: $spacing-unit 0 $spacing-unit;
	}
	.modal-close {
		position: absolute;
		right: 1.5rem;
		top: 1.5rem;
		color: $color-text-gray;
		font-size: px-to-rem(12px);

		&:hover {
			color: $color-brand-black;
		}

	    &:before {
	      content: 'Close';
	      text-transform: uppercase;
	      font-family: $font-family-sans-serif-bold;
	      letter-spacing: 2px;
	      color: $color-brand-blue;
	      margin-right: .5em;
	    }

	    @include bt-icon-after( $bt-var-times) {
	      color: $color-brand-blue;
	    }
	}

	@include bp-max($bp-mobile-portrait){
		$border-style: 40px solid rgba($color-brand-dark-black, 0);

		padding: px-to-rem(60px) 1rem;
		border-top: $border-style;
		border-bottom: $border-style;

	}

	@include bp-max(360px) {
		$border-style: 30px solid rgba($color-brand-dark-black, 0);

		padding: px-to-rem(40px) 1rem;
		border-top: $border-style;
		border-bottom: $border-style;

		.title {
			font-size: 1.5rem;
		}

		.copy {
			font-size: .9rem;
		}
	}
}

.modal-bg {
	background-color: rgba($color-brand-dark-black, .9);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index:0;
	visibility: hidden;
	opacity: 0;
	transition: opacity 300ms ease-in, visibility 300ms linear 300ms;

	.is-active-modal & {
		opacity: 1;
		visibility: visible;
		z-index: $zindex-modal-bg;
		transition: opacity 300ms ease-in .01s, visibility 100ms linear ;
	}
}