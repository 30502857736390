.autocomplete-suggestions-wrapper {
    position: relative;
}

.autocomplete-suggestions {
    $border-style: 1px solid $color-gray-gainsboro;

	background-color: $color-brand-white;
	overflow-y: auto;
    width: 100% !important;
    max-height: 350px;
    border-bottom: $border-style;
  //  overflow-y:scroll;

	.autocomplete-suggestion {
    	padding: rem(15px) rem(20px);
		border: $border-style;
        border-top: none;
		line-height: 1.2;
		cursor: pointer;

        &:last-of-type {
            border-bottom: none;
        }

    	.auto-term {
            color:$color-brand-red;
        }

        &:hover {
	        background-color: $color-brand-gray-light;
        }
    }

    .autocomplete-selected {
        background-color:$color-brand-gray-light;
    }
}