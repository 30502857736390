.office-hero-header {
    $section-spacing: px-to-rem(15px);

    padding-bottom: $spacing-unit;
    border-bottom: 1px solid $border-color-divider;
    margin-bottom: $spacing-unit;
    text-align: center;

    .page-title {
        margin-bottom: $spacing-unit-small;

        @include bp-max($bp-people-small) {
            font-size: px-to-rem(30px);
        }
    }

    @include bp-max($bp-people-desktop) {
        padding-bottom: 0;
        border-bottom: 0 none;
    }


    @include bp-max($bp-people-small) {        
        text-align: center;
    }
}


.office-hero-body {
    $section-spacing: px-to-rem(20px);

    @extend %body-3;

    line-height: 2.25;
    text-align: center;

    .address,
    .office-hero-tel {
        margin-bottom:  0;
    }


    @include bp-max($bp-people-desktop) {
        clear: both;

        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-top: $spacing-unit;
            border-top: 1px solid $border-color-divider;
        }
    }

    @include bp-max($bp-people-small) {
        font-size: px-to-rem(14px);

        .page-tools {
            display: none;
        }
    }
}

.office-hero-label {
    display: inline-block;
    margin-right: $spacing-unit-small;
}

// .office-hero-map-mobile{
//     display:none;
//     text-transform:uppercase;
//     font-family: $font-family-sans-serif;
//      @include bt-icon-before($bt-var-map-marker) {
//         margin-right: $spacing-unit-small;
//         font-weight: 900;
//         font-size: px-to-rem(22px);
//         vertical-align: sub;
//         color: $color-text-icons;
//     }
//     @include bp-max($bp-people-small) {
//        display:block;
//     }
// }

.office-hero-map {
    text-transform: uppercase;


    @include bt-icon-before($bt-var-map-marker) {
        margin-right: $spacing-unit-small;
        font-weight: 900;
        font-size: px-to-rem(22px);
        vertical-align: sub;
        color: $color-brand-blue;
    }

    // @include bp-max($bp-people-small) {
       // left: 40%;
       //  position: absolute;
       //  top: px-to-rem(55px);
        // display:none;
    // }
}