.page-insights-sidley-updates {
	$spacing: 50px;

	.article-wrapper {}
	.article-content {
		margin-top: px-to-rem($spacing);
        &.hold{
            padding-left:0;
        }
	}

    .byline{
        margin-top: px-to-rem($spacing / 2);
    }

    .header{
    	font-weight:bold;
    }


    @include bp-max($bp-mobile){
        .article-content {
            padding-top: 0;
        }
    }

    .article-share-tools {
        .header {
            margin-top: 0;
        }
    }

}

