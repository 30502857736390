
.audience-selection {

	.audience-list {
		display: flex;
  		flex-flow: row wrap;
  		align-items: stretch;
  		margin: 0;
  		padding-left: 0;

  		@include bp-max($bp-mobile-landscape) {
			display: block;
		}
	}

	.audience-list-container {
		flex: 1 1 50%;
		margin: 0;
		@include bp-max($bp-tablet) {
			flex: 1 1 100%;
		}

		@include bp-max($bp-mobile) {
			flex: 1 1 50%;
		}
	}

	.audience-list-item {
		border: 1px solid $color-brand-white;
		border-right: 0 1px 1px 0;
		margin: 0;



		@include bp-max($bp-mobile-landscape) {
			display: block;
			border-right: 0;
		}


	}


	.audience-list-link {
		display: block;
		position: relative;

		overflow: hidden;
		height: px-to-rem(165px);
		text-align: center;
		padding: 0 $spacing-unit-small;
		transition: none;
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 3;
			background-color: rgba(0,0,0,0.5);
			transition: background-color 1s ;
		}

		&:hover,
		&.is-active {


			&:after {
				background-color: rgba(0,0,0,0.3);
			}

			.audience-img {
				filter: grayscale(0%);
				transform:translate(-50%, -50%) scale(1.05);
			}

		}
	}

	.audience-external-link {

		.audience-title {

			&:after {
				content: '↑';
				transform:  rotate(45deg);
				margin-left: px-to-rem(5px);
				display: inline-block;
			}
		}

	}

	.audience-title {
		@extend %body-3;

		color: $color-brand-white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		width: 100%;
		z-index: 10;
		padding: 0 1rem;



	}

	.audience-img {
		@include absolute-center-align;

		z-index: 2;
		min-width: 100%;
		min-height: 100%;
		max-width: none;
		transition: all 1s ;
		transform-origin: 50% 50%;
    	filter: grayscale(100%);
	}

}
