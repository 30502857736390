.detail-overview {
    margin-top: $spacing-unit-section-large;
    padding-left: 0;
}

.detail-overview-rte {
	.rte-expand {

		& + .rte-expand {
			margin-top: $spacing-unit-section;
		}
	}
}

// Locations Detail modifications
.detail-overview {
    &.detail-overview--locations-detail {
        .section-header {
            margin-bottom: 1.4rem;
            margin-top: -2.2rem;
        }
    }
}