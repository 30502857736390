.accordions-browse-container {
	margin-top: 3.9rem;

	.section-header {
		margin-bottom: 0;
	}
}

.accordions-collapse-expand-container {
	justify-content: center;
	color: $color-green-blue-lagoon;
	margin-bottom: 2.7rem;
	margin-top: 2rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	.accordions-drawer-btn {
		@extend .btn--blue;
	}

	.accordions-drawer-btn--active {
  		display: none;
	}
}

.accordions-collapse-expand-wrapper {
	display: inline-flex;
	justify-content: center;
	border: 1px solid;
}

.accordions-drawer-btn {
	@extend %body-4;
	display: inline-block;
	margin-left: 0 !important;
	cursor: pointer;
	position: relative;
	text-align: left;
	text-transform: uppercase;
	transition: border-bottom 0s 0.5s;
}

.accordions-drawer-container {
    background-color: $color-brand-white;
	position: relative;
	z-index: 1;

    .accordions-drawer-btn {
        $border-color: #e4e4e5;

        background-color: $color-brand-gray-light;
        border-color: $border-color;
        border-style: solid;
        border-left-width: 1px;
        border-right-width: 1px;
        border-top-width: 1px;
        color: $color-green-blue-lagoon;
        padding-bottom: 1.6rem;
        padding-left: 1.5rem;
        padding-right: 2rem;
        padding-top: 1.6rem;
		width: 100%;

        &:hover {
            font-family: $font-family-sans-serif-bold;
        }
        &::after {
            content: "+";
            margin-right: 1.35rem;
           	position: absolute;
           	right: 0;
        }
    }
}

.accordions-drawers-container {
	margin-left: auto;
	margin-right: auto;
	padding-left: 6em;

	@include bp-max($bp-mobile){
		padding-left: 0;
	}

	.accordions-drawer-container:last-of-type {
		.accordions-drawer-btn {
			border-bottom-style: solid;
			border-bottom-width: 1px;
		}
	}

	&.careers-accordians-drawers-container{
		padding-left: 0;
		margin-top: 2.7rem;
	}

	&.accordions-drawers-container__no-padding{
		padding-left: 0;
	}
}

.accordions-drawer-container--active {
	.accordions-drawer-btn {
	    border-bottom-width: 1px;
    	font-family: $font-family-sans-serif-bold;
    	font-weight: $font-weight-bold;
	    transition: border-bottom 0s;

	    &::after {
	        content: "−";
	    }
	}

	.accordions-drawer-list {
		transition: all 0.5s, opacity 0.4s;
	}

	.accordions-drawer-list--phantom {
		opacity: 1;
	    visibility: visible;
	}

	.accordions-drawer-list:not(.accordions-drawer-list--phantom) {

		.accordions-drawer-list-item {
            min-height: 100%;
		}
	}
}

.accordions-drawer-list-container {
    position: relative;
    padding: 2rem 0;
    display: none;
}

.accordions-drawer-list--phantom {
    background-color: $color-brand-white;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
}

.accordions-drawer-list {
/*    column-gap: 1rem;
    column-count: 1;
    column-width: auto;*/
    margin-bottom: 0;
    padding-left: 1.2rem;
    transition: all 0.4s ease-out;
  /*  visibility: hidden;*/
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;

    @include bp-min($bp-mobile) {
   
    }
}

.accordions-drawer-list-item {
    margin-bottom: 0;
    padding-bottom: $spacing-unit/2;
    break-inside: avoid;
	overflow: hidden;
	&:before{
		content: '';
	}

	ul{
		list-style: disc outside none;
		padding-left: 40px;
		ul{
			list-style-type: circle;
		}
	}
}

.rich-text ul > li.acccordions-drawer-list-item:before {
	content: '';
}


.accordions-typeahead-list-container {	
	opacity: 0;
	max-height: 0;
	transition: all 0.5s ease-in-out, opacity 0.9s ease-in-out;
	margin-top:1em;
	&.is-visible {
		max-height: 1200px;
		opacity: 1;
	}
}

.accordions-typeahead-list {
    display: flex;
    width: 100%;
	flex-direction: row;
	flex-flow: row wrap;	
	margin:0;

    li {
    	width: 100%;
    	margin-top: $spacing-unit/4;
    	margin-bottom: $spacing-unit/4;
    }

    @include bp-min($bp-mobile) {
	    li {
	    	width: 50%;
	    	padding-right: 1.5rem;
	    }
    }
}
