.resource-tiles {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 40px;
    &__content {
        background-color: white;
        position: relative;
        padding: 2.5rem;
        margin-top: 2rem;
        background: $color-brand-white;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        min-height: 420px;
        max-width: 400px;
        box-shadow: -1px 5px 10px rgba(0, 0, 0, 0.15);
        transition: box-shadow 0.35s ease-out,
        transform 0.25s ease-out;
        &:hover {
            box-shadow: -5px 10px 20px 2px rgba(0, 0, 0, 0.15);
            transform: translate(1px, -3px);
        }
    }
    &__title {
        font-size: px-to-rem(26px);
        margin-bottom: px-to-rem(30px);
        color: $color-brand-dark-gray;
    }
    &__date {
        font-size: px-to-rem(18px);
        font-style: italic;
        color: $color-brand-dark-gray;
    }
    &__supporting-content {
        color: $color-brand-dark-gray;
    }
    .resource-tiles__tile.full {
        width: 100%;
        .resource-tiles__background {
            width: 100%;
            left: 0;
            position: absolute;
        }
        .resource-tiles__content {
            left: -30px;
            position:relative;
        }
    }
    &__tile {
        width: 48%;
        height: 420px;
        position: relative;
        +.resource-tiles__tile {
            margin-top: 2rem;
        }
    }
    &__background {
        background-size: cover;
        height: 100%;
        width: calc(100% - 50px);
        left: 50px;
        position: absolute;
    }
    @include bp-max($bp-mobile) {
        flex-wrap: wrap;
        padding-top: 20px;
        padding-bottom: 40px;
        .resource-tiles__tile {
            width: 100%;
        }
        &__content {
            margin-right: 2rem;
        }
    }

}