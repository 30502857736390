.insights-search-form {
	$top-spacing: 1rem;

	.btn-submit.insights-btn-desktop{
		@include bp-max($bp-desktop){
			display:none;
		}

	}

	.btn-submit.insights-btn-mobile{
		margin: 1rem 0;
		@include bp-min($bp-desktop){
			display: none;
		}
	}
	.submit-block{
		display:flex;
		justify-content:space-between;
		.search-form-buttons {
			margin: $top-spacing 0 2rem;
		}
	}

	.search-parameters {
		margin-top: $top-spacing;
		margin-bottom: 2rem;
	}

	.search-text-input.search-text-input {
		margin-left: 0;
		@include bp-max($bp-desktop){
			margin-right:0;
		}
	}

	.insight-type-filters {
		margin-top: $top-spacing * 3;
	}

	@include bp-max($bp-desktop) {
		.search-text-input,
		.advance-search,
		.search-parameters {
			margin-right: 0;
		}
	}

	@include bp-max(900px) {
		.search-text-input,
		.advance-search,
		.search-form-buttons,
		.search-parameters {
			$spacing: 0;

			margin-left: $spacing;
			margin-right: 0;
		}
	}
}

