$color__brand-yellow: #FDB515;

.cookie-controls {
  width: 100%;
  background-color: $color-brand-blue;
  padding: .75rem 0;
  justify-content: normal;
  align-items: flex-start;

  &__close {
    @extend %body-5;

    position: absolute;
    right: 18px;
    top: 18px;
    color: $color-brand-white;
    cursor: pointer;

    @include bt-icon-after($bt-var-times) {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  &__mobile-close {
    display: none;
  }

  &__icon {
    width: $site-header-nav-width;
    text-align: center;
    flex-shrink: 0;
    position: relative;
  }

  &__text {    
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__content {
    width: 100%;
    .cookie-message {
      width: auto;
      max-width: none;
      width: 85%;
    }
  }

  &__drawer {
    width: 75%;

    .rich-text {
      margin-bottom: 0;
    }
  }

  &__expand {
    text-align: left;
    text-transform: uppercase;
    font-family: $font-family-sans-serif-bold;
    letter-spacing: 1.75px;
    padding: 0;
    
    .btn-read-more {
      color: $color__brand-yellow;
      font-size: 16px;
      padding-left: 15px;
      margin: 0;

      &:hover {
        color: $color__brand-yellow;
      }

      &:before {
        font-size: 0.5rem;
        font-weight: 600;
        top: 3px;
      }
    }
  }

  &__option {
    display: flex;
    color: $color-brand-white;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    &-description{
      color: #dfdfdf;
      font-size: 1rem;
      line-height: 1.5;
    }

    &-toggle {
      position: relative;
      flex-basis: 50px;
      flex-shrink: 0;
      height: 20px;
      background-color: #054868;
      border-radius: 14px;
      margin-right: 15px;

      &:after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        border-radius: 50%;
        background-color: $color-brand-blue;
        top: 2px;
        left: 3px;
        transition: left 300ms ease;
      }

      &--selected {
        &:after {
          background-color: $color__brand-yellow;
          left: unset;
          left: 30px;
        }
      }
    }

    &-text {
      flex: auto;
      display: flex;
      flex-wrap: wrap;
    }
  
    &-title {
      font-size: 1rem;
      margin-right: 1rem;
      padding-bottom: .5rem;
      font-family: $font-family-primary;
      font-weight: 700;
      line-height: 1.5;
      color: $color-gray-soft;
      &:lang(ja){
          font-family: $font-family-primary-ja;
      }
      
    }
  }

  &__buttons {
    font-size: .9em;
    margin: 1rem 1rem 0 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;

    &--mobile {
      display: none;
    }

    &--desktop {
      display: flex;
    }

    &--accepted {
      .cookie-controls__btn-undo {
        display: block;
      }

      .cookie-controls__btn-accept {
        display: none;
      }
    }
  }

  &__btn {

    &.btn-cookie-popup {
      font-weight: 600;
      flex-basis: auto;
    }

    &-undo {
      display: none;
      opacity: 0.5;

      &.btn-cookie-popup {
        background-color: transparent;
        color: $color-brand-white;
      }

      .default {
        display: flex;
        justify-content: center;

        @include bt-icon-before($bt-var-check) {
          font-size: 0.6rem;
          width: 22px;
          height: 22px;
          line-height: 22px;
          margin-right: 12px;
          padding-left: 2px;
          border-radius: 50%;
          background-color: $color-brand-white;
          color: $color-brand-blue;
          font-weight: 600;
          margin-top: -2px;
        }
      }

      .hover {
        display: none;
        justify-content: center;

        @include bt-icon-before($bt-var-times) {
          font-size: 0.6rem;
          width: 22px;
          height: 22px;
          line-height: 22px;
          margin-right: 12px;
          padding-left: 2px;
          border-radius: 50%;
          background-color: $color-brand-white;
          color: $color-brand-blue;
          font-weight: 600;
          margin-top: -2px;
        }
      }

      &:hover {
        opacity: 1;

        .default {
          display: none;
        }

        .hover {
          display: flex;
        }
      }
    }
  }

  &__reopen {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1050;
    width: $site-header-nav-width;
    text-align: center;

    &-icon {
      width: 49px;
      height: 49px;
      cursor: pointer;

      &--desktop {
        display: block;
      }
      &--mobile {
        display: none;
      }

      svg path,
      svg .icon-c {
        transition: all 300ms ease;
      }

      svg path {
        stroke: #A3A3A3;
      }

      svg .icon-c {
        fill: #A3A3A3;
      }

      &:hover {
        svg path {
          stroke: $color-brand-blue;
        }
    
        svg .icon-c {
          fill: $color-brand-blue;
        }
      }
    }
  }

  @include bp-max($bp-desktop-large) {
    &__icon,
    &__reopen {
      width: $site-header-nav-width-desktop;
    }

    &__reopen {
      bottom: 50px;
    }
  }

  @include bp-max($bp-tablet) {
    &__icon,
    &__reopen {
      width: $site-header-nav-width-tablet;
    }

    &__content {
      .cookie-message {
        width: 70%;
        padding: 1rem 0;
      }
    }

    &__option-text{
      padding-top: .75rem;
    }

    &__btn {
      .btn-cookie-popup {
        position: relative;
        right: auto;
      }
    }
  }

  @include bp-max(600px) {
    &__option-description, 
    &__option-title {
      font-size: .75rem;
    }
  }


  @include bp-max($bp-mobile) {
    padding: 1.5rem 2.5rem;
    padding-top: .5rem;
    flex-direction: column;

    &__icon,
    &__close {
      display: none;
    }

    &__content {
      .cookie-message {
        width: 100%;
        margin: 0;
        border-bottom: $color-gray-soft 1px solid;

        &--long {
          border-bottom: none;
        }
      } 
    }

    &__expand {
      .btn-read-more {
        font-size: .75rem;
      }
    }

    &__option-toggle {
      flex-basis: 40px;
      height: 19px;
      &:after {
        height: 1rem;
        width: 1rem;
        top: 2px;
      }
      &--selected {
        &:after {
          left: 22px;
        }
      }
    }

    &__mobile-close {
      display: flex;
      align-items: center;
      color: $color-brand-white;
      height: 50px;
      padding: 1rem 2rem;
      position: absolute;
      top: -50px;
      left: -20px;
      text-transform: uppercase;
      font-family: $font-family-sans-serif-bold;
      background-color: $color-brand-blue;

      svg {
        margin-right: 0.5rem;
      }

      @include bt-icon-after($bt-var-times) {
        font-size: .75rem;
        font-weight: 400;
        margin-left: 1rem;
      }
    }

    &__text {
      flex-direction: column;
      width: 100%;
    }

    &__drawer {
      width: 100%;
    }

    &__buttons {
      margin: 2.5rem 0 0;
      width: 100%;
      &--mobile {
        display: block;
        margin: 0;
        margin-top: 1rem;
        
        .cookie-controls__btn {
          width: 100%;
        }
      }

      &--desktop {
        display: none;
      }
    }

    &__btn {
      width: 100%;
      margin: 0;

      &.btn-cookie-popup {
        font-size: .75rem;
        position: relative;
        right: auto;
      }
    }

    &__reopen {
      width: 40px;
      height: 40px;
      padding: 0;
      bottom: 15px;
      left: 15px;
      background-color: $color-brand-blue;
      border-radius: 2rem;

      &-icon {
        width: 40px;
        height: 40px;
        &--desktop {
          display: none;
        }
        &--mobile {
          display: block;
        }
      }

      svg path {
        stroke: $color-brand-white;
      }
  
      svg .icon-c {
        fill: $color-brand-white;
      }

      &:hover {
        svg path {
          stroke: $color-brand-white;
        }
    
        svg .icon-c {
          fill: $color-brand-white;
        }
      }
    }
  }

  @include bp-max(600px) {
    &__icon{
      width: auto;
    }
  }
}