.category-name {
	@include bp-max($bp-tablet) {
		margin-top: 1.5rem;
	}
}

.related-services {
	margin-top: $spacing-unit-section-large;

	.category-name {
		text-transform: uppercase;
		font-family: $font-family-sans-serif-bold;
		font-size: px-to-rem(14px);
	}

	.services {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.service {
		@extend %body-4;

		display: block;
		margin: 0;
		padding-top: px-to-rem($font-size-base-px);
		font-size: $font-size-small;

		-webkit-column-break-inside: avoid;
    	page-break-inside: avoid;
    	break-inside: avoid;
    	position: relative;
	}
}

.footnote-text{
	font-size: 11px;
}

.sector-reach-container {
	&.has-two-columns{
		columns: 2 auto;
		@include bp-max($bp-tablet){
			columns: 1;
		}
	}



	margin-top: 1.5rem;
	padding-top: 1.5rem;
	border-top: 1px solid $color-gray-gainsboro;

		&.no-border{
		border-top:0;
		padding-top:0;
	}

	@include bp-max($bp-tablet) {
		padding-top: 0;
		margin-top: 0;
		border-top-width: 0;
		margin-top: 0;
		columns: 1 auto;
	}
}

.services-sectors,
.sector-reach-item {
	break-inside: avoid;
}

.sector-reach-item {


}

.services-services {
	columns: 2 auto;
	display: block;
	overflow: hidden;

	.service {
		overflow: hidden;
		display: inline-block;
		width: 100%;
	}
	@include bp-max($bp-tablet) {
		columns: 1 auto;
	}
}
