.person-gallery-slide {
    float: left;
    position: relative;
}

.person-gallery-slide-content {

    &.video-content{
        background-color:black;
    }
    @include bp-min($bp-tablet) {
        align-items: stretch;
        display: flex;
        flex-flow: row nowrap;
    }
}

.person-gallery-slide-copy {
    @extend .h4-size;

    background-color: $color-brand-gray-light;
    border: 1px solid $border-color-base;
    border-bottom: 0;
    flex: 1;
    font-size: 1.15rem;
    margin-bottom: 0;
    overflow: hidden;
    padding: 3vw 3% 3vw 5%;

    @include bp-min($bp-tablet) {
        height:435px;
        flex-grow: 1;
    }

    blockquote {
        border: 0 none;
        margin: 0;
        padding: 0;
    }

    em {
        @extend %body-2;
    }

    a.h3-size,
    a {
        color: $color-text-link;
        line-height: 1.1;
        font-size: px-to-rem(30px);
        font-family: $font-family-primary;
        &:lang(ja){
            font-family: $font-family-primary-ja;
        }
    }


}

.person-gallery-slide-media {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-bottom: 65%;

    @include bp-min($bp-tablet) {
        width: calc(50% + 1.5px);
        padding-bottom: 0;
        &.video{
              margin:auto;
        }
    }

    & + .person-gallery-slide-copy {

        @include bp-min($bp-tablet) {
            border-left-width: 1px;
        }

        border-left-width: 0;

        a {
            &:hover {
                color: $color-green-dark-green;
            }
        }
    }
}


.person-gallery-slide-img {
    @include horizontal-align;

    position: absolute;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
}


.person-gallery-slide-video-link {
    @extend %body-3;

    color: $color-green-blue-lagoon;
    margin-bottom: 0;


    @include bt-icon-after($bt-var-video) {
        font-size: 0.83em;
        font-weight: 900;
        margin-left: 0.5em;
    }
}