.infographic-grid-item {
    align-items: center;
    box-shadow: 1px 0 0 0 $border-color-articles, 0 1px 0 0 $border-color-articles, 1px 1px 0 0 $border-color-articles, 1px 0 0 0 $border-color-articles inset, 0 1px 0 0 $border-color-articles inset;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 23.4375rem;
    position: relative;
    width: 99.9%;    

    &.single-highlight{
        width: 49.9%;
    }
    .articles-grid-list--2 & {
        @media (min-width: $bp-mobile-landscape) {
            width: 49.9%;
        }
    }

    .articles-grid-list--3 & {
        @media (min-width: $bp-mobile-landscape) {
            width: 33.34%;
        }
    }

    .articles-grid-list--4 & {
        @media (min-width: $bp-mobile-landscape) {
            width: 25%;
        }
    }

    .content:not(.full-width) {
        @include absolute-center-align;

        text-align: center;
        z-index: 2;
    }
    
    .content{
        &.full-width{
            margin-bottom: -7px;
        }
    }

    .number {
        color: $color-brand-red;
        font-family: $font-family-titles;
        font-size: px-to-rem(150px);
        line-height: 0.75;
        &:lang(ja){
            font-family: $font-family-titles-ja;
        }
    }

    .number {
        + .brief {
            $spacing: 1.5rem;

            margin-top: $spacing;
            padding-top: $spacing;            
        }
    }

    .brief {
        position: relative;
        font-size: 1.33333rem;
        &:not(.no-line):after {
            background-color: $color-gray-gainsboro;
            content: "";
            display: inline-block;
            height: 2px;
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            width: 10rem;
        }
    }

    .infographic-background-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: 50% 50%;
    }


    @media (max-width: $bp-tablet) {
        max-width: none;
        min-height: 15rem;
        padding: 1.5rem;
        &.no-padding{
            padding:0;
        }
        .number {
            font-size: 5.5rem;
        }

		.brief {
            font-size: 1.2rem;
		}

        .content {
            position: static;
            transform: none;
        }
    }

}
