.subscribe-publications {
	$spacing: 2rem;

	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem 0;
	position: relative;
	margin-top: px-to-rem(50px);

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		width: 1px;
		height: 100%;
		background-color: $color-gray-gainsboro;
	}

	.prompt {
		@extend %body-3;

		margin-right: $spacing;
		width: 50%;
		text-align: center;
	}

	.subscribe-action {
		width: 50%;
		display: flex;
		cursor: pointer;
		justify-content: center;
	}

	.call-to-action {
		@extend %body-4;

		$offset: 3rem;
		position: relative;
		padding-left: $offset;
		color: $color-button-primary;		
		transition: color .3s;

		&:hover {
			color: $color-button-primary-hover;
		}

		&:before {
			content: url('./../images/envelope.png');
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	@include bp-max($bp-mobile) {
		.article-share-tools {
			display: none;
		}

		.subscribe-action {
			margin-left: 0;
			justify-content: center;
		}

		.prompt {
			margin-right: 0;
			text-align: center;
		}
	}

	@include bp-max(415px) {

		.prompt {
			margin: 0;
			text-align: center;
		}

		.subscribe-action {
			margin: 0;
			text-align: center;
		}

		.call-to-action {
			$offset: 1.5rem;

			width: 100%;
			left: 0;
			top: $offset;
			padding-left: 0;

			&:before {
				top: $offset * -1.5;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}