.resource-featured {
    padding: 40px 0 60px;
    &__container {
        display: flex;
    }    
    $padding-top: 30px;
    &__background {
        width: 40%;
        padding-top: $padding-top;
        img {
            width: 100%;
            height: 380px;
            object-fit: cover;
            object-position: center;
        }
        @include bp-max($bp-mobile) {
            padding-top: 0;
        }
    }
    &__content {
        width: 60%;
        padding: $padding-top;
        color: $bg-footer-home;
        position:relative;
    }
    a {
        color: $bg-footer-home;
        &:hover {
            color: $color-brand-blue;
        }
    }
    &__text {
        font-size: px-to-rem(18px);
        color: $bg-footer-home;
        margin-bottom: px-to-rem(30px);
    }
    &__date {
        font-size: px-to-rem(18px);
        font-style: italic;
        padding-bottom: 20px;
    }
    &__tags {
        padding: 14px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: calc(100% - 60px);
        position: absolute;
        bottom: 6px;
        left: 30px;
    }
    &__title {
        &:hover {
            color: $color-brand-blue;
        }
    }
    &__tag-item {
        padding: 6px;
        background-color: #F5F5F5;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 3px;
        font-family: $font-family-sans-serif;
        display: inline-block;
        margin-top: 5px;
    }
    &__cta {
        padding: 18px 26px;
        background-color: $color-cta-purple;
        color: white !important;
        font-size: px-to-rem(12px);
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
        letter-spacing: 2px;
        word-break: normal;
        // &:after {
        //     content: "";
        //     background-image: url('/assets/release/images/arrow-right-white.svg');
        //     display: inline-block;
        //     width: 16px;
        //     height: 12px;
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     background-position: 50%;
        //     margin-left: .5rem;
        //     vertical-align: baseline;
        // }
        &:hover {
            background-color: $color-button-primary-hover;
        }
    }
    @include bp-max(1150px) {
        &__container {
           flex-wrap: wrap;
        }  
        &__background {
            width: 100%;
        }
        &__content {
            width: 100%;
            padding: $padding-top 0 0;
        }  
        &__tags {
            width: 100%;
            position: relative;
            bottom: 0;
            left: 0;
        }
    }
    @include bp-max(550px) {
        &__tags {
            display: block;
        }
        &__cta {
            display: inline-block;
            margin-top: 36px;
        }
    }
}

//for icon
.fa.play-outline {
    font-size: 7px;
    border: solid 1px;
    padding: 4px 2px 4px 6px;
    position: relative;
    bottom: 2px;
} 