.design-detail {
  text-align: center;
  margin-top: 1rem;
}

.design-detail-canvas {
  display: inline-block;
  position: relative;
  width: 3.5rem;

  img {
    display: block;
    width: 100%;
  }

  &:after,
  &:before {
    @include vertical-align;

    content:'';
    position: absolute;
    left: 100%;
    display: inline-block;
    background-color: $border-color-base;
    height: 1px;
    margin-left: 15px;
    width: 120px;
  }

  &:after {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 15px;
  }
}
