.articles-grid-list {
     margin-top: $spacing-unit-section;

     &--spacing-bottom {
         margin-bottom: $spacing-unit-section;
     }

     @media (min-width: $bp-mobile) {
        min-height: 300px;
        margin-right: -5px;
    }

    @include bp-max($bp-mobile-portrait) {
        display: block;
    }

    &.single-highlight {
        margin: $spacing-unit-section auto 0 auto;

        @media (max-width: $bp-mobile-portrait) {
            width: 100%;
        }

        @media (max-width: $bp-tablet) {
            max-width: 15.4rem;
        }

        @media (min-width: $bp-tablet) {
           max-width: 24rem;
           width: 100%;
        }
    }

    display: flex;
    flex-flow: row wrap;

    &:not(.infographic-grid) {
        &.article-grid-list--home {
            @media (min-width: $bp-article-grid-high-res) {
                .article-grid-item:not(.article-grid-item--full) {
                    width: 33.33334%;
                }
            }
        }

        &:not(.article-grid-list--home) {
            @media (min-width: $bp-grid-three-column) {
                .article-grid-item:not(.article-grid-item--full) {
                    width: 33.33334%;
                }
            }
        }
    }
}

.article-grid-list--2 {
    .article-grid-item {
        width: 49.9%;
    }
}

.article-grid-item {
    background-color: $bg-articles;
    color: $color-text-dark;
    overflow: hidden;
    position: relative;
    transform: translateZ(0);
    display: block;

    // this prevents janky text on hover
    transition: $base-animation;
    width: 100%;


    border-bottom: 5px solid $color-brand-white;

    @media (min-width: $bp-mobile) {
        min-height: 300px;
        border-right: 5px solid $color-brand-white;
    }

    &:focus {
        outline-offset: -5px;
    }

    &:not(.article-grid-item--full) {

        //overflow: visible; needed to remove to prevent grid items with bg images from growing beyond it's own box boundaries on hover

        @media (min-width: $bp-mobile) {
            width: 50%;

        }
    }

    &.article-grid-item--popular-recent {
        background-color: #fff;
        color: $color-green-blue-lagoon;
        z-index: 2;
        box-shadow: inset 0 0 0 1px $color-gray-white-dust;
    }

    &:not(.article-grid-item--popular-recent):not(.recently-viewed-grid-item)::before {
        background-color: $color-gray-mortar;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: -1px;
        transition: transform 0.3s $easeInOutQuint;
        width: 100%;
        z-index: 1;
        transform: translate3d(0, -100%, 0);
    }

    article, .article-grid-item__popular-recent-content {
        padding: 8%;
        position: relative;
        z-index: 5;

        @include bp-max($bp-mobile) {
            padding: 40px;
        }

	    @media (max-width: $bp-mobile-portrait) {
            padding: 35px;
        }
    }

    article {
        @media (max-width: $bp-mobile-portrait) {
            min-height: 200px;
        }
    }

    &:not(.article-grid-item--popular-recent):hover {
        color: $color-brand-white;

        &:before {
            transform: translate3d(0, 0, 0);
        }

        .article-tag,
        .article-meta {
            color: $color-brand-white;
        }
    }

    &.article-grid-item--cta {
        text-align: center;

    }

    &.is-hidden {
        visibility: hidden;
        opacity: 0;
        min-height: 0;
        max-height: 0;
    }

    .article-logo {
        font-size: px-to-rem(48px);
        .fa-linkedin {
            color: #007bb6;
        }
    }
}

.article--black-text {
    color: $color-brand-dark-black !important;
    &:hover {
        .article-title {
            color: $color-brand-dark-black !important;
        }
        .article-tag {
            color: $color-brand-dark-black !important;        
        }
        .article-meta {
            color: $color-brand-dark-black !important;        
        }
    }
    .article-title {
        color: $color-brand-dark-black !important;
    }
    .article-tag {
        color: $color-brand-dark-black !important;        
    }
    .article-meta {
        color: $color-brand-dark-black !important;        
    }
}

.article-grid-item--full {
    width: 100%;
    color: $color-text-dark;
    &:before {
        display: none;
    }

    article {
        @include bp-max($bp-mobile-portrait) {
            width: 100%;
        }

        width:  50%;
        z-index: 2;
        padding: 4%;

        @media (max-width: $bp-mobile) {
            width: 100%;
            min-height: 200px;
            padding: 40px;
        }

        @media (max-width: $bp-mobile-portrait) {
            padding: 35px;
        }
    }
}
.solid-blue-background{
    background-color: rgb(0,94,133);
}
.article-grid-item--has-bg {
    color: $color-brand-white;

    .article-tag,
    .article-meta {
        color: $color-brand-white;
    }
}

.article-img {
    background: $color-brand-dark-black;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 1s;
    width: 100%;
    z-index: 1;

    &:after {
        background-color: rgba(0, 0, 0, 0.2);
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        transition: background-color 1s;
        width: 100%;
    }

    .article-grid-item--has-bg:hover & {
        transform: scale(1.05);

        a
        &:after {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    &.is-video {
        @include font-awesome-icon-before($fa-var-play) {
            $size: 50px;

            position: absolute;
            top: 75%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            z-index: 1;
            font-size: $size / 2;
            width: $size;
            height: $size;
            border-radius: 50%;
            border: $size / 10 solid $color-brand-white;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: $size / 10;
            opacity: .5;
        }
    }
}

.article-img-anchor-bottom {
    background-position: 100% 100%;
}
