


@font-face {
  font-family: 'Font Awesome 5 Brands';
  src: url('#{$fa-font-path}/brands/fa-brands-400.eot');
  src: url('#{$fa-font-path}/brands/fa-brands-400.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/brands/fa-brands-400.woff2') format('woff2'),
  url('#{$fa-font-path}/brands/fa-brands-400.woff') format('woff'),
  url('#{$fa-font-path}/brands/fa-brands-400.ttf') format('truetype'),
  url('#{$fa-font-path}/brands/fa-brands-400.svg#fontawesome') format('svg');
//  src: url('#{$bt-font-path}/brands/FontAwesomeBrands-Regular.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url('#{$fa-font-path}/solid/fa-solid-900.eot');
  src: url('#{$fa-font-path}/solid/fa-solid-900.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/solid/fa-solid-900.woff2') format('woff2'),
  url('#{$fa-font-path}/solid/fa-solid-900.woff') format('woff'),
  url('#{$fa-font-path}/solid/fa-solid-900.ttf') format('truetype'),
  url('#{$fa-font-path}/solid/fa-solid-900.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Black Tie';
  src: url('#{$bt-font-path}/solid/BlackTie-Solid-webfont.eot?v=#{$bt-version}');
  src: url('#{$bt-font-path}/solid/BlackTie-Solid-webfont.eot?#iefix&v=#{$bt-version}') format('embedded-opentype'),
    url('#{$bt-font-path}/solid/BlackTie-Solid-webfont.woff2?v=#{$bt-version}') format('woff2'),
    url('#{$bt-font-path}/solid/BlackTie-Solid-webfont.woff?v=#{$bt-version}') format('woff'),
    url('#{$bt-font-path}/solid/BlackTie-Solid-webfont.ttf?v=#{$bt-version}') format('truetype'),
    url('#{$bt-font-path}/solid/BlackTie-Solid-webfont.svg?v=#{$bt-version}#black_tiesolid') format('svg');
//  src: url('#{$bt-font-path}/solid/BlackTie-Solid.otf') format('opentype'); // used when developing fonts
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Black Tie';
  src: url('#{$bt-font-path}/regular/BlackTie-Regular-webfont.eot?v=#{$bt-version}');
  src: url('#{$bt-font-path}/regular/BlackTie-Regular-webfont.eot?#iefix&v=#{$bt-version}') format('embedded-opentype'),
    url('#{$bt-font-path}/regular/BlackTie-Regular-webfont.woff2?v=#{$bt-version}') format('woff2'),
    url('#{$bt-font-path}/regular/BlackTie-Regular-webfont.woff?v=#{$bt-version}') format('woff'),
    url('#{$bt-font-path}/regular/BlackTie-Regular-webfont.ttf?v=#{$bt-version}') format('truetype'),
    url('#{$bt-font-path}/regular/BlackTie-Regular-webfont.svg?v=#{$bt-version}#black_tieregular') format('svg');
//  src: url('#{$bt-font-path}/regular/BlackTie-Regular.otf') format('opentype'); // used when developing fonts
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Black Tie';
  src: url('#{$bt-font-path}/light/BlackTie-Light-webfont.eot?v=#{$bt-version}');
  src: url('#{$bt-font-path}/light/BlackTie-Light-webfont.eot?#iefix&v=#{$bt-version}') format('embedded-opentype'),
    url('#{$bt-font-path}/light/BlackTie-Light-webfont.woff2?v=#{$bt-version}') format('woff2'),
    url('#{$bt-font-path}/light/BlackTie-Light-webfont.woff?v=#{$bt-version}') format('woff'),
    url('#{$bt-font-path}/light/BlackTie-Light-webfont.ttf?v=#{$bt-version}') format('truetype'),
    url('#{$bt-font-path}/light/BlackTie-Light-webfont.svg?v=#{$bt-version}#black_tielight') format('svg');
//  src: url('#{$bt-font-path}/light/BlackTie-Light.otf') format('opentype'); // used when developing fonts
  font-weight: 200;
  font-style: normal;
}
