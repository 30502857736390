.globalization-menu {
  top: $site-location-bar-height;
  bottom: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: $zindex-header-bar - 1;
  background-color: $color-brand-white;
  visibility: hidden;
  transition: bottom .7s $easeInOutQuint,
              visibility  .7s $easeInOutQuint .7s;

  position: fixed;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;


  .rich-text {
    a {
      text-decoration: underline;
    }
  }

  .headroom--unpinned + & {
      top: $site-header-height;
  }

  .is-active-globalization-menu & {
    visibility: visible;
    bottom: auto;
    transition: bottom .7s $easeInOutQuint,
                visibility  .01s $easeInOutQuint;
    max-height: calc(100vh - #{$site-location-bar-height});
    overflow-y: auto;

    @include bp-max($bp-mobile-portrait){
      bottom: 0;
      overflow: auto;
    }
  }


  .searchbox-input {
    @extend %large-size;

    padding: 4% 16% 4% 5%;

    @include bp-max($bp-mobile-landscape) {
      font-size: 1.5rem;
    }
  }

  .searchbox-btn {
    font-size: 2rem;
    padding: .4rem 5%;

    @include bp-max($bp-mobile-landscape) {
      font-size: 1.3rem;
    }
  }

  &-regions {
    padding-bottom: $spacing-unit;
    border-bottom: 1px solid $border-color-input;
  }

  &-region {
    display: flex;
    flex-wrap: nowrap;
    padding-top: $spacing-unit;
    margin-top: $spacing-unit;
    border-top: 1px solid $border-color-input;
    
  }

  &-region-title {
    @extend .h6-size;
    @extend %uc;
    
    width: 30%;
    max-width: px-to-rem(220px);
    margin-bottom: 0;
  }


  &-languages {
    display: flex;
  }

  &-language {
    $radio-size: 22px;

    @extend %body-1;
    color: $color-text-base;

    padding-left: px-to-rem($radio-size) + $spacing-unit-small;
    display: inline-block;
    position: relative;

    &:not(:last-child) {
      margin-right: $site-padding-tablet ;
    }

    &:before {
      @include center-align('vertical');
      @include transition(background-color);

      content: '';
      left: 0;
      background-color: $color-brand-white;
      border: 2px solid  $color-brand-blue;
      border-radius: 50%;
      width: $radio-size;
      height: $radio-size;
      box-shadow: inset 0px 0px 0px 2px white
    }

    &.is-selected,
    &:hover {
      &:before {
        background-color: $color-brand-blue;
      }
    }
  }

  @include bp-max($bp-mobile-landscape) {
    &-region-title {
      margin-bottom: 1rem;
    }

    &-region {
      display: block;
    }
  }
}

.globalization-menu-wrapper {
  $max-width: px-to-rem(790px);

  max-width: $max-width;

  margin: 2rem auto 0;
}


.globalization-menu-greeting {
  @extend .h3-size;
  color: $color-text-base;
}


.globalization-menu-languages {
  display: flex; 
}

.globalization-menu-region--global {
  margin-bottom: $spacing-unit;
}

.globalization-menu-cookie-disclaimer  {
  @extend .small-text-size;

  margin: $spacing-unit 0 0;
}

.globalization-menu-close{
  @extend %body-5;

  position: absolute;
  right: $site-padding-mobile;
  top: $site-padding-mobile;
  color: $color-text-link;

   @include bt-icon-after($bt-var-times) {
      font-size: 0.6rem;
      margin-left: 0.3rem;
  }
}




.is-active-globalization-menu {
  position: fixed;
  overflow-y: scroll;
  left: 0;
  right: 0;
}

@include bp-min($bp-mobile-landscape) {

  .globalization-menu {
    top: 0;
    bottom: auto;
    border-top: $site-header-bar-height solid transparent;
    overflow-y: visible;
    transform: translateY(-100%);
    transition: transform .7s $easeInOutQuint,
                  visibility  .01s $easeInOutQuint .7s;


    .headroom--unpinned + & {
      top: 0;
    }


    .is-active-globalization-menu & {
      visibility: visible;
      bottom: auto;
      transform: translateY(0);
      transition: transform .7s $easeInOutQuint,
                  visibility  .01s $easeInOutQuint;
    }
  }

  .globalization-menu-cta {
    margin-top: 0;
  }


  .globalization-menu-close {
    top: $site-padding;
  }
}

body {
  &:after {
    content: '';
    background-color: $opaqueblack;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: $zindex-header-bar - 2;

    transition: opacity .7s $easeInOutQuint,
                visibility  .7s $easeInOutQuint .7s;
  }


  &.is-active-globalization-menu  {

    &:after {
      opacity: 1;
      visibility: visible;
      transition: opacity .7s $easeInOutQuint,
                  visibility  .01s $easeInOutQuint;
    }
  }
}

.globalization-menu__checkbox-label-text {
  font-size: 0.85rem;
  margin-right: 0.2rem;
}