.carousel {
  position: relative;
  margin: 3rem 0 5rem 0;
  
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &__navs {
    display: flex;
    position: absolute;
    top: 0;
    right: 2rem;
    z-index: 3;
  }
  &__nav {
    position: relative;
    height: 70px;
    width: 70px;
    background-color: $color-brand-white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 25px;
    @include transition(opacity 0.7s);

    &:hover {
      cursor: pointer;
      &::before {
        box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
      }
    }
    &.swiper-button-disabled {
      opacity: 0;
      // &::before {
      //   box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.05);
      // }
    }
    &--next {
      background-image: url('/assets/release/images/arrow-next.svg');
    }
    &--prev {
      background-image: url('/assets/release/images/arrow-prev.svg');
      margin-right: 5px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.15);
      @include transition(box-shadow);
    }
  }
  &__cards {
    margin: 1rem 0;
  }
  &__card {
    width: 80%;
    max-width: 900px;
    padding: 2rem;
    position: relative;
    margin: 0 2rem;
    // margin-top: 100px;
    min-height: 500px !important;
    // @include transition(margin 1s cubic-bezier(0.785, 0.135, 0.15, 0.86));
    // animation-delay: .1s;
    &:nth-child(2n) {
      margin-top: 100px;
    }

    &:focus {
      outline: none;
    }
  }
  &__background {
    position: absolute;
    top: 0;
    left: 4.5rem;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: $color-brand-blue;

    &--none {
      background-color: transparent;
    }
  }
  &__parallax {
    position: absolute;
    height: 100%;
    left: -45px;
    right: -45px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  &__content {
    width: 75%;
    display: block;
    position: relative;
  }

  &__content-box {
    background: $color-brand-white;
    position: relative;
    padding: 1.75rem 2.5rem;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.17);
    transition: box-shadow 0.35s ease-out,
      transform 0.25s ease-out;
  }

  &__content:hover &__content-box {
    box-shadow: 0px 4px 25px rgba(0,0,0,.25);
    transform: translateY(-4px);
  }

  &__title {
    font-size: 24px;
  }
  &__order {
    margin-bottom: 0;
    color: #979797;
    font-size: 1rem;
  }
  .article-tag {
    font-size: 14px;
  }


  @include bp-max($bp-desktop-large) {
    &__navs {
      position: static;
      margin: 0 4.5rem;
    }
    &__card {
      width: 70vw;
    }
    &__content {
      padding: 0;
    }
  }

  @include bp-max($bp-mobile) {
    margin-top: 0;
    padding-top: 2rem;

    &__navs {
      margin: 0;
      position: static;
    }
    &__card {
      padding: 0 1.5rem;
      min-height: 400px !important;
      margin: 0;
      &:nth-child(2n) {
        margin-top: 0;
      }
    }
    &__content-box {
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.17);
    }
    &__background {
      top: 2rem;
      left: 2.75rem;
    }
    &__title {
      font-size: 20px;
    }
    &__content {
      padding: 0;
      width: 100%;
    }
  }
}