// Only display content to screen readers. A la Bootstrap 4.
//
// See: http://a11yproject.com/posts/how-to-hide-content/


#skip-nav {
  height: 1px;
  width: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: -1px;

  &:focus,
  &:active {
    height: auto;
    width: auto;
    clip: auto;
    margin: 0;
    display: inline-block;
    overflow: visible;
    z-index: $zindex-cookie-popup;
    background-color: $color-brand-white;
    color: $color-text-link;
    padding: 0.5em 1em;
  }
}

//visually hide content but accssible to screen readers
//http://a11yproject.com/posts/how-to-hide-content/
.sr-only {
	position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

//solve problem where VoiceOver jumps content to underneath the header
.sr-invisible-offset::before {
	display: block; 
	content: " "; 
	margin-top: -150px; 
	height: 150px; 
	visibility: hidden;
}