.sidley-footer {
  background-color: $bg-footer-home;
  padding: 3rem 0 0 0;
  position: relative;
  font-family: $font-family-sans-serif;
  font-size: px-to-rem(12px, 16px);
  font-weight: 300;
  overflow: hidden;
  z-index: 5;
  color: $color-brand-white;

  &__perpetual {
    position: absolute;
    right: -300px;
    bottom: -300px;
    width: 600px;
    z-index: -1;
    opacity: 0.4;
    @include bp-max($bp-mobile) {
      bottom: -100px;
      max-width: 600px;
      // z-index: 0;
      // width: 600px;
    }
  }
  &__columns {
    display: flex;
    margin: 0 3rem;
  }
  &__column {
    flex: 1 1 auto;
    flex-basis: 30%;
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
  a {
    color: $color-brand-white;
  }
  &__subheader {
    font-family: $font-family-primary;
    color: $color-brand-white;
    font-size: px-to-rem(18px, 16px);
    font-style: italic;
    &:lang(ja){
        font-family: $font-family-primary-ja;
    }
  }
  &__sign-up {
    background: $color-navy-dark;
    padding: 1rem 2rem 2rem 1rem;
    margin: 0 1rem 2rem -1rem;
    .cta-links-arrow {
      color: $color-gold;
      font-size: px-to-rem(12px, 16px);
      &::after {
        background-image: url(/assets/release/images/arrow-right-yellow.svg);
      }
    }
  }
  &__links {
    &--languages {
      .sidley-footer__link {
        @include transition(opacity);
        text-transform: none;
        letter-spacing: 0;
        margin-bottom: 1rem;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        &--active {
          font-weight: 700;
          opacity: 1;

          &:before {
            content: '';
            display: inline-block;
            background: $color-brand-white;
            height: 2px;
            width: 10px;
            vertical-align: middle;
            margin: 3px 3px 5px 0;
          }
        }
      }
    }
  }
  .drop-down-option {
    padding: 0;
  }
  &__dropdown {
    margin: 0 auto 2rem auto;
    max-width: 300px;
    a {
      color: $color-brand-dark-black;
      width: 100%;
      padding: 1rem;
      min-width: 100%;
      display: block;
    }
    .display-item {
      background: none;
      text-align: center;
      &::after {
        color: $color-brand-white;
      }
    }
    .display-item-label {
      color: $color-brand-white;
    }
  }
  &__link {
    margin-bottom: 1.5rem;
  }
  &__link {
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  &__social-links {
    display: flex;
    align-items: center;
    margin: -3rem 2rem 0;
    justify-content: end;
  }
  li.sidley-footer__social-link {
    color: $color-brand-white;
    margin: 12px;
    font-size: px-to-rem(25px, 16px);
    position: relative;
    cursor: pointer;

    &:hover {
      color: #e2e2e2;
    }
  }
  &__icon {
    &--dropdown {
      display: flex;
      align-items: center;
      &:active {
        outline: 0;
      }
      &.is-focused {
        ~ .sidley-footer__dropdown-items {
          display: block;
        }
      }
      .bt-caret-down {
        font-size: px-to-rem(18px, 16px);
        margin-left: 5px;
      }
    }
    .fa-twitter {
      font-family: $ico-icon-name !important;
      &:before {
        content: "\e913";
        font-size: 0.95em;
        vertical-align: middle;
      }
    }
  }
  &__dropdown-items {
    display: none;
    position: absolute;
    font-size: px-to-rem(14px, 16px);
    bottom: 100%;
    min-width: 200px;
    right: 0;
    margin-bottom: 10px;
  }
  &__dropdown-item {
    background: #fff;
    margin: 0;
    &:not(:last-of-type) {
      border-bottom: 1px solid #e8e8e8;
    }
    a {
      color: $color-brand-dark-black;
      display: block;
      padding: 0.5rem;
      &:hover {
        background: $color-brand-gray-light;
      }

    }
  }
  &__secondary {
    background: $color-navy-dark;
    padding: 1.5rem 2rem 1rem 2rem;
  }
  &__secondary-links {
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: px-to-rem(10px, 16px);
    justify-content: flex-start;
  }
  &__secondary-link {
    margin: 0 1.5rem 0.5rem 0;
    font-size: px-to-rem(14px);
    flex-basis: calc(25% - 2rem);
  }
  &__copyright {
    font-size: .625rem;
    flex-basis: calc(100% - 2rem);
  }
  &--mobile {
    display: none;
  }
  &--desktop {
    display: block;
  }
  &__child {

    &-header {
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 1.5rem;
    }
  
    &-link {
    display: block;
    margin-bottom: 1rem;
    text-transform: none;
    letter-spacing: 0;

    
      &::before {
        content: '—';
        vertical-align: middle;
        margin-right: 0.5rem;

      }
    }
  }

  @include bp-max($bp-desktop-large) {
    &__columns {
      flex-flow: row wrap;
      margin: 0 2rem;
    }
    &__column {
      flex-basis: calc(33% - 2rem);
      margin-right: 0;
      &--social {
        display: flex;
        justify-content: center;
      }
    }
    &__social-links {
      margin: 0;
    }
    &__sign-up {
      margin: 0 0 2rem 0;
      padding: 1rem 1rem 1rem 1rem;
    }
  }

  @include bp-max($bp-desktop) {
    &__column {
      flex-basis: calc(50% - 2rem);
      &--sign-up,
      &--social,
      &--languages {
        flex-basis: 100%;
        margin-right: 0 !important;
      }
    }
    &__social-links {
      margin-top: 0%;
    }
    &__secondary {
      padding-bottom: 5rem;
    }
    &__secondary-link {
      flex-basis: calc(50% - 2rem);
    }
    &__copyright {
      flex-basis: calc(100% - 2rem);
    }
    &--mobile {
      display: block;
    }
    &--desktop {
      display: none;
    }
    &__dropdown-items {
      transform: translateX(50%);
    }
  }

  @include bp-max($bp-mobile-portrait) {
    &__secondary-link,
    &__column {
      flex-basis: 100%;
    }
  }
}