button,
.btn {
	display: inline-block;
	cursor: pointer;
	background-color: transparent;
	transition: $base-animation;
	padding: 0;
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-regular;
	border: 1px solid transparent;
	text-align: center;

	&:focus {
		outline: thin dotted;
	}
	& + & {
		margin-left: 1rem;
	}
}

.btn__row {
	&--center {
		text-align: center;
	}
}

.btn--ghost {
	@extend %body-5;
	border: 1px solid $border-color-base;
	color: $color-text-gray;
	padding: 1rem 2rem;
/*	font-family: $font-family-sans-serif;
	font-weight: $font-weight-regular;
	text-transform: uppercase;
	line-height: 14px;
	font-size: 12px;*/
	letter-spacing: .1rem;
/*	height:52px;*/

	&:hover {
		background-color: $color-brand-gray;
	}
}

.btn--white {
	@extend .btn;
	@extend %body-5;

	background-color: $color-brand-white;
	border: 1px solid;
	color: $color-green-blue-lagoon;
	padding: 1rem 2rem;

	&:hover {
		background-color: $color-button-primary-hover;
		color: $color-brand-white;
	}
}

.btn--text-link {
	@extend %body-4;

	text-transform: none;
	padding: 0;
	letter-spacing: 0;
}


.btn--icon-next {
	padding: 1rem;

	&:after {
		@include bt-icon;
		content: "→";
		display: inline-block;
		font-weight: 900;
		font-size: 1.05rem;
		margin-left: .5rem;
		vertical-align: inherit;
	}
}

.btn--blue  {
	@extend .btn;
	@extend %body-5;

	color: $color-brand-white;
	background-color: $color-button-primary;
	padding: 1rem 2rem;

	&:hover {
		background-color: $color-button-primary-hover;
		color: $color-brand-white;
	}
}

//button--blue is actually purple whereas this button is actually blue --pat 
.btn--true-blue, .btn--true-gray  {
	@extend .btn;
	@extend %body-5;

	color: $color-brand-blue;
	background-color: $color-brand-white;
	padding: 1rem 2rem;

	&:hover, &--active {
		background-color: $color-brand-blue;
		color: $color-brand-white;
	}
}

.btn--true-gray {
	background-color: $color-brand-gray;
	border: 1px solid $border-color-base;

	&:hover, &--active {
		background-color: $color-brand-blue;
		color: $color-brand-white;
		border-color: transparent;
	}
}


.btn-submit {
	@extend %body-5;
	color: $color-brand-white;
	background-color: $color-button-primary;
	padding: 1rem 2rem;

	&:focus {
		border: thin dotted;
	}

	&:hover {
		background-color: $color-button-primary-hover;
	}

	&:disabled {
		background-color: $color-button-primary-disabled;
		cursor: not-allowed;
	}
}

.btn-back-section {
	@extend %body-5;

	color: $color-brand-white;
	background-color: $color-button-primary;
	padding: 1rem 2rem;

	.bts {
		margin-right: 0.5em;
	}

	&:hover {
		background-color: $color-button-primary-hover;
		color: $color-brand-white;
	}
}

.btn-meet-team{
	@extend %body-5;

	color: $color-brand-white;
	background-color: $color-button-primary;
	padding: 1rem 2rem;

	&:hover {
		background-color: $color-button-primary-hover;
	}
}

.btn-read-more {
	@extend %body-5;
	color: $color-text-link;

	position: relative;
	overflow: visible;


	&:hover {
		color: $color-shade-blue;
	}

	@include bt-icon-before($bt-var-plus) {
		position: absolute;
		margin-left: -1rem;
	}

	&.is-expanded {
		&:before {
			content: $bt-var-minus;
		}
	}
}

.btn-load-more {
	@extend .btn;
	@extend %body-5;

	display: block;
	width: 100%;
	text-align: center;
	color: $color-button-primary;


	padding: 1.5rem;
	border: 1px solid $color-gray-gainsboro;
	background-color: $color-brand-white;
	transition: color .4s, background-color .4s;

	&:before {
		content: $bt-var-plus;
		font-family: $font-family-icons;
		margin-right: .75rem;
		font-size: .75em;
	}

	&:hover {
		color: $color-brand-white;
		background-color: $color-button-primary;
	}
}

.btn--our-work-overview--footer {
	margin-bottom: 3.1rem;
	margin-top: 3.8rem;

	&:before {
		content: $fa-var-caret-left;
		position: relative;
		top: 3px;
		margin-right: .5rem;
		@include font-awesome-icon;
		font-size: 1.2rem;
	}
}

.btn--our-work-deep--footer {
	margin-bottom: 5rem;
	margin-top: 3rem;

	&:after {
		content: $fa-var-caret-right;
		position: relative;
		top: 3px;
		margin-left: .5rem;
		@include font-awesome-icon;
		font-size: 1.2rem;
	}
}

.btn--our-work-deep--footer, .btn--our-work-overview--footer {
	@extend %body-3;
	color: $color-green-blue-lagoon;
	background-color: $color-brand-gray;
	display: block;
	font-size: px-to-rem(14px);
	padding-bottom: 1rem;
	padding-top: 1rem;
	text-align: center;
	width: 100%;

	&:hover {
		background-color: $color-gray-white-dust;
	}
}
