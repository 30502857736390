.people-hero-body {
	$section-spacing: px-to-rem(15px);

	@extend %body-3;
	text-align: center;

	.email {
		margin-bottom: px-to-rem(16px);
	}

	@include bp-max($bp-people-desktop) {
    	clear: both;

    	&:before {
    		content: '';
    		display: block;
    		width: 100%;
    		padding-top: $spacing-unit;
    		border-top: 1px solid $border-color-divider;
    	}
  	}

  	@include bp-max($bp-people-small) {
  		font-size: px-to-rem(14px);

  		.page-tools {
  			display: none;
  		}
  	}
}

.people-hero-contact-list {
	padding-left: 0;
}


.people-hero-contact {
	margin: px-to-rem(10px) 0 0;
	text-align: center;
	width: 100%;

	.location-name,
	.mobile-label {
		padding-right: px-to-rem(5px);
	}
}
