	.rich-text ul > li p,
	.rich-text ul > li div.Bio-Text{
		display:inline;
	}

.article-content {
	$padding: 6rem;
	$spacing: 50px;

	padding-left: $padding;
	//padding-right: $padding;
	position: relative;

	.article-share-tools {
		position: absolute;
		left: calc(50% - #{$width-max-page/2});
		left: 0;
		top: 0;

		@include bp-max($bp-desktop-large){
			left: 0;
		}
	}

	&:not(.article-content--sub-page) {
		.rich-text {
		}
	}

	.article-related-blogs {
			border-top: 1px solid $color-gray-gainsboro;
			padding-top: px-to-rem($spacing);
	}

	.rich-text {
			margin-bottom: px-to-rem($spacing);
		}


	.contacts-module {
		margin-top: px-to-rem($spacing);
	}

	.related-services {
		margin-top: px-to-rem($spacing);
	}

	.suggested-insights {
		margin-top: px-to-rem($spacing);
	}

	.btn-download {
		margin-top: $spacing-unit;
		margin-left: 0;

		&:not(:last-child){
			margin-right: 1rem;
		}

		@include bp-max($bp-mobile){
			margin-right:0;
			width:100%;
		}
	}

	@include bp-max(1100px) {
		.contacts-module {
			.contact-card {
				@include span(100%);
			}
		}
	}

	@include bp-max($bp-mobile) {
		$padding: 4rem;
		padding-left: 0;
		padding-top: 30px;	//Spacing for shared links on mobile
	}

	@include bp-max($bp-mobile-portrait) {
		padding-right: 0;
	}
}

// Our Story Detail
	.article-content--our-story-detail{
		.rich-text {
			border-bottom: none;

			p {
				&:first-child {
					@extend .h4-size;
				}
			}
		}

	}
	.article-content--program-page.article-content--program-page.article-content {
		margin-top: px-to-rem(50px);
		padding-left: 0;

		.share-tools-container {
			position: relative;
		}

		.scrollmagic-pin-spacer {
			+ .tab-content-general--wrapper {
				.page-wrapper {
					margin-top: 0;
				}
			}
		}

		.rich-text {
			border-bottom: none;
		}

		.rte-expand {
			padding-left: 6rem;
		}

		.rte-expand.rte-expand__no-padding {
			padding-left: 0;
		}

		@include bp-max($bp-mobile){
			.rte-expand {
				padding-left: 0;
			}
		}
	}
