.contacts-list{

    & &-items{
        margin-bottom: 1rem;
        width: 48%;
    }

    & &-items__full-width{
        width: 100%;
    }

    &-lists{
        display: flex;
        justify-content: space-between;
    }

    &-items{
        & &-location, & &-lawyer{

            padding-left: 0;
            margin: 0;

            &:before{
                content: '';
                display: none;
            }
        }

        & &-location:not(:first-child){
            margin-top: 1rem;

            &__split{
                break-before: column;
            }
        }

        & &-location{            
            font-weight: 700;

            &.contacts-list-items-location__top{
                margin-top: 0;
            }

        }        

        @include bp-max($bp-mobile-landscape) {
            flex-wrap: nowrap;

            & &-location, & &-lawyer{
                width: 90%;
            }

            & &-location{            
                &__top{
                    margin-top: 1rem;
                }    
            }   
        }
    }
    &-email{
        white-space: nowrap;
    }

}