.story-card-container {
	$image-aspect-ratio: 1.289156627;
	$width: 19rem;

	margin-bottom: 0.3rem;
	min-height: calc(#{$width} / #{$image-aspect-ratio});
    overflow: hidden;
    position: relative;
	width: $width;
    transform: translateZ(0);

    @include bp-min(px-to-rem(500px)) {
		$width: 25rem;
		margin-bottom: 0.39rem;
    	width: $width;
    }

    @include bp-min(px-to-rem(600px)) {
		$margin-right: 5px;
		margin-bottom: 5px;
		margin-right: $margin-right;
        width: calc(50% - #{$margin-right});
    }
    @include bp-min(px-to-rem(1300px)) {
		$margin-right: 4px;
		margin-bottom: 4px;
		margin-right: $margin-right;
        width: calc(33.3333% - #{$margin-right});
    }
    &::before {
        content: "";
        cursor: pointer;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
		opacity: 0.6;
    	transition: all 1s;
		background-image: linear-gradient(to top, #686868 0%, $color-brand-black 50%);
        transform: translateZ(0);
        z-index: 10;
    }
    &:not(.story-card-container--blockquote):hover {
        &::before {
        	opacity: 0.4;
        }

        .story-card-background-img {
            transform: translate3d(-50%, -50%, 0) scale(1.05);
        }
    }
	&.is-video{
		@include bt-icon-after($bt-var-play) {
			$size: 40px;
			position: absolute;
            bottom: 10px;
            right: 10px;
            //transform: translate3d(-50%, -50%, 0);
            z-index: 10;
            font-size: $size / 2;
            width: $size;
            height: $size;
            border-radius: 50%;
            border: $size / 10 solid $color-brand-white;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: $size / 10;
			color: $color-brand-white;
            opacity: .5;
		}
	}
}

.story-card-container--blockquote {
    background-color: $bg-articles;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &::before {
        background-image: none;
        background-color: $color-gray-mortar;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: -1px;
        transition: transform 0.3s $easeInOutQuint;
        width: 100%;
        z-index: 1;
        transform: translate3d(0, -100%, 0);
        opacity: 1;
    }

    &:hover {
        &:before {
            transform: translate3d(0, 0, 0);
        }

        .story-card-quote,
        .story-card-quote-author {
            color: white;
        }
    }
}

.story-card-container:not(.story-card-container--blockquote) .story-card-content {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height:100%;
    header {
        margin-bottom: 0.5rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-top: 1.2rem;
    }
}

.story-card-content {
    position: relative;
    z-index: 20;
}

.story-card-background-img {
    @include absolute-center-align;

    overflow: hidden;
    max-width: none;
    min-width: 105%;
    min-height: 105%;
    z-index: 1;
    transition: transform .3s;
}

.story-card-subtitle {
    font-family: $font-family-primary;
    font-size: px-to-rem(14px);
    font-style: italic;
    color: $color-brand-white;
    &:lang(ja){
        font-family: $font-family-primary-ja;
    }
}

.story-card-heading {
    color: $color-brand-white;
    font-size: 1.33em;
    margin-bottom: 0.4rem;
}

.story-card-quote {
    color: $color-brand-red;
    @extend .h4-size;
    font-size: px-to-rem(22px);
    margin-bottom: 0.2rem;
    transition: color .3s;
}

.story-card-quote-author {
    color: $color-gray-med;
    @extend %body-2;
    font-size: px-to-rem(14px);
    transition: color .3s;
}

.story-card-tag {
    color: $color-brand-white;
    display: inline-block;
    bottom: 0;
    z-index: 10;
    left: 1.5rem;
    font-family: $font-family-sans-serif;
    font-size: px-to-rem(12px);
    letter-spacing: 0.05rem;
    line-height: 1.35;
    position: absolute;
    padding: 0.15rem 0.3rem;
    margin-top: 1.4rem;
    min-width: 5rem;
    text-align: left;
    text-transform: uppercase;
}

.firm {
    background-color: $color-brand-yellow;
}

.probono {
    background-color: $color-brand-red;
}

.sidley150 {
    background-color: $color-green-blue-lagoon;
}
