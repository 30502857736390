%site-search-padding {
    $site-search-padding: 20%;
    $site-search-padding-tablet: 15%;
    $site-search-padding-mobile: 1rem;

    // padding-left: $site-search-padding;
    // padding-right: $site-search-padding;

    // @include bp-max($bp-desktop) {
    //     padding-left: $site-search-padding-tablet;
    //     padding-right: $site-search-padding-tablet;

    // }

    // @include bp-max($bp-mobile) {
        padding-left: 0;
        padding-right: 0;
    // }
}