.programs-projects  {
    margin-top: $spacing-unit-section-large;
    padding-left: 0;

    .rich-text {
        color: $color-brand-dark-gray
    }

    .entity-grid {
        margin-top: $spacing-unit-section;
    }
}
