.headroom {
    transition: transform .3s linear;
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
   	  @include bp-min($bp-mobile) {
    		transform: translateY(-100%);

    	&.site-header {
    		transform: translate(0);
    	}
	}
}
