.explore-stories-container {
	margin-top: 3.4rem;

	.btn-load-more {
		margin-bottom: 3rem;
		margin-top: 3rem;
	}
}
.explore-stories-tabs {
	margin-bottom: $spacing-unit-section;
}


.explore-stories-tab {
	display: none;	
	&.is-active-tab {
		display: block;
	}
}