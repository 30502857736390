.insights-landing-search {
    .insights-search-form {
        margin-bottom: 2rem;
		margin: 0 auto 0;
	}
    .insight-tabs + #podcast-section {
        margin-top: 50px;
        @include bp-max($bp-mobile) {
            margin-top: 30px;
        }
    }
    .banner {
        margin-top: 10px;
        +.inner-wrapper.full {
            padding-top:30px;
        }
        @include bp-max($bp-mobile) {
            padding-top: 10px;
        }
    }
    .resources-tab {
        .banner {
            margin-top: 50px; 
        }
    }
}