/**
 *
 * Utility Classes/
 *
 */

.util-pull-left {
	float: left;
}

.util-pull-right {
	float: right;
}

.util-divider {
	position: relative;
	padding-top: $spacing-unit;
	margin-top: $spacing-unit;

	&:before {
		@include horizontal-align;

		content: '';
		display: inline-block;
		border-top: 1px solid $border-color-base;
		position: absolute;
		width: 75px;
		position: absolute;
		margin-top: -$spacing-unit;
	}
}
.util-reset-list  {
	padding: 0;
	margin: 0;
	list-style: none;

	li {
		margin-bottom: 0;
	}
}

.util-mobile-only {
	display:none;

	@include bp-max($bp-mobile) {
		display: block;
	}
}

.util-mobile-hidden {
	@include bp-max($bp-mobile) {
		display: none;
	}
}
