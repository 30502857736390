﻿

.article-content {
    &:not(.article-content--sub-page) {

            .article-social-assets {
                .rich-text {
                    border-bottom: none;
                }
            }
    }
    .article-share-tools { &.dedicated-social-assets{
        position:relative;
        }
    }

}

.article-social-assets {
     padding-top:2em;
     }

.article-share-tools {
    &.dedicated-social-assets {
        width: 100%;

        .share-tools {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;

            li{
                width: 50%;
                text-align: center;
                font-size: 1.5rem;
                margin: 1.5rem 0;
                padding: 0 1rem;
            }
        }

        .dedicated-social-asset {
            padding-right: 0.5rem;
            &:before {
                font-family: $font-family-font-awesome-brands;
                line-height: 0;
                position: relative;
                top: 3px;
            }
        }

        .link-twitter {
            
            &:before {
                font-family: $ico-icon-name !important;
                content: "\e913";
                color: $color-brand-dark-black;
                font-size: 0.89em;
            }
        }

        .link-linkedin {
            color: #007bb6;
            &:before {
                content: $fa-var-linkedin;
            }
        }

        .link-facebook {
            color: #3b5998;
            &:before {
                content: $fa-var-facebook;
            }
        }

        .link-email {
            &:before {
                font-family: $font-family-icons;
                content: $bt-var-envelope;
            }
        }
    }
}
