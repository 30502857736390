.services-browse-container {
	margin-top: 3.9rem;

	.section-header {
		margin-bottom: 0;
	}
}

.services-link {
  font-family: $font-family-sans-serif;
  font-size: px-to-rem(15px);
  break-inside: avoid;
  overflow: hidden;
}

.services-collapse-expand-container {
	justify-content: center;
	color: $color-green-blue-lagoon;
	margin-bottom: 2.7rem;
	margin-top: 2rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	.services-drawer-btn {
		@extend .btn--blue;
	}

	.services-drawer-btn--active {
  		display: none;
	}
}

.services-collapse-expand-wrapper {
	display: inline-flex;
	justify-content: center;
	border: 1px solid;
}

.services-drawer-btn {
	@extend %body-4;
	display: inline-block;
	margin-left: 0 !important;
	cursor: pointer;
	position: relative;
	text-align: left;
	text-transform: uppercase;
	transition: border-bottom 0s 0.5s;
}

.services-drawer-container {
    background-color: $color-brand-white;
	position: relative;
	z-index: 1;

    .services-drawer-btn {
        $border-color: #e4e4e5;

        background-color: $color-brand-gray-light;
        border-color: $border-color;
        border-style: solid;
        border-left-width: 1px;
        border-right-width: 1px;
        border-top-width: 1px;
        color: $color-green-blue-lagoon;
        padding-bottom: 1.6rem;
        padding-left: 1.5rem;
        padding-right: 2rem;
        padding-top: 1.6rem;
		width: 100%;

        &:hover {
            font-family: $font-family-sans-serif-bold;
        }
        &::after {
            content: "+";
            margin-right: 1.35rem;
           	position: absolute;
           	right: 0;
        }
    }
}

.services-drawers-container {
	margin-left: auto;
	margin-right: auto;

	.services-drawer-container:last-of-type {
		.services-drawer-btn {
			border-bottom-style: solid;
			border-bottom-width: 1px;
		}
	}
}

.services-drawer-container--active {
	.services-drawer-btn {
	    border-bottom-width: 1px;
    	font-family: $font-family-sans-serif-bold;
    	font-weight: $font-weight-bold;
	    transition: border-bottom 0s;

	    &::after {
	        content: "−";
	    }
	}

	.services-drawer-list {
		transition: all 0.5s, opacity 0.4s;
	}

	.services-drawer-list--phantom {
		opacity: 1;
	    visibility: visible;
	}

	.services-drawer-list:not(.services-drawer-list--phantom) {
		max-height: 999px;
		margin-top: 2rem;
		margin-bottom: 2rem;

		.services-drawer-list-item {
            min-height: 100%;
		}
	}
}


.services-drawer-list-container {
    position: relative;
}

.services-drawer-list--phantom {
    background-color: $color-brand-white;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
}

.services-drawer-list {
    column-gap: 1rem;
    column-count: 1;
    column-width: auto;
    margin-bottom: 0;
    padding-left: 1.2rem;
    transition: all 0.4s ease-out;
    visibility: hidden;
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;
    width: 100%;
	overflow: hidden;

    @include bp-min($bp-mobile) {
    	column-count: 2;
    }
}

.services-drawer-list:not(.services-drawer-list--phantom) {
    max-height: 0;
}

.services-drawer-list-item {
    font-family: $font-family-sans-serif;
    font-size: px-to-rem(15px);
    margin-bottom: 0;
    padding-bottom: $spacing-unit/2;
    break-inside: avoid;
	overflow: hidden;
}

.services-landing-search-form {
	position: relative;
}

.services-typeahead-list-container {
	opacity: 0;
	max-height: 0;
	transition: all 0.5s ease-in-out, opacity 0.9s ease-in-out;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;

	&.is-visible {
		max-height: 1200px;
		opacity: 1;
		z-index: 20;
	}
}

.services-typeahead-list {
    $border-style: 1px solid $color-gray-gainsboro;

	background-color: $color-brand-white;
	overflow-y: auto;
    width: 100% !important;
    max-height: 350px;
    border-bottom: $border-style;

	.services-typeahead-list-item,
	.rte-expand-button {
		border: $border-style;
        border-top: none;
		line-height: 1.2;
		cursor: pointer;
		margin: 0;

		a {
	    	padding: rem(15px) rem(20px);
			color: $color-text-base;
			display: block;
		}

        &:last-of-type {
            border-bottom: none;
        }

    	.keyword {
            color:$color-brand-red;
        }

        &:hover {
	        background-color: $color-brand-gray-light;
        }
    }

    .rte-expand-button {
    	background-color: $color-brand-gray-light;
    }
}

.services-local-container {
	.services-drawer-list {
		visibility: visible;
		max-height: 999px;
	}
}

.services-column-list {
	column-count: 1;

	transition: opacity 0.4s;

	&.is-transitioning {
    position: relative;
    opacity: 0.1;
  }


	li {
		display: inline-block;
		width: 100%;
		break-inside: avoid-column;
		page-break-inside: avoid;
		break-inside: avoid;
		-webkit-column-break-inside: avoid; 
	}
	@include bp-min($bp-mobile-landscape) {
		column-count: 2;
		column-gap: 1rem;
	}

	@include bp-min($bp-desktop) {
		column-count: 2;
		column-gap: 2rem;
	}
}