.search__padding-wrapper {
    @extend %site-search-padding;

    margin-bottom: 3rem;
}

.search-results-list {
	$spacing: .5rem;

	list-style-type: none;
	padding: 0;
	margin: 0;

	.contact-card {
		&:not(.last-child){
			margin-bottom: 18px;
		}
	}

	&:not(:first-of-type).hide-list {
		display: none;
	}

	.result-tag {
		letter-spacing: 0.05rem;
		color: $color-brand-red;
		text-transform: uppercase;
		font-family: $font-family-sans-serif;
		font-size: px-to-rem(12px);
	}

	.result-title {
		font-family: $font-family-primary;
		color: $color-text-dark;
		font-size: px-to-rem(22px);
		margin-top: $spacing;
		&:lang(ja){
			font-family: $font-family-primary-ja;
		}
	}

	.result-meta {
		color: $color-gray-med;
		font-family: $font-family-primary;
		font-style: italic;
		margin-top: $spacing;
		&:lang(ja){
			font-family: $font-family-primary-ja;
		}
	}

	.result-meta-item {
		font-size: px-to-rem(14px);

		&:after {
			content: '|';
			font-style: normal;
			padding-left: .5rem;
			padding-right: .25rem;
		}

		&:last-of-type {
			&:after {
				content: none;
			}
		}
	}

	.result-region-tag {
		@extend .result-tag;

		+ .result-tag {
			font-family: $font-family-primary;
			font-size: 0.9rem;
			font-style: italic;
			text-transform: none;
			&:lang(ja){
				font-family: $font-family-primary-ja;
			}

			&::before {
			    content: '  |  ';
				white-space: pre;
			}
		}
	}

	.result-preview-text {
		margin-bottom: 0;
		margin-top: 0.5rem;
	}
}

.search-result {
	$border-style: 1px solid $color-gray-gainsboro;

	border-bottom: $border-style;
	padding: 1.5rem 0;
	margin-bottom: 0;

	&.search-result--clickable {
		a {
			&:hover {
				.result-title {
					transition: color .3s;
					color: $color-blue-dark-cerulean;
				}
			}
		}
	}

	.search-results &:first-of-type {
		border-top: $border-style;
	}

	.search-results .section-header + .search-results-list &:first-of-type {
		border-top: none;
	}

	&:last-of-type,
	&--last{
		border-bottom:none;
	}

	&--hidden{
		display: none;
	}

}