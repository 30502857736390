.site-nav-main-wrap {
  background-color: $bg-site-nav;
  position: fixed;
  top: $site-header-bar-height;
  left: 0;
  z-index: $zindex-header-main;
  width: $site-header-nav-width;
  padding-top: 50px;
  text-align: center;
  transform: translate3d(0,0,0);
  height: calc(100vh - #{$site-header-bar-height});

  &--mobile {
    display: none;
    @include bp-max($bp-mobile) { 
      display: block;
    }
  }

  @include bp-max($bp-desktop-large) {
    min-width: $site-header-nav-width-tablet;
    width: $site-header-nav-width-desktop;
	}
  @include bp-max($bp-tablet) {
		width: $site-header-nav-width-tablet;
	}

  @include bp-max($bp-mobile) {
    background-color: transparent;
    position: static;
    top:auto;
    bottom: auto;
    left: auto;
    width: auto;
    padding-top: 0;
    height: auto;
    &--desktop {
      display: none;
    }
  }
}

.site-nav {
  @include bp-max($bp-mobile) {
    background-color: $bg-nav-bar;
    padding: ($site-location-bar-height) 25px 0;
    text-align: center;
    z-index: $zindex-header-main;
    top: 0;
    bottom: 100%;
    left: 0;
    width: 100%;
    overflow: hidden;
    visibility: hidden;
    transition: bottom .75s $easeInOutQuint,
                visibility  .75s $easeInOutQuint .75s;

    position: fixed;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .headroom--unpinned + & {
      padding-top: $site-header-height;
    }

    .is-active-mobile-menu & {
      top: $site-header-and-location-height;
      bottom: 0;
      overflow: auto;
      visibility: visible;
      transition: bottom .75s cubic-bezier(.165,.84,.44,1);
      padding-top: .5rem;
    }
  }

}
