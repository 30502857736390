.module-pattern {
    background-color: $color-brand-white;
    // reset hardcoded margin
    .subscribe-publications,
    .related-services,
    .contacts-module,
    .resource-list,
    .related-insights {
        margin-top: 0;
    }

    &:first-of-type {
        padding: 0;
    }


    &:first-of-type & {
        &__content {

            &--small {
                padding-top: $spacing-unit-section;
                padding-left: 1em;
                padding-right: 1em;
            }
        }
    }

    & + & {
        padding-top: $spacing-unit-section-large;

        @include bp-max($bp-mobile) {
            padding-top: $spacing-unit-section;
        }
    }

    .rich-text {
        margin-bottom: 0;
        .rte-expand-button {
            margin-bottom: 0;
        }
    }

    .contacts-module {
        margin-bottom: px-to-rem(50px);
    }
    &__content {

        &--small {
            max-width: px-to-rem($width-max-page);
            margin: 0 auto;
            padding-left: 1em;
            padding-right: 1em;

            @include bp-max($bp-mobile) {
                padding-right: 1em;
                padding-left: 1em;
            }
        }
    }

    &__contacts + &__contacts {
        padding-top: px-to-rem(30px);
    }
}
