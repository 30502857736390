.insight-item-title {
 font-size: 1.6rem;
 line-height: 1.4;


 @include bp-max($bp-desktop) {
   font-size: 1.35rem;
 }
}

.insight-item-tag {
@extend %body-5;

color: $color-brand-red;
margin-bottom: .5rem;

}

.insight-item-link {
  color: $color-text-dark;
  font-size: px-to-rem(22px);
  list-height: 1.4;
  display: inline-block;
}


.insight-item-meta {
@extend .small-text-size;
color: $color-text-gray;
font-style: italic;
margin-top: px-to-rem(5px);
}

.insight-item-meta-item {
 &:after {
   content: '|';
   display: inline-block;
   padding:0 .3rem 0 .5rem;
 }

 &:last-child {
   &:after {
     content: '';
     padding:0;
   }
 }
}
