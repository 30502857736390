.people-gallery {

  $animation-duration: .8s;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color-brand-dark-black, 0.9);
  z-index: $zindex-modal-bg;
  opacity: 0;
  visibility: hidden;
  transition: opacity $animation-duration $easing,
              visibility 0.1s $easing $animation-duration;
  padding: px-to-rem(60px) 0;

  &.is-active-gallery {
    opacity: 1;
    visibility: visible;
    transition: opacity $animation-duration $easing;

    .page-wrapper {
      opacity: 1;
      transition-delay:  $animation-duration/2;
    }
  }

  .gallery-copy{
    text-align:center;
  }

  .page-wrapper {
    $vertical-spacing: 30px;

    opacity: 0;
    transition: opacity $animation-duration ease-in $animation-duration/2;
    background-color: $color-brand-white;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: px-to-rem($vertical-spacing);
    position: relative;

    // first inner wraper contains controls, last inner-wrapper contains image
    > .inner-wrapper {
      $controls-height: 35px;
      $padding-bottom: $vertical-spacing;

      &:first-child {
        height: px-to-rem($controls-height);
        margin-bottom: px-to-rem($padding-bottom)
      }

      &:last-child {
        height: calc(100% - #{$controls-height + $padding-bottom});
        overflow: auto;
      }
    }
  }
  @media screen and (max-width: $bp-mobile-portrait), screen and (max-height: 750px) {
    padding: px-to-rem(30px) 0;

    .page-wrapper {
      padding: px-to-rem(15px);

      > .inner-wrapper {

        &:first-child {
          margin-bottom: px-to-rem(15px);
        }
      }
    }
  }

  .gallery-controls {
    @extend %body-5;

    text-align: center;
    color: $color-text-gray;
  }

  .gallery-btn {
     color: $color-text-gray;
     border: 1px solid $border-color-input;
     padding: px-to-rem(7px) px-to-rem(10px);

     &:hover {
      color: $color-brand-black;
     }

    @include bt-icon-before( $bt-var-angle-right) {
      font-size: px-to-rem(16px);
    }
  }

  .gallery-photo {
    margin: 0 auto $spacing-unit;
    display: block;
    max-height: 70vh;
  }

  .gallery-status {
    margin: 0 $spacing-unit-small;
  }

  .gallery-btn-prev{
    &:before {
      content: $bt-var-angle-left;
    }
  }

  .gallery-slide {
    padding: 0 3rem;


    @include bp-max($bp-mobile) {
      padding: 1rem;
    }
  }

  .gallery-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    color: $color-text-gray;

    &:hover {
      color: $color-brand-black;
    }

    &:before {
      content: 'Close';
      text-transform: uppercase;
      font-family: $font-family-sans-serif-bold;
      letter-spacing: 2px;
      color: $color-brand-blue;
      margin-right: .5em;
    }

    @include bt-icon-after( $bt-var-times) {
      color: $color-brand-blue;
    }
  }
}
