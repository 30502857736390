.locations-accordions {
	$spacing: px-to-rem(55px);

	.location-accordion {
		margin-top: 1rem;

		&:first-of-type {
			margin-top: 0;
		}

		&.is-expanded {
			.btn-accordion-trigger {

				&:after {
					content: $bt-var-minus;
				}

				&:before {
					background-color: rgba($color-brand-dark-black, .3);
				}
			}
			.background-image-container {

				.cssfilters & {
					filter: grayscale(0);
				}

				.no-cssfilters & {
					&:after {
						opacity: 0;
					}
				}
			}
		}
	}

	.btn-accordion-trigger {
		display: block;
		width: 100%;
		text-align: left;
		padding: px-to-rem(70px) px-to-rem(60px);
		color: $color-brand-white;
		font-size: px-to-rem(16px);
		text-transform: uppercase;
		border: none;
		position: relative;

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color-brand-dark-black, .4);
			transition: background-color .3s;
			z-index: -1;
		}

		&:after {
			content: $bt-var-plus;
			float: right;
			@include bt-icon;
		}

		&:hover {
			.background-image-container {
				.cssfilters & {
					filter: grayscale(0);

					.touchevents & {
						@include bp-max($bp-mobile-portrait){
							filter: grayscale(100%);
						}
					}
				}

				.no-cssfilters & {
					&:after {
						opacity: 0;
					}
				}
			}
			&:before {
				background-color: rgba($color-brand-dark-black, .3);
			}
		}
	}

	.background-image-container {
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -2;

		.cssfilters & {
			filter: grayscale(100%);
			transition: filter .3s;
		}

		.no-cssfilters & {
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($color-brand-dark-black, .3);
				transition: opacity .3s;
			}
		}
	}

	.accordion-content {
		padding: $spacing;
		display: none;
	}

	.rich-text {
		+ .accordion-content-section {
			margin-top: $spacing;
		}
	}

	.section-links {
        columns: auto 2;
        column-gap: $spacing-unit/2;

        &.full-width {
        	columns: auto;
        }
	}

	.section-link-container {
		display: block;
		width: 100%;

		_:-ms-input-placeholder, :root & {
		    -webkit-column-break-inside: avoid;
		    page-break-inside: avoid;
		    break-inside: avoid;
		    display: block;
		}
	}

	.section-link {
        display: block;
        background-color: $color-button-primary;
        color: $color-brand-gray-light;
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
        text-align: center;
        padding: 1rem;
        letter-spacing: .1rem;
        font-size: px-to-rem(14px);

        &:hover {
            background-color: $color-button-primary-hover;
        }

        &.alternate-color {
			color: $color-green-blue-lagoon;
			background-color: $color-brand-gray;

			&:hover {
				background-color: $color-gray-white-dust;
			}
        }
	}

	@include bp-max(550px){
		.btn-accordion-trigger {
			padding-left: px-to-rem(30px);
			padding-right: px-to-rem(30px);
		}

		.accordion-content {
			padding-left: px-to-rem(30px);
			padding-right: px-to-rem(30px);
		}
	}
	@include bp-max($bp-mobile-portrait){
		.section-links {
			columns: auto;
		}
	}
}
