.person-gallery {
	margin-top: $spacing-unit-section;
}

.person-gallery-body {
	overflow: hidden;
}

.person-gallery-slides {
	overflow: hidden;
}

.video-embed{
	position:relative;
	padding-bottom:56.25%;
	padding-top:46px;
	height:0;
	overflow:hidden;
	display:block;
	width:100%;
	iframe {
		position:absolute;
		top:0;
		left: 0;
		height: 100%;
		width:100%;
	}

	@include bp-max($bp-tablet) {
		position:absolute;
	}
}


.person-gallery-nav  {
	overflow: hidden;

	.slide-img {
		filter: grayscale(100%);
	}

	.nav-btn {
		float: left;
		position: relative;
		width: 25%;
		overflow: hidden;
		height: px-to-rem(175px);
		cursor: pointer;
		border: 1px solid $color-brand-white;
		border-width: 1px 0 1px;

		@media (min-width: 500px) and (max-width: 800px){
			height: 225px;
		}


		& + .nav-btn {
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 1px;
				background-color: $color-brand-white;
				z-index: 3;
			}
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 2;
			background-color: rgba(0,0,0,0.4);
			transition: background-color 1s ;
		}

		&:hover {

			&:after {
				background-color: rgba(0,0,0,0.1);
			}

			.slide-img {
				transform: translate3d(-50%, -50%, 0) scale(1.05);
			}

		}


		&.slick-current {
			&:after {
				background-color: $bg-nav-bar;
			}
		}
	}

	.nav-info {
		position: absolute;
		bottom: 5%;
		left: 5%;
		width: 90%;
		z-index: 5;
		color: $color-brand-white;
		text-align: center;
	}

	.nav-title {
		font-size: px-to-rem(28px);
		line-height: 1.1;
		display: block;
	}

	.nav-location {
		font-size: px-to-rem(14px);
	}

	.slide-img {
		position: absolute;
		display: block;
		min-height: 100%;
		min-width: 100%;
		max-width: none;
		z-index: 1;
		transition: transform 1s ;
		transform-origin: 50% 50%;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
}
	.slick-prev{
			position:absolute;
			z-index:999;
			top:0;
			left:5px;
			align-items:center;
			height:100%;
		}
	.slick-prev:after{
		color:white;
		font-family: $font-family-icons;
		content: $bt-var-angles-left;
		font-size: px-to-rem(26px) !important;
	}


		.slick-next{
			position:absolute;
			z-index:999;
			top:0;
			right:5px;
			align-items:center;
			height:100%;
		}
		.slick-next:before{
			color:white;
			font-family: $font-family-icons;
			content: $bt-var-angles-right;
			font-size: px-to-rem(26px) !important;
		}
