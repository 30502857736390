
.tile-entity {
  padding: 1.1rem 1rem; 
  background-color: $color-gray-white-smoke;
  display: block;
  color: $color-gray-med;
  height: 100%;

  &:hover & {
    &__title {
      color: $color-gray-med;
    }

    &__image {
      transform: scale(1.05);
    }
  }

  &__figure {
    height: px-to-rem(105px);
    width: 100%;
    margin-bottom: px-to-rem(20px);
    position: relative;
    overflow: hidden;
  }

  &__image {
    @include bg-cover();
    background-position: center;
    background-color: $color-gray-med;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: transform 1s;
    backface-visibility: hidden;
  }

  &__title {
    font-size: 1.3rem;
    line-height: 1.2;
    color: $color-text-link;
    margin-bottom: 0.75em;
  }

  &__meta {
    @extend .small-text-size;
    font-style: italic;
    margin-top: px-to-rem(10px);
    display: inline-block;
  }
}
