.footer-links-helper,
.footer-link  {
	display: inline-block;
}

.footer-links {
	font-size: .8rem;
	line-height: 1;
	background-repeat: no-repeat;
	background-position: 50% 75%;
	background-size: 75% auto;
	padding: 1.75rem;
	background-color: $bg-footer-home;
	color: $color-brand-white;
	text-align: center;
	height: 40%;
	position: relative;
	z-index: $z-index-homepage-footer;

    @media (min-width: $bp-desktop) {
    	padding: 2em;
    }

    @include bp-max($bp-mobile) {
    	height: 30%;
  	}

  	.icon-list {
  		margin: 0;
  	}
	.site-location-disclaimer{
		display: none;
		color: $color-text-dark;
		font-size: px-to-rem(15px);
		margin-bottom: $spacing-unit;
		@include bp-max($bp-mobile) {
    	display: block;
  	}
	}
}


.footer-link {
	color: $color-brand-white;
	vertical-align: middle;
	& + &  {
		padding-left: .5rem;
	}
}

.copy-right-outer{
	text-align:center;

	.copy-right-inner {
		font-size: px-to-rem(15px);
		display: inline-block;
		color: $color-text-dark;
		font-family: $font-family-sans-serif;
	}
}

.footer-social-links-icon {
	font-size: .9rem;

	& + &  {
		padding-left: 1rem;
	}

	@media screen and (max-width: $bp-desktop) and (orientation:landscape){
  		font-size: 80%;
  	}
}

.footer-links-helper {
	margin-bottom: $spacing-unit * .6;

}
.footer-link-helper-item {
	@extend %body-4;

	line-height: 1.6;

	&:after {
		content: '|';
		display: inline-block;
		padding-left: .7rem;
	}

	&:last-child {
		&:after {
			content: '';
		}
	}
}
