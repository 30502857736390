$image-tablet-width:  span(8);
$image-mobile-width:  span(6);

$bp-full-width: $bp-mobile - 1px;

@mixin project-card-tile-styling {
	$spacing: 5px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	// Pseudo element to ensure the last row is always left align
	// Pseudo element has same width and margin as project-card
	&:after,
	&:before {
		content: '';
		display: block;
		width: calc(25% - #{$spacing / 4 * 2});
		margin-bottom: $spacing;
		order: 1;
	}

	.project-card {
		margin-bottom: $spacing;
		width: calc(25% - #{$spacing / 4 * 2});
		min-height: 300px;
		padding-left: 0;
		padding-right: 0;
		overflow: hidden;
		color: $color-brand-white;

		&:hover {
			.project-card-img {
				transform: translate3d(-50%,-50%, 0) scale(1.05);				
			}
		}
	}
	.project-card-figure {
		width: 100%;

		&:after {
			content: '';
			background-color: rgba(0,0,0,.35);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

	}
	.project-card-img {
		transform: translate3d(-50%,-50%, 0);
		transition: transform .3s;
	}
	.project-card-info {
		width: 100%;
	}
	.project-card-abstract { display: none; }
	@include bp-max(1280px){
		&:after,
		&:before,
		.project-card {
			width: calc(33.33% - #{$spacing / 3 * 2});
		}
	}

	@include bp-max(1100px){
		&:after,
		&:before,
		.project-card {
			width: calc(50% - #{$spacing / 2});
		}
	}

	@include bp-max($bp-full-width){
		&:after,
		&:before,
		.project-card {
			width: 100%;
			margin-bottom: 1.5rem;
		}

		.project-card {
			&:hover {
				.project-card-img {
					transform: none;
				}
			}
		}

		.project-card-figure {
			&:after {
				background-color: rgba($color-brand-black, 0);
			}
		}
		.project-card-info {
			color: $color-text-headings;
		}

		.project-card-img {
			transform: none;
		}
	}
}

.project-card-listing {
	.project-card {
		margin-bottom: 1.5rem;
	}
	transition: opacity 0.25s ease-out, visibility 100ms cubic-bezier(0.86, 0, 0.07, 1) 0.25s;

	@include bp-min($bp-desktop) {

			.project-card {
				$image-width:  span(6);

				&:nth-child(2n) {
					padding-left: 0;
					padding-right: $image-width;

					.project-card-figure {
						left: auto;
						right: 0;
					}
					.project-card-info {
						border-right-width: 0;
						border-left-width: 1px;

						.project-card-listing-all:not(.filtered) & {

						}
					}
				}
			}

			.project-card-listing-all:not(.filtered) {
				.project-card {
					border: 1px solid $border-color-articles;
					&:hover {
						.project-card-info {
							border-top-color: $color-gray-mortar;
							border-bottom-color: $color-gray-mortar;
							transition: border-bottom-color 0s $base-animation-timing;

							@include bp-min($bp-desktop){
								&:before {
									height: 100%;
								}
							}
						}
					}
				}
			}
	}

	// Target only featured-projects tab to show tiles between desktop and mobile states
	@media (min-width: $bp-full-width) and (max-width: $bp-desktop){
		.project-card-listing-all:not(.filtered) {
			@include project-card-tile-styling;
		}
	}

	.filtered {
		@include project-card-tile-styling;

		@include bp-min($bp-mobile) {
			.project-card-info.project-card-info {
				border: none;
			}
		}
	}
}

.project-card-listing-load {
	margin-top: $spacing-unit-block;
}



/******************PROJECT CARDS**************/
.project-card {
	background-color: $bg-articles;
	display: block;
	position: relative;
	color: $color-brand-black;

	&:hover {
		color:$color-brand-white;


		.project-card-info {
			@include bp-max($bp-full-width){
				color: $color-brand-white;

				&:before {
					height: 100%;
				}
			}
		}
	}

	@include bp-min($bp-tablet) {
		padding-left: calc(#{$image-tablet-width} + 1px);
	}

	@include bp-min($bp-full-width) {
		background-color: $bg-articles;
		padding-left: calc(#{$image-mobile-width} + 1px);
	}
}

.project-card-info {
	position: relative;
	z-index: 5;
	padding: 8% 10%;
	transition: border-top-color 0s $base-animation-timing,
		border-bottom-color 0s;

	&:before {
		background-color: $color-gray-mortar;
		content: "";
		display: block;
		width: 100%;
		height: 0;
		left: 0;
		top: 0;
		position: absolute;
		transition: height 0.3s $easeInOutQuint;
		width: 100%;
		z-index: -1;
	}

	@include bp-min($bp-full-width) {
		$card-height: px-to-rem(300px);
		padding: 10% 8%;
		min-height: $card-height;
	}

	@include bp-max($bp-full-width){
		border: 1px solid $border-color-articles;
		border-top: none;
	}

}

.project-card-figure {
	position: relative;
	overflow: hidden;
	z-index: 5;

	@include bp-min($bp-tablet) {
		width: calc(#{$image-tablet-width} + 1px);
	}

	@include bp-min($bp-full-width) {
			width: calc(#{$image-mobile-width} + 1px);
			position: absolute;
			left: 0;
			height: 100%;
	}
}

.project-card-img {
	display: block;
	@include bp-min($bp-full-width) {
		@include absolute-center-align;
		max-width: none;
		min-height: 100%;
		min-width: 100%;
	}

	@include bp-max($bp-full-width){
		width: 100%;
	}
}

.project-card-title {
	color: inherit;
	margin-bottom: $spacing-unit-small;
	transition: $base-animation-minus-color;
	font-size: 1.55rem;
}

.project-card-abstract {
	color: inherit;
	margin-bottom: $spacing-unit-small;
	transition: $base-animation-minus-color;

	p {
		margin-bottom: $spacing-unit-small;
	}
}


.project-card-countries {
	@extend %body-2;
	color: inherit;
	transition: $base-animation-minus-color;
}

.project-card-country {
	&:after {
		content: ',';
	}

	&:last-child {
		&:after {
			content: '';
		}
	}
}

.project-card-countries-label {
	text-transform: capitalize;

	&:after {
		content: ':';
	}
}