.home-experience {
  background-color: $color-brand-white;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;

  // //is sticky classes
  z-index: 1;
  height: $homepage-last-panel-height;
  padding: 0;

  .is-revealing-footer & {
    z-index: 3;
  }

  @include bp-max($bp-mobile) {
    left: 0;
  }

  @include bp-max($bp-mobile-landscape) {
    height: $homepage-last-panel-height-mobile;
  }
}

.home-experience-hero {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-experience-hero-img {
  @include absolute-center-align;

  position: absolute;
  z-index: 5;
  height: 100%;
}

@keyframes arrowLoop {
    0% {
      opacity: 0;
      transform: translate3d(-5px, 0, 0);
    }
    50%, 60%, 65% {
      opacity: 1;
      transform: translate3d(5px, 0, 0);
    }
    100% {
      opacity: 0;
      transform: translate3d(15px, 0, 0);
    }
}

.home-experience-hero-copy {
  width: 100%;
  text-align: center;
  z-index: $z-index-homepage-experience;

  .btn--icon-next {
    display: inline-flex;
    align-items: center;

    &::after {
      @include font-awesome-icon
      content: $fa-var-long-arrow-right ;
      transform: translate3d(0,0,0);
      font-size: 1rem;
    }

    &:hover {
      &:after {
        animation: arrowLoop 1.5s infinite;
      }
    }

    .btn-text {
      display: inline-block;
    }
  }
}

.home-experience-hero-title {
  color: $color-brand-dark-gray;
  font-family: $font-family-titles;
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 1.7rem;
  display: block;
  &:lang(ja){
    font-family: $font-family-titles-ja;
  }
}
