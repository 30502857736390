.filter-toggles-list  {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.filter-toggle {
	> input[type=checkbox]  {
		position: absolute;
		visibility: hidden;
	}
}


.filter-toggle-label {
	@extend %body-5;

	display: inline-block;
	padding: 0.8rem 1rem;
	color: $color-green-blue-lagoon;
	cursor: pointer;
	border: 1px solid $border-color-base;

	&:hover {
		background-color: $color-brand-gray-light;
	}

	input[type=checkbox]:checked + &,
	.checked & {
		background-color:  $color-green-blue-lagoon;
		border-color:  $color-green-blue-lagoon;
		color: $color-brand-white;
	}
}

.filter-toggles {
	//Sub filters - regional program page
	&.sub-filters {
		
		.filter-toggle-label{
			background-color: transparent;
			border-color: transparent;
			color: $color-green-blue-lagoon;
			padding: 0;
			@include bt-icon-before($bt-var-circle) {
				font-size: px-to-rem(13px);
				margin-right: .5em;
			}
		}

		.checked .filter-toggle-label {
			background-color: transparent;
			border-color: transparent;
			color: $color-green-blue-lagoon;
			
			@include bt-icon-before($bt-var-check-circle) {
				font-size: px-to-rem(13px);
				font-weight: bold;
				margin-right: .5em;
			}
		}

		&.is-hidden {
			opacity: 0;
			height: 0;
			overflow: hidden;
		}

		&.is-visible {
			opacity: 1;
			height: auto;
			transition: opacity .4s;
			margin-top: -25px;
		}
	}
	
	// Site search
	&.filter-toggles--site-search {
		input[type=checkbox]:checked + .filter-toggle-label {
			background-color:  $color-brand-purple;
			border-color:  $color-brand-purple;
			color: $color-brand-white;
		}
	}
}

.filter-toggles-list-item {
	$spacing: $spacing-unit/2;

	margin-right: $spacing;
	margin-bottom: $spacing;
	display: inline-block;

	&:last-child {
		margin-right: 0;
	}

	label {
		padding: 0;
	}

	&--hide-recent{
		&:first-child {
			display: none;
		}
	}
	
	&.has-children {
		.filter-toggle-label {
			&:after {
				@include font-awesome-icon();
				content: $fa-var-chevron-down;
				margin-left: .8em;
			}
		}
		
		.checked {
			.filter-toggle-label {
				&:after {
					content: $fa-var-chevron-up;
				}
			}
		}
	}
	
	
}