﻿.services-filters {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;

    .btn {
        width: 100%;
    }

    &__external-link{
        &:after {
            content: '↑';
            transform: rotate(45deg);
            margin-left: px-to-rem(5px);
            display: inline-block;
        }
    }

    @include bp-min($bp-mobile-landscape) {
        flex-direction: row;

        &__filter {
            margin-right: .5rem;
            margin-left: .5rem;
        }

        .btn {
            width: auto;
        }
    }

    &__intro-label {
        font-size: px-to-rem(14px);
        line-height: 1.2;
        font-family: $font-family-sans-serif;
        color: $color-text-headings;
        padding-right: 15px;
        padding-top: .9rem;
        padding-bottom: .5rem;
    }
}
