.banner {
    margin-top: 60px;
    &-box {
        background-color: white;
        display: inline-block;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
    }
    &-featured {
        color: black;
        position: relative;
        text-align: center;
        width: 100%;
        &::before {
            content: '';
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            height: 1px;
            background-color: #c4c4c4;
            top: 50%;
        }
        &__main {
            display: block;
            font-size: px-to-rem(19px);
            letter-spacing: .38rem;
            color: #322f31;
            text-transform: uppercase;
            line-height: 29px;
            font-family: $font-family-sans-serif;
        }
        &__secondary {
            display: block;
            font-size: px-to-rem(24px);
            color: #656365;
            line-height: 29px;
            font-style: italic;
            font-family: $font-family-titles;
        }
        &__standard {
            display: block;
            font-size: px-to-rem(36px);
            color: #424242;
            text-transform: none;
            font-weight: normal;
            line-height: 29px;
            font-family: $font-family-titles;
            i {
                margin-left: 4px;
                color: #BDBDBD;
                font-size: px-to-rem(28px);
                &.play-outline {
                    font-size: px-to-rem(12px);
                    padding: px-to-rem(4px) px-to-rem(8px);
                    border-width: 2px;
                    bottom: px-to-rem(4px);
                }
            }
        }
    }
}