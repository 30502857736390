.article-list {
    margin-top: 80px;
    width: calc(100% + 20px);

    .article-item {
        background-color: $bg-articles;
        color: $color-text-dark;
        border-right:solid 20px white;
        position: relative;
        z-index: 5;
        margin-bottom: 20px;
        &__wrapper {
            position: relative;
            overflow: hidden;
            &:hover {
                &:before {
                    transform: translate3d(0,0,0);
                }
                .article-title, .article-tag, .article-item__text,.article-meta {
                    color: white;
                }
            }
            &:before {
                background-color: #535353;
                content: "";
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                right: 0;
                top: -1px;
                transition: transform .3s cubic-bezier(.165,.84,.44,1);
                width: 100%;
                z-index: 1;
                transform: translate3d(0,-100%,0);
            }
            
        }
        &__content {
            padding: px-to-rem(40px);
            position: relative;
            z-index: 2;
            @include bp-max($bp-mobile) {
                padding: 40px;
            }

            @media (max-width: $bp-mobile-portrait) {
                padding: 35px;
            }
        }
    }
    .article-title, .article-tag, .article-item__text, .article-meta {
        transition: $base-animation;
    }
    .article-meta {
        margin-bottom: px-to-rem(20px);
    }
    .article-title {
        margin-bottom: px-to-rem(30px);
    }
    .article-image {
        img {
            width: 100%;
            vertical-align: bottom;
        }
    }
    .grid-sizer,
    .article-item {
        width: calc(33.333%);
        @include bp-max($bp-desktop) {
            width: calc(50%);
        }
        @include bp-max($bp-mobile-landscape) {
            width: calc(100%);
        }
    }
    /* clear fix */
    .article-list:after {
        content: '';
        display: block;
        clear: both;
    }

    @include bp-max($bp-mobile) {
        margin-top: 30px;
    }
}
