.article-links-list {
    max-width: 1100px;
    margin: 80px auto 60px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .article-link-item {
        width: 100%;
        padding-right: 40px;
        padding-left: 40px;
        &:nth-child(1),&:nth-child(2) {
            .article-link-content {
                border-top: none; 
            }            
        }
        &:nth-last-child(1),&:nth-last-child(2) {
            .article-link-content {
                border-bottom: solid 1px $color-text-gray-alt; 
            }            
        }
    }
    .article-link-content {
        padding-bottom: 40px;
        padding-top: 40px;
        height: 100%;
        border-top: solid 1px $color-text-gray-alt; 
    }
    .article-link-title {
        font-size: px-to-rem(26px);
        color: $color-text-dark;
        margin-top: 8px;
        display:inline-block;
        &:hover {
            color: $color-text-link;
        }
    }
     @include bp-max($bp-mobile) {
        grid-template-columns: repeat(1, 1fr);
        margin: 10px auto 30px;
        .article-link-item {
            width: 100%;
            padding-right: 0px;
            padding-left: 0px;
            &:nth-child(2) {
                .article-link-content {
                    border-top: solid 1px $color-text-gray-alt; 
                }            
            }
            &:nth-last-child(2) {
                .article-link-content {
                    border-bottom: none; 
                }            
            }
    
        }
    }
}
