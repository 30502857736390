.infographic-grid {
    @include bp-min($bp-mobile) {
        flex-direction: row;
    }


    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;
    margin-left: auto;
    margin-right: auto;
    width: 99.9%;

    .infographic-grid-item {
        width: 100%;
        font-size: px-to-rem(16px);
        @include bp-min($bp-mobile) {
            width: 49.9%;
        }
    }
}

.infographic-grid--services-sectors-detail {
    margin-bottom: 0;
}
