.header-logo {
  	img {
	    width: 70%;
	    max-width: none;
      height: auto;

	    @include bp-max($bp-mobile) {
	        display: block;
	        width: auto;
	        height: 95%;
	    }
	}
}

.site-header {
  background-color: $color-brand-gray;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 50px + $site-header-bar-height;
  width: $site-header-nav-width;
  z-index: $zindex-header;
  text-align: center;
  -webkit-transform: translateZ(0);

  @include bp-max($bp-desktop-large) {
    width: $site-header-nav-width-desktop;

	}
  @include bp-max($bp-tablet) {
		width: $site-header-nav-width-tablet;
	}

  @include bp-max($bp-mobile) {

    top: $site-location-bar-height;
    padding-top: 0;
    width: 100%;
    text-align: left;
    padding:15px 70px;
    height:$site-header-height;

    transition: transform $animation-mobile-timing $easeInOutQuint;
  }

  &.headroom--unpinned {
  	@media (max-width: $bp-mobile) {
      transform: translate3d(0, $site-header-and-location-height * -1, 0);
  	}
  }
}

.nav-mobile-toggle {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 53px;
  padding: 20px 5px 0;
  text-align: center;
  background-color: $bg-mobile-trigger;
  display: none;
  transition: $base-animation;
  border: 0 none;

	@include bp-max ($bp-mobile) {
    display: block;
  }

  .is-active-mobile-menu & {
    background-color: $bg-nav-bar;
    color: $color-brand-white;
  }
}

.nav-mobile-toggle-bars {
  content: '';
  display: block;
  position: absolute;
  background-color:  $color-brand-dark-black;
  height: 1px;
  width: 22px;
  margin:0 auto;
  transition: all 500ms ease-in-out;
  left: 15px;
  top:19px;


	&:before,
	&:after{
		content: '';
		display: block;
		position: absolute;
		background-color:  $color-brand-dark-black;
		height: 1px;
		width: 22px;
		margin:0 auto;
		transition: all 500ms ease-in-out;
	}

  &:before {
    top: -5px;

  }

  &:after {
    top: 5px;
  }

  .is-active-mobile-menu &{
		background-color: transparent;


		&:before,&:after {
			top:0;
      background-color: $color-brand-white;
		}

		&:before{
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}

.nav-mobile-label {
  display: block;

  font-size: .65rem;
  font-style: normal;
}

.nav-mobile-label-close {
  display: none;
}
