$search-field-margin-right: .5rem;

.advance-search {

    display: none;

    .search-fields {
        list-style-type: none;
        padding: 0; 
        margin: 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-right: $search-field-margin-right * -1;
    }

    .search-text-input {
        @extend .display-item;
        margin-top: 0;
        input {
            display: block;
            line-height: inherit;
        }
    }

    .search-field {
        margin-top: $search-field-margin-right;
        margin-bottom: 0;
        // .01rem is to address an issue where ie rounds up and may cause items not to fit on 1 row
        margin-right: $search-field-margin-right - .01rem;
    }

    &.two-per-row {
        .search-field {
            width: calc(50% - #{$search-field-margin-right})
        }
    }

    &.three-per-row {
        .search-field {
            width: calc(100% / 3 - #{$search-field-margin-right});
        }
    }

    &.four-per-row {
        .search-field {
            width: calc(25% - #{$search-field-margin-right});
        }
    }

    @include bp-max(900px) {
        &.four-per-row {
            .search-field {
                width: calc(50% - #{$search-field-margin-right});
            }
        }
    }

    @include bp-max(600px) {
        .search-fields {
            margin-right: 0;
        }
        &.two-per-row,
        &.three-per-row,
        &.four-per-row {
            .search-field {
                width: 100%;

                &.search-field.search-field {
                    margin-right: 0;
                }

                .page-people-landing & {

                    &:nth-of-type(n + 4) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.media-tab-filters {
    display: block !important;
}
// Insights Landing modifications
.insights-search-form {
    .advance-search {
        &.advance-search {
            margin-left: 0;
            margin-right: 0;
        }

        @include bp-min(900px) {
            &.four-per-row {
                .search-field {
                    width: calc(50% - #{$search-field-margin-right});
                }
            }
        }
    }
}
