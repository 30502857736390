.search-parameters {
	$font-size: 14px;

	font-family: $font-family-sans-serif;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	display: none;

	&.show-parameters {
		display: flex;
	}



	.you-searched-for {
		list-style-type: none;
		margin: 0;
		padding: 0;
		font-size: 14px;
		white-space: nowrap;

		@include bp-max($bp-mobile) {
			width: 100%;
		}
	}

	.results-row {
		width: 100%;
	}

	.results-count {
		font-weight: bold;
		white-space: nowrap;
		font-size: px-to-rem($font-size);
		display: inline-block;
		margin-top: 1rem;
	}

	.view-by {
		display: flex;
		width: 100%;
		margin-top: 1rem;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 1.8px;

		> div {
			margin: 0 1rem;
			transition: color .3s;
			cursor: pointer;
			color: $color-green-blue-lagoon;

			&:hover,
			&.active {
				color: $color-brand-red;
			}
		}
	}

	.parameter-list {
		list-style-type: none;
		margin: 10px 0;
		padding: 0;
		padding-left: 1rem;
		display: flex;
		flex-grow: 1;
		flex-wrap: wrap;
		column-gap: 1rem;
		row-gap: 0.5rem;

		@include bp-max($bp-mobile) {
			padding-left: 0;

		}
	}

	.parameter {
		display: inline-block;
		margin: 0;
		font-size: px-to-rem($font-size);
		cursor: pointer;

		&:after {
			content: $bt-var-times;
			font-family: $font-family-icons;
			font-size: .6em;
			margin-left: .5em;
			color: $color-green-blue-lagoon;
		}
	}

	.btn-clear-all {
		display: table-cell;
		white-space: nowrap;
		font-size: px-to-rem($font-size);
		padding: 0;
		margin: 0;
		border: none;
		letter-spacing: 1px;
		color: $color-green-blue-lagoon;
		visibility: hidden;

		&.show-button {
			visibility: visible;
		}
	}
}
