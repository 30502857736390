.site-search-btn {
  background-color: $color-brand-dark-black;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  color: $color-brand-white;
  padding: .4rem 0;
  text-align: center;
  width: $site-search-width;
  font-size: .8rem;

  &:hover,
  .is-active-search & {
    background-color: $color-brand-white;
    color: $color-brand-dark-black;
  }

  .is-active-search & {
    .btr {
      &:before {
        content: $bt-var-times;
        font-weight: 200;
        font-size: 1.2rem;
      }
    }
  }

  @include bp-max($bp-desktop) {
    font-size: .85rem;
  }

  @include bp-max($bp-tablet) {
    font-size: .9rem;
  }

  @include bp-max($bp-mobile) {
    background-color: $bg-mobile-trigger;
    font-size: 1.1rem;
    color: $color-brand-dark-black;
  }
}

.site-search {
  top: 0;
  bottom: auto;
  height: 0;
  overflow: hidden;
  z-index: $zindex-header-bar - 1;
  background-color: $color-brand-white;
  transition: height .7s $easeInOutQuint;


  @include bp-max($bp-mobile) {
    top: $site-header-height + $site-location-bar-height;
    left: 0;

    .headroom--unpinned + & {
      top: $site-header-height;
    }

    .is-active-mobile-menu & {
      visibility: visible;
      transition-delay: 0s;
    }
  }


  .site-padding {
    border-top: $site-header-bar-height solid transparent;
  }

  .is-active-search &{
    position: fixed;
    height: 100%;
  }

  .searchbox-input {
    @extend %large-size;

    padding: 4% 16% 4% 5%;

    @include bp-max($bp-mobile-landscape) {
      font-size: 1.5rem;
    }
  }

  .searchbox-btn {
    font-size: 2rem;
    padding: .4rem 5%;

    &:focus {
      background-color: $color-brand-blue;
      border-color: $color-brand-blue;
    }

    @include bp-max($bp-mobile-landscape) {
      font-size: 1.3rem;
    }
  }
}

body.is-active-search {
  overflow: hidden;
}
